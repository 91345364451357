import { Injectable } from '@angular/core';

@Injectable()
export class SessionstorageService {
  setTiposPago(tipo_pago: number) {
    sessionStorage.setItem('TiposPago', tipo_pago.toString());
  }

  getTiposPago() {
    return sessionStorage.getItem('TiposPago');
  }

  setTipoAsignado(tipo_asignado: number) {
    sessionStorage.setItem('TipoAsignado', tipo_asignado.toString());
  }

  getTipoAsignado() {
    return sessionStorage.getItem('TipoAsignado');
  }

  setBoletosCol(tipo_asignado: number) {
    sessionStorage.setItem('BoletosCol', tipo_asignado.toString());
  }

  getBoletosCol() {
    return sessionStorage.getItem('BoletosCol');
  }

  setCorreoComp(correo_comp: string) {
    sessionStorage.setItem('CorreoComp', correo_comp.toString());
  }

  getCorreoComp() {
    return sessionStorage.getItem('CorreoComp');
  }

  setColab(colab: string) {
    sessionStorage.setItem('Colab', colab.toString());
  }

  getColab() {
    return sessionStorage.getItem('Colab');
  }
  setPopstate(pop: any) {
    sessionStorage.setItem('PopupC', pop);
  }
  getPopstate() {
    return sessionStorage.getItem('PopupC');
  }
  setContCarrito(cont: number) {
    sessionStorage.setItem('Cont', cont.toString());
  }
  getContCarrito() {
    return sessionStorage.getItem('Cont');
  }
  setDBEstadoPopUp(pop: any) {
    sessionStorage.setItem('popState', pop);
  }
  getDBEstadoPopUp() {
    return sessionStorage.getItem('popState');
  }
  setOrdenBanamex(orden: any) {
    sessionStorage.setItem('OrdenBanamex', orden);
  }
  getOrdenBanamex() {
    return sessionStorage.getItem('OrdenBanamex');
  }
  setDatosOrdenBanamex(orden: any) {
    sessionStorage.setItem('DatosOrdenBanamex', orden);
  }
  getDatosOrdenBanamex() {
    return sessionStorage.getItem('DatosOrdenBanamex');
  }
  setBoletoSuerteComprador(idcomprador: any) {
    sessionStorage.setItem('IdComprador', idcomprador);
  }
  getBoletoSuerteComprador() {
    return sessionStorage.getItem('IdComprador');
  }
  setLogGtag(script: any) {
    sessionStorage.setItem('LogGtag', script);
  }
  getLogGtag() {
    return sessionStorage.getItem('LogGtag');
  }
}
