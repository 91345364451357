<section class="stc-venta-exitosa err-p">
  <div class="container">
    <div class="row">
      <div class="col-md-6  d-flex justify-content-center flex-column order-md-1 order-2" data-aos="fade-left"
      data-aos-easing="ease" data-aos-duration="3000">
        <div class="text-center">
          <img *ngIf="request.nbResponse != 'ErrorDatosCarrito'"  src="/assets/img/sorteo2024/error.png" alt="Error Pago" class="img-fluid error-pago">
          <img *ngIf="request.nbResponse == 'ErrorDatosCarrito'" src="/assets/img/sorteo2024/boleto.png" alt="Error Pago" class="img-fluid error-pago">
        </div>
      </div>

      <div class="col-md-6 my-auto order-md-2 order-1">
        <h1  *ngIf="request.nbResponse != 'ErrorDatosCarrito'" >
         ERROR
          <span class="d-md-block">
            DE PAGO
          </span>
        </h1>

        <h1  *ngIf="request.nbResponse == 'ErrorDatosCarrito'" >
          BOLETO
           <span class="d-md-block">
             BLOQUEADO
           </span>
         </h1>
      </div>
      
    </div>
  </div>
  <a (click)="scrollPage('#orden')" class="cta-bottom d-md-block d-none" >
    <img src="/assets/img/sorteo2023/arrow-bottom.png" class="img-fluid" >
  </a>
</section>
  
  <section class="venta-ext">
    <div class="container" data-aos="fade-down" id="orden">
  
      <div
        class="row bgerror"
        *ngIf="request.nbResponse != 'Aprobado' && reqBanamex.resultIndicator == undefined"
        data-aos="fade-down"
      >
        <div class="BoletosPay col-xs-12 col-sm-12 col-md-12 col-lg-12 RechazadoC center space-top">
          <div>
            <h2  *ngIf="request.nbResponse != 'ErrorDatosCarrito'" class="texto-primario errortitle" style="text-align: center">Transacción no exitosa</h2>
            <h2  *ngIf="request.nbResponse == 'ErrorDatosCarrito'" class="texto-primario errortitle" style="text-align: center;  font-size: 36px;">  Motivos de bloqueo</h2>
          </div>
          <div>
       
          </div>
          <div *ngIf="request.nbResponse == 'Rechazado'">
            <!-- <label style="text-align:center;">Lo sentimos, el pago por ${{request.importe}} MXN ha sido rechazado por su banco.</label>
              <label style="text-align:center;">Mensaje del banco: {{request.cdResponse}}</label>
              <label style="text-align:center;">" {{request.error}} "</label> -->
            <label>Lo sentimos, pago rechazado por su banco.</label>
          </div>
  
          <div *ngIf="request.nbResponse == 'Invalido'">
            <label class="errorlabel" style="text-align: center"
              >Lo sentimos, uno o varios de sus boletos ya no se encontraban disponibles. Favor de intentar nuevamente</label
            >
          </div>
  
          <div *ngIf="request.nbResponse == 'Bloqueo'">
            <label class="errorlabel" style="text-align: center"
              >Hubo un error al generar tu pago, por favor inténtalo nuevamente</label
            >
          </div>

          <div *ngIf="request.nbResponse == 'ErrorDatos'">
            <label class="errorlabel" style="text-align: center"
              >Existe un problema con tus datos, ya que contienen caracteres no permitidos. Por favor, intenta de nuevo en 15 minutos, ya que tu boleto se encuentra en el carrito. Trata de no usar caracteres como '-?*/+, entre otros. </label
            >
          </div>
          <div *ngIf="request.nbResponse == 'ErrorDatosCarrito'">
              <label class="errorlabel" style="text-align: center">
                <ul>
                  <li>
                    El boleto ya contiene una orden compra en este caso hay que esperar 15 minutos, para que nuevamente intentes comprar el mismo número.
                  </li>
                  <li>
                    Si generaste una referencia bancaria revisa que la tengas en tu correo y no olvides que esta se libera en 5 días hábiles
                  </li>
                </ul>
              </label
            >
          </div>
  
          <div *ngIf="request.nbResponse == null">
            <label class="errorlabel">
              <ul>
                <li>
                    Hubo un error al generar tu pago, por favor inténtalo nuevamente
                </li>
           
              </ul>

            </label>
          </div>
  
          <div class="col-xs-12 col-sm-12 margen-t center">
            <div class="row margen-t">
              <div class="col-12 margen-b center">
                <button (click)="terminar()" class="btn-stack border-0 mt-3">Volver a comprar</button>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
  
    </div>
  </section>