import { Injectable } from '@angular/core';
import { ScriptStore } from '../interfaces/Scripts';
import { ScriptParams, Scripts } from '../models/ScriptParams';
import { SessionstorageService } from './sessionstorage.service';

declare var document: any;
@Injectable()
export class ScriptService {
  private scripts: any = {};

  constructor(private _session: SessionstorageService) {
    ////console.log("script ini");
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src,
        text: script.text,
        noscriptsrc: script.noscriptsrc,
      };
    });
  }

  load(scripts: Scripts[]) {
    var promises: any[] = [];
    scripts.forEach((script) => {
      promises.push(this.loadScript(script.script, script.params));
      promises.push(this.loadNoScript(script.script, script.params));
    });
    return Promise.all(promises);
  }

  loadScript(name: string, params: ScriptParams[]) {
    return new Promise((resolve, reject) => {
      //resolve if already loaded

      if (this.scripts[name].loaded) {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      } else {
        //load script
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.text = this.scripts[name].text;
        script.id = name;
        script.setAttribute('id', name);
        if (params) {
          let boletosgtag = '';
          params.forEach((param) => {
  
            switch (param.name) {
              case 'BOLETO_ID':
                let boletoscript =
                "{'name': 'Boleto','id': 'BOLETO_ID','price': BOLETO_PRICE,'brand': 'Sorteo Anáhuac','category': 'Boleto','quantity': 1, 'user_data_email_address': 'EMAIL','user_data_phone': 'PHONE','user_data_address_first_name': 'NAME', 'user_data_address_last_name': 'LASTNAME', 'user_data_street': 'STREET', 'user_data_city': 'CITY', 'user_data_state': 'STATE', 'CountryElogia': 'COUNTRY', 'user_data_cp': 'CP' },";
                boletoscript = boletoscript.replace(param.name, param.value);
                boletosgtag = boletosgtag + boletoscript;
              break;
              
              case 'BOLETO_PRICE':
                  boletosgtag = boletosgtag.replace(/BOLETO_PRICE/g, param.value);
                  boletosgtag = boletosgtag.slice(0, -1);
                  script.text = script.text.replace('BOLETOS', boletosgtag);
              break;
              case 'EMAIL' :
                boletosgtag = boletosgtag.replace(param.name, param.value);
              break;
              case  'PHONE':
                boletosgtag = boletosgtag.replace(param.name, param.value);
              break;
              case   'NAME':
                boletosgtag = boletosgtag.replace(param.name, param.value);
              break;
              case   'LASTNAME':
                boletosgtag = boletosgtag.replace(param.name, param.value);
              break;
              case   'STREET' :
                boletosgtag = boletosgtag.replace(param.name, param.value);
              break;
              case    'CITY' :
                boletosgtag = boletosgtag.replace(param.name, param.value);
              break;
              case  'STATE' :
                boletosgtag = boletosgtag.replace(param.name, param.value);
              break;
              case    'COUNTRY':
                boletosgtag = boletosgtag.replace(param.name, param.value);
              break;
              case    'CP':
                boletosgtag = boletosgtag.replace(param.name, param.value);
              break;

              default:
                script.text = script.text.replace(param.name, param.value);
              break;
            }
          });
        }
        if (script.readyState) {
          //IE
          script.onreadystatechange = () => {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              resolve({ script: name, loaded: true, status: 'Loaded' });
            }
          };
        } else {
          //Others
          script.onload = () => {
            this.scripts[name].loaded = true;
            resolve({ script: name, loaded: true, status: 'Loaded' });
          };
        }
        script.onerror = (error: any) => {
          resolve({ script: name, loaded: false, status: 'Loaded' });
        };
        document.getElementsByTagName('head')[0].appendChild(script);

        this._session.setLogGtag(script.text);
        resolve({ script: name, loaded: true, status: 'Loaded' });
      }
    });
  }

  loadNoScript(name: string, params: ScriptParams[]) {
    return new Promise((resolve, reject) => {
      if (name == 'tradetracker') {
        ////console.log("noscript ini");
        let noscript = document.createElement('noscript');
        let img = document.createElement('img');
        img.src = this.scripts[name].noscriptsrc;
        img.alt = '';
        if (params) {
          params.forEach((param) => {
            img.src = img.src.replace(param.name, param.value);
          });
        }
        noscript.appendChild(img);
        noscript.id = name + 'noscript';
        document.body.appendChild(noscript);
        resolve({ script: name, loaded: true, status: 'Loaded NoScript' });
      } else {
        resolve({ script: name, loaded: true, status: 'No Scripts available' });
      }
    });
  }

  deleteScript(names: string[]) {
    return new Promise((resolve, reject) => {
      names.forEach((name) => {
        var scripts = document.getElementsByTagName('script');
        for (var i = scripts.length; i >= 0; i--) {
          if (
            scripts[i] &&
            scripts[i].getAttribute('id') != null &&
            (scripts[i].getAttribute('id') == name ||
              (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes(name)))
          ) {
            scripts[i].parentNode.removeChild(scripts[i]);
          }
          if (scripts[i] && scripts[i].getAttribute('src') != null && name != 'gtag') {
            const scr = scripts[i].getAttribute('src');
            if (scr.includes(name) || scr.indexOf(name) !== -1) {
              scripts[i].parentNode.removeChild(scripts[i]);
            }
          }
        }
        var img = document.getElementsByTagName('img');
        for (var i = img.length; i >= 0; i--) {
          if (img[i] && img[i].getAttribute('src') != null) {
            const scr = img[i].getAttribute('src');
            if (scr.includes(name) || scr.indexOf(name) !== -1) {
              img[i].parentNode.removeChild(img[i]);
            }
          }
        }

        var noscript = document.getElementById(name + 'noscript');
        if (noscript) {
          noscript.parentNode.removeChild(noscript);
        }
      });
      resolve({ script: name, loaded: false, status: 'Script deleted' });
    });
  }
}
