import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs-compat';
import { DatosCompradorService } from '../datos-comprador/services/datos-comprador.service';
import { Boleto } from '../models/Boleto';
import { Pais } from '../models/Pais';
import { ScriptParams, Scripts } from '../models/ScriptParams';
import { SeleccionMultipleBoleto } from '../models/SeleccionMultipleBoleto';
import { HttpService } from '../services/http.service';
import { MyHttpGetService } from '../services/http.services.token';
import { LocalstorageService } from '../services/localstorage.service';
import { ScriptService } from '../services/script.service';
import { SessionstorageService } from '../services/sessionstorage.service';
import * as $ from 'jquery';
import { Leads } from '../models/Leads';

@Component({
  selector: 'app-venta-exitosa',
  templateUrl: './venta-exitosa.component.html',
  styleUrls: ['./venta-exitosa.component.scss'],
  providers: [MyHttpGetService, LocalstorageService, SessionstorageService],
})
export class VentaExitosaComponent implements OnInit {
  public compradores_tag: any[];
  public tipos_pago: number = 1;
  public tipo_asignado: number;
  public boletos_generados: Array<Boleto> = [];
  public boletos_recomendados: Array<Boleto> = [];
  public boletos_asignados: Array<Boleto> = [];
  public boletos: Array<Boleto> = [];
  public boletos_bloqueados: { boletos: Array<Object>; fecha: string; orden: string; carrito } = {
    boletos: [],
    fecha: '',
    orden: '',
    carrito: '',
  };
  public total_compra: number = 0;
  public comprador: any;
  check: any;
  c: any;
  public request: {
    nbResponse: string;
    cdResponse: string;
    idLiga: string;
    referencia: string;
    importe: string;
    no_tarjeta: string;
    nombre: string;
    tipo_tarjeta: string;
    email: string;
    nuAuth: string;
    error: string;
  } = {
    nbResponse: '',
    cdResponse: '',
    idLiga: '',
    referencia: '',
    importe: '',
    no_tarjeta: '',
    nombre: '',
    tipo_tarjeta: '',
    email: '',
    nuAuth: '',
    error: '',
  };
  public datos_compra: { response: string; folio: string; amount: string; objeto: string } = {
    response: '',
    folio: '',
    amount: '',
    objeto: '',
  };
  public colab: SeleccionMultipleBoleto = { numero: '', mecanismo: '', pkcolab: '' };
  public orden_banamex: any;
  public reqBanamex: { resultIndicator: string; sessionVersion: string };
  //public datos: { folio: string, nombre_cliente: string,	monto: string, mensaje : string}
  private params: ScriptParams[] = [];
  private scriptsobj: Scripts[] = [];
  public Paybackref: string;
  public logGtag: { response: string; folio: string; amount: string; objeto: string } = {
    response: '',
    folio: '',
    amount: '',
    objeto: '',
  };

  public listPaises : any[];
  textoInt: string = "";
  constructor(
    private _http: HttpService,
    private myGetService: MyHttpGetService,
    private _local: LocalstorageService,
    private router: Router,
    private route: ActivatedRoute,
    private _session: SessionstorageService,
    private location: Location,
    private scripts: ScriptService,
    private _DCompradorService: DatosCompradorService,
  ) {
    var lsGenerados = this._local.getBoletosGenerados();
    var lsRecomendados = this._local.getBoletosRecomendados();
    var lsAsignados = this._local.getBoletosAsignados();
    var lsApartados = this._local.getBoletosApartados();
    var lsCarrito = this._local.getCarrito();
    this.boletos_generados = lsGenerados == null ? [] : JSON.parse(lsGenerados);
    this.boletos_recomendados = lsRecomendados == null ? [] : JSON.parse(lsRecomendados);
    this.boletos_asignados = lsAsignados == null ? [] : JSON.parse(lsAsignados);
    this.boletos_bloqueados.boletos = lsApartados == null ? [] : JSON.parse(lsApartados);
    this.boletos_bloqueados.orden = this._local.getFolio();
    this.boletos_bloqueados.carrito = lsCarrito;
    this.route.queryParams.subscribe((params) => {
      this.request = {
        nbResponse: params.nbResponse,
        cdResponse: params.cdResponse,
        idLiga: params.idLiga,
        referencia: params.referencia,
        importe: params.importe,
        nombre: '',
        tipo_tarjeta: '',
        no_tarjeta: '',
        error: params.nb_error,
        email: params.email,
        nuAuth: params.nuAuth,
      };
      //console.log(this.request);
      this.datos_compra = {
        response: params.nbResponse,
        folio: params.referencia,
        amount: params.importe,
        objeto: this.router.url,
      };
      //console.log(this.datos_compra);
      this.reqBanamex = {
        resultIndicator: params.resultIndicator,
        sessionVersion: params.sessionVersion,
      };
      //console.log( this.reqBanamex);
    });

    this.tipos_pago = parseInt(this._session.getTiposPago());
    this.tipo_asignado = parseInt(this._session.getTipoAsignado());
    this.Paybackref = this._local.getPaybackref() ? this._local.getPaybackref() : '0';


    /* redirect pago no exitoo */
    if(this.request.nbResponse != 'Aprobado' && this.reqBanamex.resultIndicator == undefined) {
      this.router.navigate(['error-pago']);
      if(this.request.nbResponse=='Invalido' || this.request.nbResponse == 'Rechazado'|| this.request.nbResponse == 'Bloqueo' || this.request.nbResponse == null) {
        this.router.navigate(['error-pago']);
      }
    }
  }

  async ngOnInit() {
    this.textoInt = sessionStorage.getItem('texto');
    //console.log( this.listPaises);
    ////console.log(this.datos_compra);
    this.myGetService.postData(this.datos_compra, 'api/BoletosWeb/LogMit').subscribe((res) => {});
    
    if (this.request.nbResponse != 'Aprobado' && this.reqBanamex.resultIndicator == undefined) {
      //this.request.referencia == undefined || this.request.referencia == null){
      // const registroventa = await this.LiberaBoletos();
      this.LiberaBoletos();
    }
    //obtener compradores
    this.compradores_tag = JSON.parse(sessionStorage.getItem('BoletosApartados'));

    this.getData(this.boletos_generados, this.boletos_recomendados, this.boletos_asignados);

    this.orden_banamex = this._session.getOrdenBanamex();
    if (this.reqBanamex.resultIndicator != undefined) {
      if (this.reqBanamex.resultIndicator == this.orden_banamex) {
        const registroventa = await this.RegistraVentaBanamex();
      }
    }
    //aqui valida la autorizacion
    if (this.request.referencia != undefined || this.request.referencia != null) {
      this.getInfoCompra();
    }

    if (this._local.getPKColab != null) {
      this.AsignaColabExt();
    }

  
    this._local.deleteFolio();
    this._local.deleteBoletosGenerados();
    this._local.deleteBoletosRecomendados();
    this._local.deleteBoletosAsignados();
    this._local.deleteCarrito();
    this._local.deletePaybackref();

    history.pushState(null, null, 'venta-exitosa');
    window.addEventListener('popstate', function (event) {
      history.pushState(null, null, 'venta-exitosa');
    });
    window.sessionStorage.clear();
     
  }

  getInfoCompra() {
    //console.log("entre a get info");
    //console.log(this.request.referencia.toString().padStart(8, '0'));
    this.myGetService
      .getInfoCompra('api/BoletosWeb/InformacionCompraBoletos', this.request.referencia.toString().padStart(8, '0'))
      .subscribe((res) => {
        //console.log(res);

        this.request.nombre = res.json().nombre_cliente;
        this.request.tipo_tarjeta = res.json().tipo_tarjeta;
        this.request.no_tarjeta = res.json().numero_tarjeta;
        this.request.nuAuth = res.json().autorizacion;
        this.boletos = res.json().boletos;
        this.comprador = res.json().comprador;
        this.boletos[0].estado_boleto = this.comprador;
        //console.log(this.request);
        console.log(this.boletos);
        console.log(this.comprador);

        //console.log("request: "+this.request);
        ////console.log(this.boletos);
        ////console.log(this.comprador);
        this.PaybackScript();

        /* DATOS */

        let infoCampania: Leads;
        /* LEADS */
        
        if (localStorage.getItem('leads') === "true" && localStorage.getItem('ShowPopCampania2') === "true" ) {
         // console.log("lead modifica entro");
          let temInfo: Leads= JSON.parse(localStorage.getItem('campaingData1')) ;
          //localStorage.setItem("first", 'false' );
          //console.log(temInfo);
          infoCampania = temInfo; 
          //console.log( temInfo);
          //traer datos de la compra
        
          let datosComprador = JSON.parse(localStorage.getItem('boletosAparatadosTem'));
          //console.log(datosComprador);
          let numBoletos = temInfo.boletos_seleccionados.split(",");

          //añadir nuevo correo 
          /* datos comprador */
          infoCampania.correo = datosComprador[0].comprador.correo_electronico;
          infoCampania.sorteo = 8;
          infoCampania.nombre = datosComprador[0].comprador.nombre;
          //infoCampania.nombre = "Modificado desde web";
          infoCampania.apellido_peterno = datosComprador[0].comprador.apellido_paterno ;
          infoCampania.apellido_materno = datosComprador[0].comprador.apellido_materno ;   
          infoCampania.telefono = datosComprador[0].comprador.telefono_fijo;
          infoCampania.celular = datosComprador[0].comprador.telefono_movil;
          infoCampania.num_boletos_comprados = numBoletos.length;
          infoCampania.monto = numBoletos.length * 450;
          //console.log(res.json().autorizacion);
          if( res.json().autorizacion != "" || res.json().autorizacion != null) {
            infoCampania.estatus_pago = "APROBADO";
          }else {
            infoCampania.estatus_pago = "NO EXITOSO";
          }
         
          infoCampania.boletos_seleccionados  = "";
          //console.log(datosComprador);
          //console.log(infoCampania);
         

           //llamar metodo
          let dataCampania: Leads = infoCampania;
          //console.log(dataCampania);
          //console.log("entre venta exitosa");
          this._DCompradorService.modificaLeads(dataCampania).subscribe( a => {
             // console.log(a);
              
            
          },
          err => {
            //console.log(err);
            if(err.status ==200) {
             // console.log("entre a limpiar");
             const lastCallTime = localStorage.getItem('lastCallTime');
             localStorage.clear();
             localStorage.setItem('lastCallTime', lastCallTime);
              /* retirnamos a organico */
              let organico = "?utm_campaign=organico&utm_content=organico&utm_placement=organico&campaign_name=organico&site_source_name=organico&placement=organico";
              let utm2 = organico.split("?");
              var search2 = utm2[1] ;
              //console.log(JSON.stringify(organico));
              let finalUtm = JSON.parse('{"' + search2.replace(/&/g, '","').replace(/=/g,'":"') + '"}', function(key, value) { return key===""?value:decodeURIComponent(value) });
              ////console.log(finalUtm);
              localStorage.setItem('campaingData', JSON.stringify(finalUtm));
            }
          });
          
        
        }
           


        /* FIN DATOS */

        var lab = document.getElementById('CantBoletos1');
        lab.hidden = false;

        if (this._session.getBoletoSuerteComprador != null) {
          this.myGetService
            .postData(this.boletos, 'api/BoletosWeb/PagoRecibidoBoletoSuerte/' + this.comprador)
            .subscribe((res) => {
              ////console.log(res);
            });
        }

        //this.PaybackScript();
      });
  }

  getData(boletos_generados: Array<Boleto>, boletos_recomendados: Array<Boleto>, boletos_asignados: Array<Boleto>) {
    this._local.deleteBoletosGenerados();
    this._local.deleteBoletosRecomendados();
    this._local.deleteBoletosAsignados();
    //window.sessionStorage.clear();
  }

  comprarOtro() {
    this.router.navigate(['compra-en-linea-boletos-sorteo-anahuac']);
  }

  terminar() {
    let deletescripts: string[] = ['tradetracker', 'gtag'];
    this.scripts.deleteScript(deletescripts);

    this.check = document.getElementById('checkPerpetuo');
    if (this.check != null) {
      if (this.check.checked) {
        this.BoletoPerpetuo(1);
      } else {
        this.BoletoPerpetuo(2);
      }
    }

    document.removeEventListener('mouseout', (event) => {
      if (event.relatedTarget === null) {
        this.check = document.getElementById('checkPerpetuo');
        if (this.check.checked && this.c != 1) {
          this.BoletoPerpetuo(1);
          alert('Registrado');
          this.c = 1;
        } else if (!this.check.checked && this.c != 0) {
          this.BoletoPerpetuo(2);
          alert('no registrado');
          this.c = 0;
        }
      }
    });

    const lastCallTime = localStorage.getItem('lastCallTime');
    localStorage.clear();
    localStorage.setItem('lastCallTime', lastCallTime);
    let organico = "?utm_campaign=organico&utm_content=organico&utm_placement=organico&campaign_name=organico&site_source_name=organico&placement=organico";
    let utm2 = organico.split("?");
    var search2 = utm2[1] ;
    //console.log(JSON.stringify(organico));
    let finalUtm = JSON.parse('{"' + search2.replace(/&/g, '","').replace(/=/g,'":"') + '"}', function(key, value) { return key===""?value:decodeURIComponent(value) });
    ////console.log(finalUtm);
    localStorage.setItem('campaingData', JSON.stringify(finalUtm));
    this.router.navigate(['compra-en-linea-boletos-sorteo-anahuac']);
  }

  reintentarPago() {
    this.router.navigate(['forma-pago'], { queryParams: { ordencompra: this.request.referencia } });
  }

  MarcarBoleto() {
    document.addEventListener('mouseout', (event) => {
      if (event.relatedTarget === null) {
        this.check = document.getElementById('checkPerpetuo');
        if (this.check.checked && this.c != 1) {
          this.BoletoPerpetuo(1);
          alert('Registrado');
          this.c = 1;
        } else if (!this.check.checked && this.c != 0) {
          this.BoletoPerpetuo(2);
          alert('no registrado');
          this.c = 0;
        }
      }
    });
  }

  BoletoPerpetuo(opc) {
    if (opc == 1) {
      this.myGetService.postData(this.boletos, 'api/BoletosWeb/BoletoPerpetuo_V').subscribe((res) => {
        ////console.log("response");
        ////console.log(res);
      });
    }
    if (opc == 2) {
      this.myGetService.postData(this.boletos, 'api/BoletosWeb/Del_BoletoPerpetuo_V').subscribe((res) => {
        ////console.log("response");
        ////console.log(res);
      });
    }
  }

  //Metodo para asignar venta a Colaborador Externo
  AsignaColabExt() {
    this.colab.numero = this.request.referencia;
    this.colab.pkcolab = this._local.getPKColab();
    this.colab.mecanismo = this._local.getMecanismoColabExt();
    if (this.colab.numero != null && this.colab.pkcolab != null) {
      this.myGetService.postData(this.colab, 'api/BoletosWeb/AsignaColabExt').subscribe((res) => {
        ////console.log("response");
        ////console.log(res);
        this._local.removePKColab();
        this._local.removeMecanismoColabExt();
      });
    }
  }

  async RegistraVentaBanamex() {
    var datos = this._session.getDatosOrdenBanamex();
    if (datos != null || datos != undefined) {
      return new Promise((resolve, reject) => {
        this.myGetService.postData(datos, 'api/BoletosWeb/PagoPayPalConfirmado').subscribe((res) => {
          ////console.log("response");
          ////console.log(res);
          var datos_orden = JSON.parse(datos);
          this.request.referencia = datos_orden.folio;
          this.request.importe = datos_orden.monto;

          return resolve(res);
        });
      });
    }
  }

  LiberaBoletos() {
    // if(boletos_generados != null || boletos_generados != undefined)
    // {
    // 	var boletos = boletos_generados;
    // } else if(boletos_recomendados != null || boletos_recomendados != undefined)
    // {
    // 	var boletos = boletos_recomendados;
    // } else if(boletos_asignados != null || boletos_asignados != undefined)
    // {
    // 	var boletos = boletos_asignados;
    // }

    this.boletos_bloqueados.fecha = new Date().toISOString();

    if (this.boletos_bloqueados.boletos.length != 0) {
      this.myGetService.postData(this.boletos_bloqueados, 'api/BoletosWeb/LiberaBoletos').subscribe((res) => {
        ////console.log("response");
        ////console.log(res);
      });
    }
  }

  PaybackScript(): void {
    
    this.params.push({ name: 'ORDER_ID', value: this.request.referencia });
    //add pararams
    
    this.params.push({
      name: 'ORDER_AMOUNT',
      value: this.request.importe.replace('.00', '').replace(',', ''),
    });

    
    

   

   

    if (this.params) {
      this.ScriptBoletos();
      if (this.Paybackref == '1') {
        this.scriptsobj.push({ script: 'tradetracker', params: this.params });
      }
      //datos comprador
    

      this.scriptsobj.push({ script: 'gtag', params: this.params });
      //console.log(this.scriptsobj);
      this.scripts
        .load(this.scriptsobj)
        .then((data) => {
          ////console.log('script loaded ', data);
          let LogGtag = this._session.getLogGtag();
          if (LogGtag) {
            this.logGtag = {
              response: '',
              folio: this.request.referencia,
              amount: '',
              objeto: LogGtag,
            };
           // console.log(this.logGtag);
            this.myGetService.postData(this.logGtag, 'api/BoletosWeb/LogGtag').subscribe((res) => {
             //console.log(res);
            });
          }
        })
        .catch((error) => {
           ////console.log(error);
        });
    }
  }

  ScriptBoletos(): void {

    let count = this.boletos.length-1;
    for (var i = 0; i <  this.boletos.length ; i++) {
      this.params.push({ name: 'BOLETO_ID', value:this.compradores_tag[i].folio });
      

      this.params.push({ name: 'EMAIL', value:  this.compradores_tag[i].comprador.correo_electronico });
      this.params.push({ name: 'PHONE', value:  this.compradores_tag[i].comprador.telefono_movil });
      this.params.push({ name: 'NAME',  value:  this.compradores_tag[i].comprador.nombre });
      this.params.push({ name: 'LASTNAME',  value:  this.compradores_tag[i].comprador.apellidos });
      this.params.push({ name: 'STREET',  value:  this.compradores_tag[i].comprador.calle });
      this.params.push({ name: 'CITY',  value:  this.compradores_tag[i].comprador.estado });
      this.params.push({ name: 'STATE',  value:  this.compradores_tag[i].comprador.municipio });
      let namePais = "";
      if(this.compradores_tag[i].comprador.pais=="484") {
        namePais = "México"
      }else{
        namePais = "Extranjero"
      }
      this.params.push({ name: 'COUNTRY',  value:  namePais });
      this.params.push({ name: 'CP',  value:  this.compradores_tag[i].comprador.codigo_postal });
      count--;
    }
    this.params.push({ name: 'BOLETO_PRICE', value: this.boletos[0].costo.toString() });
   // console.log( this.params);
    this.boletos.forEach((boleto) => {
      this.params.push({ name: 'BOLETO_ID', value: boleto.folio.toString() });
    });
    
    this.params.push({ name: 'BOLETO_PRICE', value: this.boletos[0].costo.toString() });

    for (var i = 0; i <  this.compradores_tag.length ; i++) {
      this.params.push({ name: 'EMAIL', value:  this.compradores_tag[i].comprador.correo_electronico });
      this.params.push({ name: 'PHONE', value:  this.compradores_tag[i].telefono_movil });
      this.params.push({ name: 'NAME',  value:  this.compradores_tag[i].nombre });
      this.params.push({ name: 'LASTNAME',  value:  this.compradores_tag[i].apellidos });
      this.params.push({ name: 'STREET',  value:  this.compradores_tag[i].calle });
      this.params.push({ name: 'CITY',  value:  this.compradores_tag[i].estado });
      this.params.push({ name: 'STATE',  value:  this.compradores_tag[i].municipio });
      this.params.push({ name: 'COUNTRY',  value:  this.compradores_tag[i].pais });
      this.params.push({ name: 'CP',  value:  this.compradores_tag[i].codigo_postal });
    }

    // this.compradores_tag.forEach((data) => {
    //   this.params.push({ name: 'EMAIL', value:  data.comprador.correo_electronico });
    //   this.params.push({ name: 'PHONE', value:  data.comprador.telefono_movil });
    //   this.params.push({ name: 'NAME',  value:  data.comprador.nombre });
    //   this.params.push({ name: 'LASTNAME',  value:  data.comprador.apellidos });
    //   this.params.push({ name: 'STREET',  value:  data.comprador.calle });
    //   this.params.push({ name: 'CITY',  value:  data.comprador.estado });
    //   this.params.push({ name: 'STATE',  value:  data.comprador.municipio });
    //   this.params.push({ name: 'COUNTRY',  value:  data.comprador.pais });
    //   this.params.push({ name: 'CP',  value:  data.comprador.codigo_postal });
    // });
   

    
  }


  scrollPage(name) {
    var posicion = $(name).offset().top;
    $("html, body").animate({
        scrollTop: posicion
    }, 2000); 
  }
}
