import { Injectable } from '@angular/core';
import { Boleto } from '../models/Boleto';

@Injectable()
export class LocalstorageService {
  setBoletosGenerados(boletos_generados: Array<Object>) {
    sessionStorage.setItem('BoletosGenerados', JSON.stringify(boletos_generados));
  }

  getBoletosGenerados() {
    return sessionStorage.getItem('BoletosGenerados');
  }

  deleteBoletosGenerados() {
    sessionStorage.removeItem('BoletosGenerados');
  }

  getBoletosRecomendados() {
    return sessionStorage.getItem('BoletosRecomendados');
  }

  setBoletosRecomendados(boletos_recomendados: Array<Boleto>) {
    sessionStorage.setItem('BoletosRecomendados', JSON.stringify(boletos_recomendados));
  }

  getBoletosAsignados() {
    return sessionStorage.getItem('BoletosAsignados');
  }

  deleteBoletosAsignados() {
    sessionStorage.removeItem('BoletosAsignados');
  }

  setBoletosAsignados(boletos_asignados: Array<Boleto>) {
    sessionStorage.setItem('BoletosAsignados', JSON.stringify(boletos_asignados));
  }

  getBoletosApartados() {
    return sessionStorage.getItem('BoletosApartados');
  }

  setBoletosApartados(boletos_apartados: Array<Boleto>) {
    sessionStorage.setItem('BoletosApartados', JSON.stringify(boletos_apartados));
  }

  deleteBoletosApartados() {
    sessionStorage.removeItem('BoletosApartados');
  }

  setArrayLogin(login: Array<Object>) {
    localStorage.setItem('Login', JSON.stringify(login));
  }

  getArrayLogin() {
    return localStorage.getItem('Login');
  }

  deleteArrayLogin() {
    localStorage.removeItem('Login');
  }

  deleteBoletosRecomendados() {
    sessionStorage.removeItem('BoletosRecomendados');
  }

  setFechaInicio(date: Date) {
    localStorage.setItem('FechaInicio', date.toString());
  }

  getFechaInicio() {
    return localStorage.getItem('FechaInicio');
  }

  deleteFechaInicio() {
    localStorage.removeItem('FechaInicio');
  }

  setFechaFin(date: Date) {
    date.setMinutes(date.getMinutes() + 17);
    localStorage.setItem('FechaFin', date.toString());
  }

  getFechaFin() {
    return localStorage.getItem('FechaFin');
  }

  deleteFechaFin() {
    localStorage.removeItem('FechaFin');
  }

  setFolio(folio: string) {
    localStorage.setItem('Folio', folio);
  }

  getFolio() {
    return localStorage.getItem('Folio');
  }

  deleteFolio() {
    localStorage.removeItem('Folio');
  }
  setPKColab(pkcolab: string) {
    localStorage.setItem('pkcolab', pkcolab);
  }
  getPKColab() {
    return localStorage.getItem('pkcolab');
  }
  removePKColab() {
    localStorage.removeItem('pkcolab');
  }
  setMecanismoColabExt(Mecanismo: string) {
    localStorage.setItem('MecanismoColab', Mecanismo);
  }
  getMecanismoColabExt() {
    return localStorage.getItem('MecanismoColab');
  }
  removeMecanismoColabExt() {
    localStorage.removeItem('MecanismoColab');
  }

  setBoletosBloqueados(boletos_bloqueados: Array<Object>) {
    sessionStorage.setItem('BoletosBloqueados', JSON.stringify(boletos_bloqueados));
  }

  getBoletosBloqueados() {
    return sessionStorage.getItem('BoletosBloqueados');
  }

  deleteBoletosBloqueados() {
    sessionStorage.removeItem('BoletosBloqueados');
  }
  setCarrito(idcarrito: string) {
    sessionStorage.setItem('idcarrito', idcarrito);
  }

  getCarrito() {
    return sessionStorage.getItem('idcarrito');
  }

  deleteCarrito() {
    sessionStorage.removeItem('idcarrito');
  }

  setPaybackref(compradorPayback: number) {
    localStorage.setItem('Paybackref', compradorPayback.toString());
  }
  getPaybackref() {
    return localStorage.getItem('Paybackref');
  }
  deletePaybackref() {
    localStorage.removeItem('Paybackref');
  }
/** Cambios Luis Aguilar WECODE */
  setCampaingnData(campaingData: any) {
    localStorage.setItem('campaingData', JSON.stringify(campaingData));
  }
  getCampaingnData() {
    return JSON.parse(localStorage.getItem('campaingData'));
  }
  deleteCampaingnData() {
    localStorage.removeItem('campaingData');
  }
  /** END Cambios Luis Aguilar WECODE */
}
