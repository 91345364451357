import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

// import { Config } from '../config';
@Injectable()
export class HttpService {
  apiURL: string;

  constructor(protected _http: HttpClient) {
    this.apiURL = environment.UrlBase;
  }

  public get(url: string): Observable<any> {
    return this._http.get(url);
  }

  public post(url: string, payload: string, token?: string): Observable<any> {
    return this._http.post(url, payload);
  }

  public put(url: string, payload: string, token?: string): Observable<any> {
    return this._http.put(url, payload);
  }

  public delete(url: string, token: string): Observable<any> {
    return this._http.delete(url);
  }
}
