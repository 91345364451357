<section class="section-login">
  <div class="container" data-aos="zoom-in-down">
      <!-- <div class=""> -->
      <div class="row">
        <div class="col-md-12">
          <div class="card card-container-radio">
           
            <label class="radio">
             
              <input
                type="radio"
                checked="checked"
                [(ngModel)]="tipoLogin"
                [value]="1"
                name="radio"
                
              />
              <img src="/assets/img/sorteo2023/pic-comprador.png" alt="Comprador" class="img-fluid">
              Soy Comprador
            </label>
    
            <label class="radio">
             
              <input
                type="radio"
                name="radio"
                [value]="2"
                [(ngModel)]="tipoLogin"
                style="transform: scale(1.3); display: inline"
              />
              <img src="/assets/img/sorteo2023/pic-colaborador.png" alt="Comprador" class="img-fluid">
              Soy Colaborador  
            </label>

            <label class="radio">
             
              <input
                type="radio"
                name="radio"
                [value]="3"
                [(ngModel)]="tipoLogin"
                style="transform: scale(1.3); display: inline"
              /> 
              <img src="/assets/img/sorteo2023/pic-boletos.png" alt="Comprador" class="img-fluid">
              Compartir mis boletos
               
            </label>
          </div>
        </div>
        <div class="col-md-12">
          <div class="card card-form" [hidden]="tipoLogin === 2 || tipoLogin === 3">
            <label>Ingresa tu correo electrónico para encontrar los boletos pendientes por pagar</label>
            <form class="form-signin">
              <span id="reauth-email" class="reauth-email"></span>
              <input
                [(ngModel)]="inputEmail"
                type="email"
                id="inputEmail1"
                name="inputEmail1"
                class="form-control"
                style="z-index: 0"
                placeholder="comprador@ejemplo.com"
                required
                autofocus
                maxlength="60"
                pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
              />
              <span id="mensajeError">Ingresa un correo valido</span>
              <button
                class="btn-stack"
                style="z-index: 0"
                (click)="entrarPagoCorreo(inputEmail)"
                type="submit"
              >
                Ver Boletos
              </button>
            </form>
          </div>
          <div class="card card-container card-form" [hidden]="tipoLogin === 1 || tipoLogin === 3">
            <!-- <img id="profile-img" class="profile-img-card" src="../../../dist/assets/img/AHC_LP_Logo.png" />
                    <p id="profile-name" class="profile-name-card"></p>-->
            <label>Ingresa tu correo electrónico para encontrar los boletos pendientes por pagar</label>
            <form class="form-signin">
              <div class="margen-b">
                <span id="reauth-email" class="reauth-email"></span>
                <input
                  [(ngModel)]="inputEmail"
                  type="email"
                  id="inputEmail"
                  name="inputEmail"
                  class="form-control"
                  style="z-index: 0"
                  placeholder="colaborador@anahuac.mx"
                  required
                  autofocus
                  maxlength="60"
                />
                <span id="mensajeError">Ingresa un correo valido</span>

                <span *ngIf="mostrarMsj">{{ mensaje }}</span>
              </div>
              <!--  <div id="remember" class="checkbox">
                            <label class="checklabel" style="color: #fff">
                                <input type="checkbox" value="remember-me"> Recuerdame
                            </label>
                        </div>-->
              <button
                class="btn-stack"
                style="z-index: 0"
                (click)="entrar(inputEmail)"
                type="submit"
              >
                Ver Boletos
              </button>
            </form>
          </div>
           <div class="card card-container card-form" [hidden]="tipoLogin === 1 || tipoLogin === 2">
            <label>Ingresa tu correo electrónico para encontrar los boletos a compartir</label>
            <form class="form-signin">
              <span id="reauth-email" class="reauth-email"></span>
              <input
                [(ngModel)]="inputEmail"
                type="email"
                [email] = "true"
                id="inputEmail1"
                name="inputEmail1"
                class="form-control"
                style="z-index: 0"
                placeholder="compartir@anahuac.mx"
                required
                autofocus
                (ngModelChange) = "mostrarMsj = false"
                maxlength="60"
              />
              <span *ngIf="mostrarMsj">{{mensaje}}</span>
              <span id="mensajeError">Ingresa un correo valido</span>
              <button
                class="btn-stack"
                style="z-index: 0"
                (click)="compartirBoletos(inputEmail)"
                type="submit"
              >
                Compartir Boletos
              </button>
            </form>
          </div>
        </div>
      </div>

    
  </div>
</section>