<section class="pay-msg">
  <div class="container">

      <div class="bg row">
        <div class="col-md-12 mensaje BoletosPay d-flex justify-content-center flex-column" data-aos="fade-left"  data-aos-easing="ease"  data-aos-duration="3000">
          <h2 *ngIf="pendiente == 0">Procesando...</h2>
          <h2 *ngIf="pendiente == 1">No hay boletos pendientes por pagar y/o el correo es incorrecto</h2>
          <h2 *ngIf="pendiente == 2">El boleto no se encuentra disponible. Favor de intentar nuevamente</h2>
          <h2 *ngIf="pendiente == 3">El boleto ya no se encuentra disponible.</h2>
        </div>
        <div class="spinnback" id="spin" [hidden]="false">
          <div class="spinn">
            <div class="spinner-border" role="status">	
              <span class="sr-only">Loading...</span>
            </div>
            <br>
            <label style="color: whitesmoke;font-family: sofiaproregular;">Espere por favor. <br> Procesando</label>
          </div>
          </div>
      </div>
      

  </div>
</section>


