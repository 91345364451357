<section class="stc-venta-exitosa">
  <div class="container">
    <div class="row">
      <div class="col-md-6 d-flex justify-content-center flex-column order-md-1 order-2" data-aos="fade-left"
      data-aos-easing="ease" data-aos-duration="3000">
         <img src="/assets/img/sorteo2024/check.png" alt="Pago exitoso" class="img-fluid">
      </div>

      <div class="col-md-6 my-auto order-md-2 order-1">
        <h1>
          ¡gracias por
          <span class="d-md-block">
            tu apoyo!
          </span>
        </h1>
      </div>
      
    </div>
  </div>
  <a (click)="scrollPage('#orden')" class="cta-bottom d-md-block d-none" >
    <img src="/assets/img/sorteo2023/arrow-bottom.png" class="img-fluid" >
  </a>
</section>


<section class="venta-ext">
  <div class="container" data-aos="fade-down" id="orden">
    <div
      class="row bg"
      *ngIf="
        request.nbResponse == 'Aprobado' ||
        (reqBanamex.resultIndicator != undefined && reqBanamex.resultIndicator == orden_banamex)
      "
    >

     
        <div class="col-12">
          <h3 style="color: #ff4700;
          font-weight: 700;
          font-size: 36px;
          margin-bottom: 18px;">
            {{textoInt}}
          </h3>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 center" >
          <label class="title">Recibirás en tu correo la información de la compra de tu(s) boleto(s)</label>
        </div>


        <div class="BoletosPay  col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <h2 id="CantBoletos1" class="center" [hidden]="true">Boleto(s) pagado(s): {{ boletos.length }}</h2>
          <br />
          <div class="row h-5">
            <!-- <div class="center col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"> -->
            <div class="center col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div style="overflow-x: auto; overflow-y: auto; max-height: 210px">
                <table id="tabla" *ngIf="boletos" class="center">
                  <thead class="center">
                    <tr>
                      <th class="texto-primario">No. Boleto</th>
                      <th class="texto-primario">Dueño del Boleto</th>
                      <th class="texto-primario">Correo</th>
                      <th class="texto-primario">Costo</th>
                    </tr>
                  </thead>
                  <tbody class="center table">
                    <tr *ngFor="let boleto of boletos">
                      <td class="texto-primario">{{ boleto.folio }}</td>
                      <td class="texto-primario">{{ boleto.nombre_comprador }}</td>
                      <td class="texto-primario">{{ boleto.correo_comprador }}</td>
                      <td class="texto-primario" id="Bcost">$ {{ boleto.costo }} MXN</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="center col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <label class="title"><br />¡Gracias por participar en el Sorteo Anáhuac!</label><br />
              <label>¡Te deseamos mucha suerte!</label>
              <hr class="hr-orange" />

              <label class="container">
                <input type="checkbox" id="checkPerpetuo" (click)="MarcarBoleto()" />
                Quiero reservar este(os) número(s) para comprarlo(s) en futuros sorteos
                
               
              </label>
            </div>
          </div>
        </div>
        <div class="margen-b w-100">
          <div class="row" style=" margin: 0 25px;">
            <div class="span1 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <label class="ml-5m small">Tu pago por ${{ request.importe }} MXN ha sido recibido con éxito</label><br />
              <label class="ml-5m small">ORDEN DE COMPRA:&nbsp;{{ request.referencia }}</label
              ><br />
              <label class="ml-5m small">MONTO&nbsp; $ {{ request.importe }} MXN</label><br />
            </div>
            <div class="span2 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 center">
              <div class="col-md-12 col-lg-12 mb-2 text-center">
                <button (click)="terminar()" class="btn-stack border-0">
                  Seguir comprando
                </button>
              </div>
              <div class="col-md-12 col-lg-12 mb-2" hidden="true">
                <button (click)="terminar()" class="btn-stack border-0 mt-3 continue">Quiero ser colaborador</button>
              </div>
              <div class="col-md-12 col-lg-12">
                <button (click)="terminar()" class="btn-stack border-0 mt-3">Terminar</button>
              </div>
            </div>
            <div class="span1 col-md-4 col-lg-4 col-xl-4"></div>
          </div>
        </div>
   

      <!-- <div *ngIf="request.nbResponse == 'Rechazado'" class="col-xs-12 col-sm-12 col-md-4 col-md-offset-4 margen-t"> -->
      <!-- <div class="col-xs-12 col-sm-12 col-md-4 col-md-offset-4 RechazadoC space-top"> -->
      <!-- <div *ngIf="request.nbResponse == 'Rechazado'"  class=" BoletosPay col-xs-12 col-sm-12 col-md-12 col-lg-12 RechazadoC center space-top"> -->
      <!-- <div *ngIf="request.nbResponse == null || request.nbResponse == 'Rechazado' || request.nbResponse == 'Invalido' || request.nbResponse == 'Bloqueo'"  class=" BoletosPay col-xs-12 col-sm-12 col-md-12 col-lg-12 RechazadoC center space-top"> -->

      <!-- <div *ngIf="request.nbResponse == 'Invalido'" class="col-xs-12 col-sm-12 col-md-4 col-md-offset-4 margen-t">
                <label style="text-align:center;">Lo sentimos, uno o varios de sus boletos ya no se encontraban disponibles. Favor de intentar nuevamente</label>

                <div class="col-xs-12 col-sm-12 margen-t">
                    <div class="row margen-t">
                        <div class="col-12 margen-b">
                            <button (click)="terminar()" class="btn btn-primary center">Terminar</button>
                            <br />
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="request.nbResponse == 'Bloqueo'" class="col-xs-12 col-sm-12 col-md-4 col-md-offset-4 margen-t">
                <label style="text-align:center;">Hubo un error al generar tu pago, por favor inténtalo nuevamente</label>

                <div class="col-xs-12 col-sm-12 margen-t">
                    <div class="row margen-t">
                        <div class="col-12 margen-b">
                            <button (click)="terminar()" class="btn btn-primary center">Terminar</button>
                            <br />
                        </div>
                    </div>
                </div>
            </div> -->
      <!-- <div class="row space-top" *ngIf="request.nbResponse == null" style="width: 100%; height: 322px;">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 h-5 center">
                    <label>Error: Acceso bloqueado</label>
                </div>
            </div>   -->
    </div>

    <div
      class="row bgerror"
      *ngIf="request.nbResponse != 'Aprobado' && reqBanamex.resultIndicator == undefined"
      data-aos="fade-down"
    >
      <div class="BoletosPay col-xs-12 col-sm-12 col-md-12 col-lg-12 RechazadoC center space-top">
        <div>
          <h2 class="texto-primario errortitle" style="text-align: center">Transacción no exitosa</h2>
        </div>
        <div>
     
        </div>
        <div *ngIf="request.nbResponse == 'Rechazado'">
          <!-- <label style="text-align:center;">Lo sentimos, el pago por ${{request.importe}} MXN ha sido rechazado por su banco.</label>
            <label style="text-align:center;">Mensaje del banco: {{request.cdResponse}}</label>
            <label style="text-align:center;">" {{request.error}} "</label> -->
          <label>Lo sentimos, pago rechazado por su banco.</label>
        </div>

        <div *ngIf="request.nbResponse == 'Invalido'">
          <label class="errorlabel" style="text-align: center"
            >Lo sentimos, uno o varios de sus boletos ya no se encontraban disponibles. Favor de intentar nuevamente</label
          >
        </div>

        <div *ngIf="request.nbResponse == 'Bloqueo'">
          <label class="errorlabel" style="text-align: center"
            >Hubo un error al generar tu pago, por favor inténtalo nuevamente</label
          >
        </div>

        <div *ngIf="request.nbResponse == null">
          <label class="errorlabel">Inténtalo nuevamente</label>
        </div>

        <div class="col-xs-12 col-sm-12 margen-t center">
          <div class="row margen-t">
            <div class="col-12 margen-b center">
              <button (click)="terminar()" class="btn-stack border-0 mt-3">Terminar</button>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>