
<section class="stc-comp">
  <div class="bg">
    
        <div class="container text-center">
          
            <div *ngIf="has_userkey" class="card">
              <div class="card-header">
                <h3 class="card-title my-4">Comparte tus boletos del Sorteo Anáhuac en línea</h3>
              </div>
              <div class="card-body">
                <div class="mb-3">
                  <h3 class="mb-0 ">¡Hola {{user.nombre ? user.nombre : ' Colaborador'}}!</h3>
                  <h5 class=" mb-0 text-secondary"> {{user.clave ? 'CLAVE ' + user.clave : ''}}</h5>
                </div>

                <h5 class="card-text col-12 col-lg-8 col-md-10 col-sm-12 mx-auto">
                  Invita a tus amigos a comprar un boleto de sorteo Anáhuac por medio de tus redes sociales.
                </h5>
                <div class="content-btn my-3">
                  <a class="mx-3" href="https://www.facebook.com/sharer/sharer.php?u={{link}}" target="_blank">
                    <img src="/assets/img/pasa-la-voz/facebook.png" width="25px" height="auto">
                  </a>
                  <a class="mx-3" href="https://www.instagram.com/" target="_blank">
                      <img src="/assets/img/pasa-la-voz/insta.png" width="60px" height="auto">
                  </a>
                  <a class="mx-3" href="https://api.whatsapp.com/send?text=Compra%20un%20boleto%20de%20Sorteo%20Anáhuac%20y%20gana%20fabulosos%20premios%0A¡Apoya%20a%20la%20educación!{{link}}" target="_blank">
                      <img src="/assets/img/pasa-la-voz/whats.png" width="60px" height="auto">
                  </a>
                  <a class="mx-3" href="https://twitter.com/intent/tweet?text=Compra%20un%20boleto%20de%20Sorteo%20Anáhuac%20y%20gana%20fabulosos%20premios%0A¡Apoya%20a%20la%20educación! {{link}}&" target="_blank">
                      <img src="/assets/img/pasa-la-voz/tw.png" width="60px" height="auto">
                  </a>
                </div>
                <div class="row">
                  <div class="form-group col-12 col-lg-8 col-md-10 col-sm-12 mx-auto mt-2 mb-0">
                    <h5 class="card-text">Puedes copiar este link para compartirlo donde quieras.</h5>
                    <input id="share_link" #sharelink type="text" class="form-control" value="{{link}}" readonly>
                    <p class="card-text mt-2">Este es el link con tu clave para invitar a comprar.</p>
                    <a (click)="copylink(sharelink)" class="btn-stack">Copiar link</a>
                    <div id="message" class="mensaje hidden my-2">
                      ¡Enlace copiado!
                    </div>
                  </div>
                </div>
              </div>
            </div>
          

            <div *ngIf="!has_userkey"  class="my-5">
              <div class="card my-5">
                <div class="card-header">
                  <h3 class="card-title my-4">Comparte tus boletos del Sorteo Anáhuac en línea</h3>
                </div>
                <div class="card-body">
                  <h5 class="card-text">
                    {{message}}
                  </h5>
                  <a routerLink="/pagar-boletos-en-linea">Regresar</a>
                </div>
              </div>
            </div>

        </div>
  </div>

 </section>
 
