import { Component } from '@angular/core';
import { Observable, Subscription } from 'rxjs/Rx';
import { Router, NavigationEnd } from "@angular/router";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {

    constructor(private router: Router) {
        this.router.events.subscribe(event => {
        //  if (event instanceof NavigationEnd) {
        //    (<any>window).ga('set', 'page', event.urlAfterRedirects);
        //    (<any>window).ga('send', 'pageview');
        //  }
       });
     }

    ticks = 0;

    minutesDisplay: number = 0;
    hoursDisplay: number = 0;
    secondsDisplay: number = 0;

    sub: Subscription;

    public startTimer() {
        ////console.log("ENTRA");
        let timer = Observable.timer(1, 1000);
        this.sub = timer.subscribe(
            t => {
                this.ticks = t;

                this.secondsDisplay = this.getSeconds(this.ticks);
                this.minutesDisplay = this.getMinutes(this.ticks);
                this.hoursDisplay = this.getHours(this.ticks);
            }
        );
    }

    public getSeconds(ticks: number) {
        return this.pad(ticks % 60);
    }

    public getMinutes(ticks: number) {
        return this.pad((Math.floor(ticks / 60)) % 60);
    }

    public getHours(ticks: number) {
        return this.pad(Math.floor((ticks / 60) / 60));
    }

    public pad(digit: any) {
        return digit <= 9 ? '0' + digit : digit;
    }
}
