import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public Url_privacidad : string;
  public Url_reglamento : string;
  public Url_terminos : string;
  public url_sitio: string;
  
  constructor() {
    this.Url_privacidad = environment.Url_Avisos_Privacidad;
    this.Url_reglamento = environment.Url_reglamento;
    this.Url_terminos = environment.Url_terminos;

    this.url_sitio = environment.Urlsitio
   }

  ngOnInit() {

  }

}
