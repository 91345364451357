import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { HttpModule } from '@angular/http';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { SimpleTimer } from 'ng2-simple-timer';
import { BsModalService } from 'ngx-bootstrap/modal';
// import { AlertsModule } from 'angular-alert-module';
//import { CountdownTimerModule } from 'ngx-countdown-timer';
import { AppComponent } from './app.component';
import { BoletosColaboradoresCarritoComponent } from './boletos-colaboradores-carrito/boletos-colaboradores-carrito.component';
import { BoletosColaboradoresComponent } from './boletos-colaboradores/boletos-colaboradores.component';
import { BoletosColaboradoresService } from './boletos-colaboradores/services/boletos-colaboradores.service';
import { CounterComponent } from './counter/counter.component';
import { DatosCompradorComponent } from './datos-comprador/datos-comprador.component';
import { DatosCompradorService } from './datos-comprador/services/datos-comprador.service';
import { FetchDataComponent } from './fetchdata/fetchdata.component';
//import { FooterComponent } from './footer/footer.component';
import { FormaPagoComponent } from './forma-pago/forma-pago.component';
import { HomeComponent } from './home/home.component';
import { HomeService } from './home/services/home.service';
import { LoginComponent } from './login/login.component';

import { NavMenuComponent } from './navmenu/navmenu.component';
import { PagarComponent } from './pagar/pagar.component';
import { HttpService } from './services/http.service';
import { MyHttpGetService } from './services/http.services.token';
import { LocalstorageService } from './services/localstorage.service';
import { ScriptService } from './services/script.service';
import { SessionstorageService } from './services/sessionstorage.service';
import { TimerService } from './timer/timer.service';
import { UiModule } from './ui/ui.module';
import { VentaExitosaComponent } from './venta-exitosa/venta-exitosa.component';
import { CompartirComponent } from './compartir/compartir.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SingleBoletosColComponent } from './single-boletos-col/single-boletos-col.component';


/** Cambios Luis Aguilar WECODE */
import { ModalModule } from 'ngx-bootstrap/modal';
/** END Cambios Luis Aguilar WECODE */
import { ErrorPagoComponent } from './error-pago/error-pago.component';
import { environment } from 'src/environments/environment';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { SeguimientoService } from './services/seguimiento.service';


@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpModule,
    // CountdownTimerModule,
    // AlertsModule.forRoot(),
    // BsModalService,
    /** Cambios Luis Aguilar WECODE */
    ModalModule.forRoot(),
    /** END Cambios Luis Aguilar WECODE */
    RouterModule.forRoot(
      [
        { path: '', redirectTo: 'compra-en-linea-boletos-sorteo-anahuac', pathMatch: 'full' },
        { path: 'home', component: HomeComponent },
        { path: 'boletos-colaborador/:id', component: BoletosColaboradoresComponent },
        { path: 'boletos-colaborador-anahuac-online/:id', component: SingleBoletosColComponent },
        { path: 'datos-comprador', component: DatosCompradorComponent },
        { path: 'venta-exitosa', component: VentaExitosaComponent },
        { path: 'forma-pago', component: FormaPagoComponent },
        { path: 'carrito-compra', component: BoletosColaboradoresCarritoComponent },
        { path: 'compartir-boleto', component: CompartirComponent },
        { path: 'pagar/:id/:pk', component: PagarComponent },
        { path: 'pagar/:id/:pk/:boleto', component: PagarComponent },
        { path: 'login', component: LoginComponent },
        { path: 'pagar', component: LoginComponent },
        { path: 'home/:liga_colaborador', component: HomeComponent },
        { path: 'compra-en-linea-boletos-sorteo-anahuac', component: HomeComponent },
        { path: 'compra-en-linea-boletos-sorteo-anahuac/:liga_colaborador', component: HomeComponent },
        { path: 'pagar-boletos-en-linea', component: LoginComponent },
        { path: 'pagar-boletos-en-linea/:id/:pk', component: PagarComponent },
        { path: 'pagar-boletos-en-linea/:id/:pk/:boleto', component: PagarComponent },
        { path: 'error-pago', component: ErrorPagoComponent },

        { path: '**', redirectTo: 'compra-en-linea-boletos-sorteo-anahuac' },
      ],
      {
        scrollPositionRestoration: 'enabled',
      }
    ),

    UiModule,
  ],
  declarations: [
    AppComponent,
    NavMenuComponent,
    CounterComponent,
    FetchDataComponent,
    HomeComponent,
    BoletosColaboradoresComponent,
    DatosCompradorComponent,
    VentaExitosaComponent,
    FormaPagoComponent,
    BoletosColaboradoresCarritoComponent,

    //FooterComponent,
    PagarComponent,
    LoginComponent,
    CompartirComponent,
    SingleBoletosColComponent,
    ErrorPagoComponent,
  ],

  providers: [
    BoletosColaboradoresService,
    HttpService,
    HomeService,
    LocalstorageService,
    SessionstorageService,
    MyHttpGetService,
    SimpleTimer,
    DatosCompradorService,
    TimerService,
    ScriptService,
    SeguimientoService,
    /** Cambios Luis Aguilar WECODE */
    BsModalService
    /** END Cambios Luis Aguilar WECODE */
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  urlMain = environment.UrlBase;
  intervalMs: number = 12 * 60 * 60 * 1000; // 12 horas en milisegundos
  lastCall: string = 'Nunca';
  constructor(private http: HttpClient) {

    // const lastCallTime = localStorage.getItem('lastCallTime');
   
    // if (!lastCallTime || Date.now() - parseInt(lastCallTime, 10) >= this.intervalMs) {
    //   this.performApiCall();
    // }

    // interval(120000).subscribe(() => {
    //   const lastCallTime = localStorage.getItem('lastCallTime');
    //   // Si no hay registro en el localStorage o han pasado 12 horas, realiza la llamada
    //   if (!lastCallTime || Date.now() - parseInt(lastCallTime, 10) >= this.intervalMs) {
    //     this.performApiCall();
    //   }
    // });


   


    //cachar utm de pagiana web
    let urlUtm = window.location.href;
    let utm = urlUtm.split("?");
    let search = utm[1];
    //console.log(search);

    if (search == undefined) {
      let organico = "?utm_campaign=organico&utm_content=organico&utm_placement=organico&campaign_name=organico&site_source_name=organico&placement=organico";
      let utm2 = organico.split("?");
      var search2 = utm2[1];
      //console.log(JSON.stringify(organico));
      let finalUtm = JSON.parse('{"' + search2.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) { return key === "" ? value : decodeURIComponent(value) });
      ////console.log(finalUtm);
      localStorage.setItem('campaingData', JSON.stringify(finalUtm));
    }

    if (search) {
      //console.log("entro");
      //console.log(search.toString());
      //console.log(search.toString().split("utm_campaign"));
      //console.log(search.toString().split("utm_campaign").length);
      if (search.toString().split("utm_campaign").length > 1) {
        let finalUtm = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) { return key === "" ? value : decodeURIComponent(value) });
        // lead.site_source_name = finalUtm.site_source_name ? finalUtm.site_source_name : "";
        // lead.placement = finalUtm.placement ? finalUtm.placement : "";
        // lead.utm_campaign = finalUtm.utm_campaign ? finalUtm.utm_campaign : "";
        // lead.utm_content = finalUtm.utm_content ? finalUtm.utm_campaign : "";
        //console.log(finalUtm);
        localStorage.setItem('campaingData', JSON.stringify(finalUtm));
      } else {
        let campaingData = JSON.parse(localStorage.getItem('campaingData'));
        if (campaingData && campaingData['pk']) {
          // console.log("existe camapaña");
        } else {
          let organico = "?utm_campaign=organico&utm_content=organico&utm_placement=organico&campaign_name=organico&site_source_name=organico&placement=organico";
          let utm2 = organico.split("?");
          var search2 = utm2[1];
          //console.log(JSON.stringify(organico));
          let finalUtm = JSON.parse('{"' + search2.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) { return key === "" ? value : decodeURIComponent(value) });
          ////console.log(finalUtm);
          localStorage.setItem('campaingData', JSON.stringify(finalUtm));
        }

      }
    } else {
      //console.log("No existe utm es otra cosa");
    }




  }

  performApiCall(): void {
      //debugger;
      //console.log("1");
      this.http.get(this.urlMain+'api/createTokenPaypal').subscribe((response: any) => {
        
        //console.log("api generada");
        this.lastCall = new Date().toLocaleTimeString();
        // Guarda la hora de la llamada actual en el localStorage
        localStorage.setItem('lastCallTime', Date.now().toString());

       },
       err => {
          console.log(err.status);
       });

       //console.log("2");

  }

}
