<section class="section-colabora">
	<div class="container" >
		<div class="bg row text-center">
				<!-- LOGO -->
				<div class="col-md-12">
					<h1> BOLETOS RECOMENDADOS</h1>
				</div>
		
				<div class="col-md-12" *ngIf="boletos_colaborador.length !== 0">
					<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 margen-to">
						<h2>Elige los boletos que le desees comprar a {{nombre_colaborador}}:</h2>
					</div>
		
		
					<!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-top: 10%;">
						<div *ngFor="let boleto of boletos_colaborador" style="display: flex; width: 50%;">
							<div style="display: flex; justify-content: around">
								<label class="container" style="width: 30%;">
									{{boleto.folio}}
									<input type="checkbox" [(ngModel)]="boleto.seleccionado" checked="false">
									<span class="checkmark"></span>
								</label>
							</div>
						</div>
					</div> -->
					<div class="BoletosPay center texto-primario mt-5 row"   *ngIf="boletos_colaborador.length !== 0" >
						<div  *ngFor="let boleto of boletos_colaborador"class="col-md-4" >
							<!-- <div *ngFor="let boleto of boletos_colaborador" class="col-xs-6 col-sm-12 col-md-12 col-lg-12 col-xl-12 texto-primario"> -->				
								<!-- <div *ngFor="let boleto of boletos_colaborador" class="texto-primario"style="display: inline-block;">  -->						
									<label  >
										<input type="checkbox" [(ngModel)]="boleto.seleccionado" checked="false">
										<span>
											{{boleto.folio}}
										</span>
									</label>
								</div>				
						</div>
		
					<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-lg-offset-2 col-xl-offset-2" style="margin: 5% 0;">
						<!-- <button *ngIf="boletos_colaborador.length !== 0" (click)="add(boletos_colaborador);" class="btn btn-secondary">AGREGAR <img src="../../assets/img/AHC_LP_IconoCarritoBlanco.png"></button> -->
						<button (click)="add(boletos_colaborador);" *ngIf="boletos_colaborador.length !== 0" (click)="add(boletos_colaborador);" class="btn-stack">Agregar al carrito </button>
					</div>
				</div>
				<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 center"  *ngIf="boletos_colaborador.length == 0">
					<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 margen-to mb-5">
						<h2>Lo sentimos, por el momento no contamos con boletos disponibles.</h2>
					</div>
				</div>
				
				
		</div>
	
	</div>
</section>