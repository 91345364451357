import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Boleto } from '../models/Boleto';
import { MyHttpGetService } from '../services/http.services.token';
import { LocalstorageService } from '../services/localstorage.service';
import { SessionstorageService } from '../services/sessionstorage.service';
import { TimerService } from '../timer/timer.service';
/** Cambios Luis Aguilar WECODE */
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DatosCompradorService } from '../datos-comprador/services/datos-comprador.service';
import { BoletoValidar } from '../models/BoletoValidar';
/** END Cambios Luis Aguilar WECODE */

@Component({
  selector: 'app-boletos-colaboradores-carrito',
  templateUrl: './boletos-colaboradores-carrito.component.html',
  styleUrls: ['./boletos-colaboradores-carrito.component.scss'],
  providers: [MyHttpGetService, LocalstorageService],
})
export class BoletosColaboradoresCarritoComponent implements OnInit {
  //boletos_generados -> Boletos de publico general.
  //boletos_recomendados -> Boletos recomendados de un colaborador especifico.
  //boletos_asignados -> Boletos de un comprador previo.
  //boletos_bloqueados -> Array de boletos a bloquear para el cliente por un periodo de 15min.
  //boletos - > Boletos a mostrar en carrito de compra.
  //tipos_pago -> Metodos de pago a mostrar seg�n el tipo de sesi�n (1 -> PublicoGeneral, 2 -> Colaborador desde APP, 3 -> Colaborador desde PC)

  /////////////////////////

  public boletos_generados: Array<Boleto> = [];
  public boletos_recomendados: Array<Boleto> = [];
  public boletos_asignados: Array<Boleto> = []; //
  public boletos_bloqueados_local: Array<Boleto> = [];
  public boletos_bloqueados: { boletos: Array<Boleto>; fecha: string; carrito: string } = {
    boletos: [],
    fecha: '',
    carrito: '',
  };
  public boletos_p_liberar: { boletos: Array<Boleto>; fecha: string; orden: string; carrito: string } = {
    boletos: [],
    fecha: '',
    orden: '',
    carrito: '',
  };
  public boletos: Array<Boleto> = [];
  public total_compra: number = 0;
  public bolCola: number = 0;
  public tipos_pago: number;
  public spinner: boolean = false;
  public carrito: string;




  constructor(
    private myGetService: MyHttpGetService,
    private _session: SessionstorageService,
    private _local: LocalstorageService,
    private router: Router,
    public st: TimerService,
  ) {
    var lsRecomendados = this._local.getBoletosRecomendados();
    var lsGenerados = this._local.getBoletosGenerados();
    var lsAsignados = this._local.getBoletosAsignados();
    var lsBloqueados = this._local.getBoletosBloqueados();
    var lsCarrito = this._local.getCarrito();
    var lsbolCol = this._session.getBoletosCol();
    this.bolCola = lsbolCol ? parseInt(lsbolCol) : 0;
    this.boletos_generados = lsGenerados == null ? [] : JSON.parse(lsGenerados);
    this.boletos_recomendados = lsRecomendados == null ? [] : JSON.parse(lsRecomendados);
    this.boletos_asignados = lsAsignados == null ? [] : JSON.parse(lsAsignados);
    this.boletos_bloqueados_local = lsBloqueados == null ? [] : JSON.parse(lsBloqueados);
    this.tipos_pago = parseInt(this._session.getTiposPago());
    this.carrito = lsCarrito;
  }

  async ngOnInit() {
    this.spinner = false;
    this.verificaCarrito();
    await this.getData(this.boletos_generados, this.boletos_recomendados, this.boletos_asignados);
    if (this.tipos_pago == 1) {
      this.st.startTimer();
    }
    this._local.deleteFolio();
    /// Pop up
    var popC = this._session.getPopstate();
    ////console.log(popC);
    //this.PopupAmigable(popC);


    
    // const folios = this.boletos.map(objeto => objeto.folio).join(',');
    // console.log(folios);


    // this.myGetService.checkTickets(folios).subscribe(
    //   (res: any) => {

    //     const body = JSON.parse(res._body);
    //     console.log(body);
    //     if (body.length > 0) {

    //       let boletosEnCarritoList = '';
    //       let boletosConReferenciaList = '';
    //       body.forEach(item => {
    //         if (item.RefBancaria && item.RefBancaria.trim() !== '') {
    //           boletosConReferenciaList += `<li>  <strong>Boleto:</strong> ${item.Folio} - <strong>Referencia: </strong> ${item.RefBancaria} - <strong>Fecha liberación:</strong> ${this.formatDate(item.FechaLiberacion)}</li>`;
    //         } else {
    //           boletosEnCarritoList += `<li> <strong>Boleto:</strong> ${item.Folio} - <strong>Fecha liberación:</strong> ${this.formatDate(item.FechaLiberacion)}</li>`;
    //         }

    //       });

    //       const confirmContent = `
    //         <div class="custom-confirm">
    //           <p>Existen problemas con tus boletos, a continuación te mostramos un resumen:</p>
    //           ${boletosEnCarritoList ?
    //           `<p><strong>Boletos en carrito: </strong></p>
    //             <ul>${boletosEnCarritoList}</ul>`
    //           : ''}
    //           ${boletosConReferenciaList ?

    //           `<p><strong>Boletos con referencia bancaria: </strong></p>
              
    //             <ul>${boletosConReferenciaList}</ul>`
    //           : ''}
    //           <p><strong>NOTA:</strong> Si tienes varios boletos por pagar, te recomendamos que quites del carrito (es decir, los boletos que aparecen en pantalla o que tienen referencia bancaria) usando la "x" que aparece junto a cada boleto. Si intentas proceder al pago sin quitar estos boletos, es posible que se produzca un error.</p>

    //         </div>
    //       `;


    //       if (window['alertify']) {
    //         (window as any).alertify
    //           .confirm('Estado de los boletos', confirmContent,
    //             () => {
    //               (window as any).alertify.success('¡LO TENGO!');
    //               console.log("Aceptar");
    //             },
    //             () => {
    //               console.log("Cancelar");
    //             }
    //           )
    //           .set('labels', { ok: 'He leído', cancel: '' })
    //           .set('closable', false)
    //           .set('defaultFocus', 'ok')
    //           .set('className', 'custom-confirm');
    //       }


    //     }
    //   },
    //   (error) => {
    //     console.error('Error en la solicitud:', error);
    //   }
    // );


  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'long',
      year: 'numeric'
    };
    return date.toLocaleDateString('es-ES', options);
  }

  verificaCarrito(): void {
    let boletosl: Boleto[] = this.bolCola == 0 ? this.boletos_recomendados : this.boletos_generados;

    boletosl.forEach((boleto) => {
      this.deleteBoleto(boleto);
    });
  }

  async getData(
    boletos_generados: Array<Boleto>,
    boletos_recomendados: Array<Boleto>,
    boletos_asignados: Array<Boleto>
  ) {
    //debugger;
    var fechaFin = new Date(this._local.getFechaFin()); //Fecha en que se limpia el carrito
    var fechaInicio = new Date(this._local.getFechaInicio()); //Fecha en que se agrego el boleto al carrito
    var seconds = fechaFin.getTime() - fechaInicio.getTime(); // Timer

    if (this.tipos_pago == 1) {
      if (boletos_generados !== []) {
        boletos_generados.sort(function (a, b) {
          return a.folio - b.folio;
        });

        for (var i = 0; i <= boletos_generados.length - 1; i++) {
          this.total_compra = this.total_compra + boletos_generados[i].costo;
          this.boletos.push(boletos_generados[i]);
        }
      }

      if (boletos_recomendados !== []) {
        boletos_recomendados.sort(function (a, b) {
          return a.folio - b.folio;
        });
        for (var i = 0; i <= boletos_recomendados.length - 1; i++) {
          this.total_compra = this.total_compra + boletos_recomendados[i].costo;
          this.boletos.push(boletos_recomendados[i]);
        }
      }
      ////console.log(fechaFin);
      this.boletos_bloqueados.boletos =
        this.boletos_generados.length > 0 ? this.boletos_generados : this.boletos_recomendados;
      if (this.boletos_bloqueados_local.length > 0) {
        this.boletos_bloqueados_local.forEach((boleto) => {
          this.boletos_bloqueados.boletos = this.boletos_bloqueados.boletos.filter((b) => b.clave !== boleto.clave);
        });
      }

      this.boletos_bloqueados.fecha = fechaFin.toISOString();
      this.boletos_bloqueados.carrito = this.carrito;
      ////console.log(this.boletos_bloqueados);

      if (this.boletos_bloqueados.boletos.length > 0) {
        return new Promise((resolve, reject) => {
          this.myGetService.postData(this.boletos_bloqueados, 'api/BoletosWeb/ValidaBoletosPagoMIT').subscribe(
            (res) => {
              this.myGetService.postData(this.boletos_bloqueados, 'api/BoletosWeb/ActualizaCarroBoletos').subscribe(
                (res) => {
                  ////console.log(res);
                  if (res.ok) {
                    if (!this.carrito) {
                      this.carrito = res.json();
                      this._local.setCarrito(this.carrito);
                    }
                    this.spinner = true;
                    this.boletos_bloqueados.boletos = [
                      ...this.boletos_bloqueados.boletos,
                      ...this.boletos_bloqueados_local,
                    ];
                    this._local.setBoletosBloqueados(this.boletos_bloqueados.boletos);
                    this.boletos_bloqueados_local = this.boletos_bloqueados.boletos;
                    resolve('Promise complete');
                  }
                },
                (error) => {
                  console.log(error);
                  //validar que este en carrito o mandar error
                  window.location.href = 'error-pago?success=false&nbResponse=ErrorDatosCarrito';
                }
              );
            },
            (err) => {
              window.location.href = 'error-pago?success=false&nbResponse=Invalido';
            }
          );
        });
      } else {
        this.spinner = true;
      }
    } else {
      if (boletos_asignados !== []) {
        return new Promise((resolve, reject) => {
          this.boletos_bloqueados.boletos = boletos_asignados;
          this.boletos_bloqueados.fecha = fechaFin.toISOString();
          this.boletos_bloqueados.carrito = '';
          this.myGetService.postData(this.boletos_bloqueados, 'api/BoletosWeb/ValidaBoletosPagoMIT').subscribe(
            (res) => {
              this._session.setContCarrito(1);
              boletos_asignados.sort(function (a, b) {
                return a.folio - b.folio;
              });
              for (var i = 0; i <= boletos_asignados.length - 1; i++) {
                this.total_compra = this.total_compra + boletos_asignados[i].costo;
                this.boletos.push(boletos_asignados[i]);
              }

              this.spinner = true;
              resolve('Promise complete');
            },
            (err) => {
              window.location.href = 'error-pago?success=false&nbResponse=Invalido';
            }
          );
        });
      }
    }
  }

  efectuarPago() {
    if (this.tipos_pago == 2 || this.tipos_pago == 3) {
      this.router.navigate(['forma-pago']);
    } else {
      this.router.navigate(['datos-comprador']);
    }
  }

  regresar() {
    if (this.tipos_pago == 2 || this.tipos_pago == 3) {
      this.router.navigate(['login']);
    } else {
      this.router.navigate(['compra-en-linea-boletos-sorteo-anahuac']);
    }
  }

  deleteBoleto = (boleto: Boleto): void => {
    this.boletos = this.boletos.filter((b) => b.clave !== boleto.clave);
    this.boletos_generados = this.boletos_generados.filter((b) => b.clave !== boleto.clave);
    this.boletos_recomendados = this.boletos_recomendados.filter((b) => b.clave !== boleto.clave);
    this.boletos_asignados = this.boletos_asignados.filter((b) => b.clave !== boleto.clave);
    this.boletos_bloqueados_local = this.boletos_bloqueados_local.filter((b) => b.clave !== boleto.clave);
    this._local.setBoletosGenerados(this.boletos_generados);
    this._local.setBoletosRecomendados(this.boletos_recomendados);
    this._local.setBoletosAsignados(this.boletos_asignados);
    this._local.setBoletosBloqueados(this.boletos_bloqueados_local);
    if (this.total_compra > 0) {
      this.total_compra = this.total_compra - boleto.costo;
    }

    this.boletos_p_liberar.boletos[0] = boleto;
    this.boletos_p_liberar.fecha = new Date().toISOString();
    if (this.tipos_pago == 1) {
      this.boletos_p_liberar.carrito = this.carrito;
      this.myGetService.postData(this.boletos_p_liberar, 'api/BoletosWeb/LiberaBoletos').subscribe((res) => {
        ////console.log("response");
        ////console.log(res);
      });
      if (this.boletos_bloqueados_local.length == 0) {
        this._local.deleteCarrito();
        this.carrito = '';
      }
    }
  };

  //// ------------ POPUP------------
  /*
  PopupAmigable(popC) {
    var PopAct = document.getElementById('popupamigable');
    if (popC == 0) {
      setTimeout(() => {
        document.addEventListener('mouseout', (event) => {
          if (event.relatedTarget === null && popC == 0) {
            PopAct.hidden = false;
            popC = 2;
            this._session.setPopstate(popC);
          }
        });
      }, 5);
    } else {
      PopAct.hidden = true;
    }
  }
*/

}
