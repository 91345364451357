import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpService } from '../../services/http.service';
import { Pais } from 'src/app/models/Pais';
import { Campus } from 'src/app/models/Campus';
import { BoletoValidar } from 'src/app/models/BoletoValidar';
import { Canales } from 'src/app/models/canales';

@Injectable()
export class DatosCompradorService extends HttpService {
    
    constructor(public _http:HttpClient) {
        super(_http)
    }
 
    // Uses http.get() to load data from a single API endpoint
    getInfoCP(codigo_postal: string) {
        const url = `${this.apiURL}/api/BoletosWeb/Buscar/${codigo_postal}`;
        return this.get(url);
    }

    getInfopais() : Observable<Pais[]>  {
        const url = `${this.apiURL}/api/Catalogo/Pais`;
        return this.get(url).pipe();
    }

    getCanales() {
        return this._http.get(`${this.apiURL}/api/Colaborador/ObtenerCanales`);
    }

    getInfocampus() : Observable<Campus[]>  {
        const url = `${this.apiURL}/api/Catalogo/Campus`;
        return this.get(url).pipe();
    }

    getInfoColab(colab: string) {
        const url = `${this.apiURL}api/BoletosWeb/GetColab/${colab}`;
        return this.get(url);
    }
    gett(api: string) {
        const url = `${this.apiURL}${api}`;
        return this.get(url);
    }
    //VALIDAR BOLETOS
    validarBoletoPasaVoz( boletoValida: BoletoValidar) {
        //console.log(boletoValida);
        return this._http.post( `${this.apiURL}api/PasaLaVoz/validarBoletoPasaLaVoz`, boletoValida); 
        //return this._http.post( `http://localhost:2393/api/PasaLaVoz/validarBoletoPasaLaVoz`, boletoValida);
    }
    
    /*MODIFICAR LEADS*/
    modificaLeads( lead: any) {
        //console.log(this.apiURL);
        //console.log(lead);
        return this._http.post( `${this.apiURL}api/Leads/ModificaLeads`, lead); 
    }

    insertarIdPaypal(data: any){
        return this._http.post( `${this.apiURL}api/Leads/ModificaLeads`,data ); 
    }

    GetDataEgresado(pk_egresado: number){
        return this._http.get( `${this.apiURL}api/Egresado/ObtieneEgresado?num=`+pk_egresado ); 
    }
}