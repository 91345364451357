import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SeguimientoService {

  constructor(private _http: HttpClient) { }

  addSeguimiento(jsonBoletoCom: any) {
    const folios = jsonBoletoCom.map((item: any) => item.folio).join(',');
    return this._http.post(`${environment.UrlBase}api/seguimiento/addSeguimiento`, 
            {     
                "folio": folios,
                "objetoDatos": JSON.stringify(jsonBoletoCom),
                "pagina": sessionStorage.getItem('pagina') ?? '',
                "tipoBoleto": sessionStorage.getItem('tipoBoleto') ?? '',
                "paginaclick": sessionStorage.getItem('paginaclick') ?? '',
                "metodoPago": sessionStorage.getItem('metodoPago') ?? '',
                "ordenDeCompra": sessionStorage.getItem('ordenDeCompra') ?? ''
            }
    );
}

  updateSeguimiento() {
    return this._http.post(`${environment.UrlBase}api/seguimiento/updateSeguimiento`, 
            {     
                  "paginaclick": sessionStorage.getItem('paginaclick')?? '',
                  "metodoPago": sessionStorage.getItem('metodoPago')?? '',
                  "ordenDeCompra": sessionStorage.getItem('ordenDeCompra')?? '',
                  "pk1": sessionStorage.getItem('pk_seguimiento'),
            }
    );
  }

  updateErrorCaracteres(mensaje:string) {
    return this._http.post(`${environment.UrlBase}api/seguimiento/updateErrorCaracteres`, 
            {     
                  "errorCaracteres": mensaje ?? '',
                  "pk1": sessionStorage.getItem('pk_seguimiento'),
            }
    );
  }
}
