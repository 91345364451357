import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as base64 from 'base-64';
import { MyHttpGetService } from '../services/http.services.token';
import { LocalstorageService } from '../services/localstorage.service';
import { SessionstorageService } from '../services/sessionstorage.service';
import { TimerService } from '../timer/timer.service';

@Component({
  selector: 'app-pagar',
  templateUrl: './pagar.component.html',
  styleUrls: ['./pagar.component.scss'],
})
export class PagarComponent implements OnInit {
  public pendiente: number = 0;
  public spin: any;

  constructor(
    private _session: SessionstorageService,
    private _local: LocalstorageService,
    private router: Router,
    private route: ActivatedRoute,
    private myGetService: MyHttpGetService,
    private st: TimerService,
    private meta: Meta
  ) {
    this.meta.updateTag({ name: 'title', content: 'Paga tus boletos del Sorteo Anáhuac en línea' });
    this.meta.updateTag({
      name: 'description',
      content:
        'Ingresa y realiza el pago online de tus boletos del Sorteo Anáhuac 6ª Edición usando tu tarjeta de crédito o débito.',
    });
  }

  ngOnInit() {
    ////console.log('entrando a controlador');

    var cont = this._session.getContCarrito();
    if (parseInt(cont) != 1) {
      this.route.params.subscribe((params) => {
       
        //console.log(params) //log the entire params object
        ////console.log(params['id']) //log the value of id
        this.spin = document.getElementById('spin');
        this.spin.hidden = false;
        this._local.removePKColab();
        ///pago uno o varios boletos
        if (params['id'] == '1') {
          var colab = params['pk'];
          this._session.setColab(colab);
          ////console.log("Es un colaborador " + colab);
          this._session.setTipoAsignado(1);
          this._session.setBoletosCol(2);

          ////console.log("Es false");

          if (params['boleto']) {
            var boleto = params['boleto'];
            ////console.log('Pagar solo un boleto');
            this.myGetService.getPagoColaborador({}, 'api/BoletosWeb/PagoBoleto', boleto).subscribe(
              (result) => {
                ////console.log(result.json());
                if (result.json().length > 0) {
                  sessionStorage.setItem('pagina', 'APP Boleto - btn pagar - btn pagar o pagar inmediatamente despues de rotular');
                  sessionStorage.setItem('tipoBoleto', 'Pago de un solo boleto rotulado en app ');
                  sessionStorage.setItem('texto', 'Gracias por ayudar a la educación');
                  ////console.log('Tengo resultados');
                  this._session.setTiposPago(2);
                  this._local.setBoletosAsignados(result.json());
                  this.router.navigate(['carrito-compra']);
                } else {
                  this.spin.hidden = true;
                  ////console.log('No tengo resultados');
                  this.pendiente = 1;
                }
              },
              (error) => {
                this.spin.hidden = true;
                ////console.log("error de usuario o contraseña");
              }
            );
          } else {
            ////console.log('Pagar muchos boletos');
            this.myGetService.getPagoColaborador({}, 'api/BoletosWeb/PagoSaldo', parseInt(colab)).subscribe(
              (result) => {
                ////console.log(result.json());
                if (result.json().length > 0) {
                  ////console.log('Tengo resultados');
                  sessionStorage.setItem('pagina', 'APP - Estado de cuenta');
                  sessionStorage.setItem('tipoBoleto', 'Pagar todos los rotulados en app y que esten pendientes de pago');
                  sessionStorage.setItem('texto', 'Gracias por ayudar a la educación');
                  this._session.setTiposPago(2);
                  this._local.setBoletosAsignados(result.json());
                  this.router.navigate(['carrito-compra']);
                } else {
                  this.spin.hidden = true;
                  ////console.log('No tengo resultados');
                  this.pendiente = 1;
                }
              },
              (error) => {
                this.spin.hidden = true;
                ////console.log("error de usuario o contraseña");
              }
            );
          }
        } else if (params['id'] == '4') {

          ////console.log("Es un comprador queriendo pagar su boleto ind");
          //liga compartida un boleto
          this._session.setTipoAsignado(2);
          this._session.setBoletosCol(1);
          var boletoInd = params['pk'];
          ////console.log(boletoInd);
          this.myGetService.getPagoCompradorBoletoInd({}, 'api/BoletosWeb/PagoBoletoIndividual/', boletoInd).subscribe(
            (result) => {
              ////console.log(result.json());
              if (result.json().length > 0) {
                ////console.log('Tengo resultados');
                ////console.log(result.json());
                ////console.log(result.json()[0].correo_comprador);
                this._session.setTiposPago(3);
                this._session.setCorreoComp(result.json()[0].correo_comprador);
                this._local.setBoletosAsignados(result.json());
                sessionStorage.setItem('pagina', 'APP Boleto - btn pagar - btn compartir liga');
                sessionStorage.setItem('tipoBoleto', 'Liga compartida a comprador desde APP');
                sessionStorage.setItem('texto', 'Gracias por ayudar a la educación');
                this.router.navigate(['carrito-compra']);
              } else {
                this.spin.hidden = true;
                ////console.log('No tengo resultados');
                this.pendiente = 1;
              }
            },
            (error) => {
              this.spin.hidden = true;
              ////console.log("error de usuario o contraseña");
              //this.router.navigate(["compra-en-linea-boletos-sorteo-anahuac"]);
            }
          );
        } else if (params['id'] == '9') {
          ////console.log("Es un comprador queriendo pagar muchos boletos");
         
          this._session.setTipoAsignado(2);
          this._session.setTiposPago(2);
          this._session.setBoletosCol(1);
          var grupoBoletos = params['pk'];
          this.myGetService
            .getPagoCompradorVariosBoletos({}, 'api/BoletosWeb/PagoVariosBoletos/', grupoBoletos)
            .subscribe(
              (result) => {
                ////console.log(result.json());
                if (result.json().length > 0) {
                  // for each de boletos en el servicio enviar al carrito
                  this._local.setBoletosAsignados(result.json());
                  sessionStorage.setItem('pagina', 'APP Boletos - Vender varios');
                  sessionStorage.setItem('tipoBoleto', 'Se selecciona mas de una boleto en app y se venden todos juntos');
                  sessionStorage.setItem('texto', 'Gracias por ayudar a la educación');
                  this.router.navigate(['carrito-compra']);
                } else {
                  this.spin.hidden = true;
                  ////console.log('No tengo resultados');
                  this.pendiente = 1;
                }
              },
              (error) => {
                this.spin.hidden = true;
                ////console.log("error de usuario o contraseña");
                //this.router.navigate(["compra-en-linea-boletos-sorteo-anahuac"]);
              }
            );
        } else if (params['id'] == '7') {
          ////console.log("Boleto de la suerte")

          this._session.setTiposPago(1);
          this._session.setTipoAsignado(2);
          this._session.setBoletosCol(1);
          var boletoSuerte = params['pk'];
          this._session.setBoletoSuerteComprador(boletoSuerte);
          var date = new Date();
          this._local.setFechaInicio(date);
          this._local.setFechaFin(date);
          this.myGetService.getInfoCompra('api/BoletosWeb/PagoBoletoSuerte', boletoSuerte).subscribe(
            (result) => {
              ////console.log(result.json());
              if (result.json().length > 0) {
                // for each de boletos en el servicio enviar al carrito
                this._local.setBoletosRecomendados(result.json());
                sessionStorage.setItem('pagina', 'Boletos de la suerte');
                sessionStorage.setItem('tipoBoleto', 'Se rotula desde boleto de la suerte');
                sessionStorage.setItem('texto', 'Gracias por participar en nuestro Sorteo');
                this.router.navigate(['carrito-compra']);
              } else {
                this.spin.hidden = true;
                ////console.log('No tengo resultados');
                this.pendiente = 3;
              }
            },
            (error) => {
              this.spin.hidden = true;
              ////console.log("error de usuario o contraseña");
              //this.router.navigate(["compra-en-linea-boletos-sorteo-anahuac"]);
            }
          );
        } else if (params['id'] == '11') {
          ////console.log("Boleto de la suerte");
          this._session.setTiposPago(1);
          this._session.setTipoAsignado(2);
          this._session.setBoletosCol(1);
          var boletoPerpetuo = params['pk'];
          this._session.setBoletoSuerteComprador(boletoPerpetuo);
          var date = new Date();
          this._local.setFechaInicio(date);
          this._local.setFechaFin(date);
          //debugger;
          this.myGetService.getInfoCompra('api/BoletosWeb/PagoBoletoperpetuo', boletoPerpetuo).subscribe(
            (result) => {
              console.log(result.json());
              if (result.json().length > 0) {
                // for each de boletos en el servicio enviar al carrito
                sessionStorage.setItem('pagina', 'Boleto perpetuo');
                sessionStorage.setItem('tipoBoleto', 'Se rotula desde boleto perpetuo');
                sessionStorage.setItem('texto', 'Gracias por participar en nuestro Sorteo');
                this._local.setBoletosRecomendados(result.json());
                this.router.navigate(['carrito-compra']);
              } else {
                this.spin.hidden = true;
                ////console.log('No tengo resultados');
                this.pendiente = 3;
              }
            },
            (error) => {
              this.spin.hidden = true;
              ////console.log("error de usuario o contraseña");
              //this.router.navigate(["compra-en-linea-boletos-sorteo-anahuac"]);
            }
          );
        }
        else if (params['id'] == '5') {
          //Desde sorteo mx
          ////console.log("Compra desde sorteoMX");
          this._session.setBoletosCol(0);
          this.route.queryParams.subscribe((params) => {
            if (params.pb) {
              this._local.setPaybackref(1);
            }
          });

          var boletoSorteoMX = JSON.parse(base64.decode(params['pk']));

          this.myGetService.postData(boletoSorteoMX, 'api/BoletosWeb/ValidaBoletoPublico').subscribe(
            (res) => {
              ////console.log("El boleto estaba disponible")
              ////console.log(res);
              var objeto_boletoSorteoMX: Array<Object> = [];
              objeto_boletoSorteoMX.push(boletoSorteoMX);
              ////console.log(JSON.parse(base64.decode(params['pk'])))

              var tipos_pago: number = 1;

              var date = new Date();
              this._local.setFechaInicio(date);
              this._local.setFechaFin(date);

              this._local.setBoletosGenerados(objeto_boletoSorteoMX);

              this.st.startTimer();
              this._session.setTiposPago(tipos_pago);
              sessionStorage.setItem('pagina', 'pagina webb');
              sessionStorage.setItem('tipoBoleto', 'Se trae desde maquinita de la suerte sorteo mx');
              sessionStorage.setItem('texto', 'Gracias por participar en nuestro Sorteo');
              this.router.navigate(['carrito-compra']);
            },
            (err) => {
              this.spin.hidden = true;
              ////console.log("El boleto no estaba disponible");
              this.pendiente = 2;
            }
          );
        } else
        if (params['id'] == '20') {
          //Desde sorteo mx
         // debugger;
          //console.log("Boletos egresado");
         // console.log(params.pk + " -- "+ params['id'] );

          const pk_egresado = Number(params['pk']);

          const pk_egr = { pk_egresado: pk_egresado }; 
          const pk_egrJSON = JSON.stringify(pk_egr);
          //console.log(pk_egrJSON );

          var date = new Date();
          this._local.setFechaInicio(date);
          this._local.setFechaFin(date);

          // Guardar un dato en sessionStorage
          sessionStorage.setItem('pk_egresado',  params.pk );

  

          this.myGetService.getFoliosBoletos( pk_egresado).subscribe(
            (res) => {
              ////console.log("El boleto estaba disponible")
              //console.log(res.json());
              var objeto_boletoSorteoMX: Array<Object> = [];
              objeto_boletoSorteoMX.push(res.json());
              //console.log(objeto_boletoSorteoMX);

              this._local.setBoletosGenerados(res.json());

              this.st.startTimer();
              this._session.setTiposPago(1);
              sessionStorage.setItem('pagina', 'pagina web camapaña egresados');
              sessionStorage.setItem('tipoBoleto', 'Se trae desde campaña de egresados');
              sessionStorage.setItem('texto', 'Gracias por participar en nuestro Sorteo');
              this.router.navigate(['carrito-compra']);
            },
            (err) => {
              this.spin.hidden = true;
              ////console.log("El boleto no estaba disponible");
              this.pendiente = 2;
            }
          );
        } 
        
        else {
          ////console.log("Es un comprador queriendo pagar su boleto");
          this._session.setTipoAsignado(2);
          var email = params['pk'].split('!').join('.');
          this._session.setCorreoComp(email);
          this._session.setBoletosCol(1);
          ////console.log(email);
          this.myGetService.getPagoComprador({}, 'api/BoletosWeb/PagoBoletos/', email).subscribe(
            (result) => {
              ////console.log(result.json());
              if (result.json().length > 0) {
                ////console.log('Tengo resultados');
                this._session.setTiposPago(3);
                this._local.setBoletosAsignados(result.json());
                sessionStorage.setItem('pagina', '/pagar-boletos-en-linea');
                sessionStorage.setItem('tipoBoleto', 'Comprador tratando de pagar sus boletos');
                sessionStorage.setItem('texto', 'Gracias por participar en nuestro Sorteo');
                this.router.navigate(['carrito-compra']);
              } else {
                this.spin.hidden = true;
                ////console.log('No tengo resultados');
                this.pendiente = 1;
              }
            },
            (error) => {
              this.spin.hidden = true;
              ////console.log("error de usuario o contraseña");
            }
          );
        }
      });
    } else {
      ////console.log("regresaste a home");
      this.router.navigate(['compra-en-linea-boletos-sorteo-anahuac']);
    }
  }
}
