import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { Boleto } from '../models/Boleto';
import { Comprador } from '../models/Comprador';
import { SeleccionMultipleBoleto } from '../models/SeleccionMultipleBoleto';
import { LocalstorageService } from '../services/localstorage.service';
import { SessionstorageService } from '../services/sessionstorage.service';
import { TimerService } from '../timer/timer.service';
import { HomeService } from './services/home.service';
import * as $ from 'jquery';
import { Leads } from '../models/Leads';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public start: boolean = false;
  private boletos_generados: Array<Object> = [];
  private miSeleccion: SeleccionMultipleBoleto = { numero: '', mecanismo: '', pkcolab: '' };
  private info_boleto: Array<Object> = [];
  private boleto_random: Object = {};
  private precio_boleto: number = 0;
  public boleto: Boleto;
  private tipos_pago: number = 1;
  public boleto_disponible: number = 1;
  public boletos: Array<Object> = [];
  public boleto_: Array<Object> = [];
  public boletosLuck: Array<Boleto> = [];
  public boletosLuckfil: Array<Boleto> = [];
  // public enabled: boolean = true;
  inputDisabled_1: boolean = true;
  inputDisabled_2: boolean = true;
  inputDisabled_3: boolean = true;
  inputDisabled_4: boolean = true;
  botonDisabled: boolean = true;
  option_1: string = '';
  option_2: string = '';
  option_3: string = '';
  option_4: string = '';
  public metodo: boolean = false;
  public mensajeError: string = '';
  public boleto_check: boolean = false;
  checkbox: string = '';
  public subsasdas: Subscription;
  Error: boolean = false;
  ErrorSuerte: boolean = false;
  public popC: any;
  public popstate: any;
  public checkC: number = 0;
  public box: any;
  formDatosComprador: FormGroup;
  PopBoleto: boolean = false;
  contlck: number = 3;
  public Info: Comprador = {
    pk1: -1,
    nombre: '',
    apellidos: '',
    telefono_fijo: '',
    telefono_movil: '',
    correo_electronico: '',
    correo_alternativo: '',
    correo_tarjetahabiente: '',
    calle: '',
    numero: '',
    pais: '',
    codigo_postal: '',
    colonia: '',
    estado: '',
    municipio: '',
    campus: '',
    egresado: '',
    apellido_materno: '',
    apellido_paterno: '',
    pkcanal: null,
    especifica_canal: ""
  };

  //nuevo
  flagGirar = false;
  flag_buy = false;
  flag_buy_loader = false;
  flag_buy_btn = false;

  /* */
  public popColaborador: boolean = false;
  private pkcolab: string;

  /*counter*/

  //time magno
  _second = 1000;
  _minute = this._second * 60;
  _hour = this._minute * 60;
  _day = this._hour * 24;
  end: any;
  now: any;
  day: any;
  hours: any;
  minutes: any;
  seconds: any;
  source = timer(0, 1000);
  /* VARIABLES ZODIACO*/
  showZod = false;
  info_zod: any = [];
  formattedNumbers: string[] = [];
  boletosZod: any = [];
  showDefault = false;
  claveCampania = "";
  showPkCompania = "";
  constructor(
    private _session: SessionstorageService,
    private _local: LocalstorageService,
    private _HomeService: HomeService,
    private router: Router,
    private st: TimerService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private meta: Meta
  ) {

    // var lsAsignados = this._local.getBoletosAsignados();
    var lsGenerados = this._local.getBoletosGenerados();
    this.boleto_ = lsGenerados == null ? [] : JSON.parse(lsGenerados);
    this.popC = Number.parseInt(this._session.getPopstate());

    // validaciones

    this.formDatosComprador = this.fb.group({
      nombre: ['', Validators.compose([Validators.required, Validators.pattern(/[a-zA-Z������������\s]/)])],
      apellidos: ['', Validators.compose([Validators.required, Validators.pattern(/[a-zA-Z������������\s]/)])],
      celular: ['', Validators.compose([Validators.required, Validators.pattern(/^\d*$/)])],
      correo: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            /^[!#$%&'*+-=?^_`{|}~a-z0-9-]+(\.[!#$%&'*+-=?^_`{|}~a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/
          ),
        ]),
      ],
    });

    this.meta.updateTag({ name: 'title', content: 'Compra boletos en línea | Sorteo Anáhuac' });
    this.meta.updateTag({
      name: 'description',
      content:
        'Compra aquí tus boletos online para del Sorteo Anáhuac 9ª Edición e impulsa la formación de líderes de acción positiva, ¡gracias por participar!',
    });
  }

  mostrar = this.boleto_.length;



  showDateSorteoMagno() {
    let distance = this.end - this.now;

    if (this.end - this.now <= 0) {
      distance = 0;
    }

    this.day = Math.floor(distance / this._day);
    if (this.day < 10) this.day = "0" + this.day;


    this.hours = Math.floor((distance % this._day) / this._hour);
    if (this.hours < 10) this.hours = "0" + this.hours;

    this.minutes = Math.floor((distance % this._hour) / this._minute);
    if (this.minutes < 10) this.minutes = "0" + this.minutes;

    this.seconds = Math.floor((distance % this._minute) / this._second);
    if (this.seconds < 10) this.seconds = "0" + this.seconds;

  }

  ngOnInit() {


    var tipo_asignado = parseInt(this._session.getTipoAsignado());
    ////console.log(this.popC);
    this._session.setContCarrito(0);
    this.PopActive(); //Consulta de estado de PopUp
    if (this._local.getPKColab != null) {
      this._local.removePKColab();
    }
    this.ValidaLigaUnica();
    this.ChecaCampania();

    //time magno
    this.source.subscribe(t => {
      this.now = new Date();
      this.end = new Date('11/23/' + (this.now.getFullYear()) + ' 17:00');
      //this.showDateSorteoMagno();
    });

    //llamar api zodiaco 
    this._HomeService.getToken().subscribe(a => {
      //console.log(a);
    }, err => {
      //console.log(err);
      //console.log(err.error.text);
      if (err.status == 200) {
        this._HomeService.getActiveZod(err.error.text).subscribe(a => {
          console.log(a);
          this.info_zod = a;
          this.showZod = true;
          if (this.info_zod.length > 0) {
            this.claveCampania = this.info_zod[0].CLAVE;

            switch (this.info_zod[0].CLAVE) {
              case "001"://campaña zodiaco
                this.showPkCompania = this.info_zod[0].CLAVE;
                const numbers = this.info_zod[0].NUMEROS.split(',');
                this.formattedNumbers = numbers;
                break;
              case "002"://campaña cuenta regresiva
                this.showPkCompania = this.info_zod[0].CLAVE;
                break;
              case "003"://campaña default
                this.showZod = false;
                this.showDefault = true;
                break;
              default:
                break;
            }

          } else {
            this.showZod = false;
          }

        },
          err => {
            ////console.log(err);

            if (err.status === 404) {
              location.reload();
              this.showZod = false;
            }
          });
      }
    });

  }
  //Animacion de maquinita + Funcion para generar boleto random
  BtnStart() {
    this.start = true;
    // (document.getElementById('maquinita') as HTMLImageElement).src =
    //   '../../assets/img/NuevoDiseño/Recursos/Home/BtnFortunaDown.png';
    // setTimeout(() => {
    //   (document.getElementById('maquinita') as HTMLImageElement).src =
    //     '../../assets/img/NuevoDiseño/Recursos/Home/BtnFortunaUpp.png';
    // }, 500);

    this.getBoletoRandom();
    //this.Msgvisible();
  }
  // msj de error si no encuentra boletos disponibles despues de 2.5 seg
  Msgvisible() {
    if (this.boleto == undefined && this.start == true) {
      var MsgBoletos = document.getElementById('Msg');
      setTimeout(() => {
        if (this.boleto == undefined && this.start == true) {
          MsgBoletos.hidden = false;
        }
      }, 2500);
    }
  }
  MsgInvisible() {
    var MsgBoletos = document.getElementById('Msg');
    MsgBoletos.hidden = true;
  }

  OnDestroy() {
    this.subsasdas.unsubscribe();
  }

  // Habilitar y desabilitar entrada con radio y limpiar campos
  inputDisabled(opcion) {
    this.box = document.getElementById('ListBol');
    if (opcion == 1) {
      this.inputDisabled_1 = false;
      this.inputDisabled_2 = true;
      this.inputDisabled_3 = true;
      this.inputDisabled_4 = true;
      this.boletos = [];
      this.option_2 = '';
      this.option_3 = '';
      this.option_4 = '';
      this.Error = false;
      this.box.hidden = true;
    } else if (opcion == 2) {
      this.inputDisabled_1 = true;
      this.inputDisabled_2 = false;
      this.inputDisabled_3 = true;
      this.inputDisabled_4 = true;
      this.boletos = [];
      this.option_1 = '';
      this.option_3 = '';
      this.option_4 = '';
      this.Error = false;
      this.box.hidden = true;
    } else if (opcion == 3) {
      this.inputDisabled_1 = true;
      this.inputDisabled_2 = true;
      this.inputDisabled_3 = false;
      this.inputDisabled_4 = true;
      this.boletos = [];
      this.option_1 = '';
      this.option_2 = '';
      this.option_4 = '';
      this.Error = false;
      this.box.hidden = true;
    } else if (opcion == 4) {
      this.inputDisabled_1 = true;
      this.inputDisabled_2 = true;
      this.inputDisabled_3 = true;
      this.inputDisabled_4 = false;
      this.boletos = [];
      this.boletosLuck = [];
      this.option_1 = '';
      this.option_2 = '';
      this.option_3 = '';
      this.Error = false;
      this.box.hidden = true;
    }
  }

  // Método pone en ceros maquinita
  Reset_maquinita(value) {
    ////console.log(value);
    this.start = false;
    this.info_boleto = [];
    for (var i = 0; i < 6; i++) {
      this.boleto_random = {
        posicion: i,
        valor: '0',
        clase: 'num_cero',
      };
      this.info_boleto.push(this.boleto_random);
    }
  }

  //obtenerNumeroZodiaco
  getBoletosZod() {
    //this.botonDisabled = false;
    this.Reset_maquinita(null); //Llama método de reset
    this.metodo = false;
    this.mensajeError = '';
    this.Error = false;
    this.box = document.getElementById('ListBol2');

    /* llamar api zodiaco */
    this._HomeService.getToken().subscribe(a => {
      // console.log(a);
    }, err => {
      //console.log(err);
      //console.log(err.error.text);
      if (err.status == 200) {
        let n1 = "0", n2 = "0";
        if (this.claveCampania == '001') {
          n1 = this.formattedNumbers[0];
          n2 = this.formattedNumbers[1];
        } else {
          n1 = Math.floor(Math.random() * 100) + '';
          n2 = Math.floor(Math.random() * 100) + '';
        }
        this._HomeService.getBoletosZod(n1, n2, err.error.text, this.claveCampania).subscribe(data => {
          //console.log(data);
          this.boletos = data;

        },
          err => {
            console.log(err);

            if (err.status === 404) {
              // location.reload();
            }
          });
      }
    });


    // this._HomeService.postBoletoConditioned(this.miSeleccion).subscribe((data) => {
    //   this.boletos = data;
    //   this.box.hidden = false;
    // });

  }

  // Obtener valores de los boletos según busqueda para boleto de la suerte
  getLuckyBoleto(opcion1, opcion2, opcion3, opcion4) {
    //this.botonDisabled = false;
    this.Reset_maquinita(null); //Llama método de reset
    this.metodo = false;
    this.mensajeError = '';
    this.Error = false;
    this.box = document.getElementById('ListBol');

    if (opcion1 != '') {
      this.miSeleccion = { numero: opcion1, mecanismo: 'option_termination', pkcolab: this.pkcolab };
      this._HomeService.postBoletoConditioned(this.miSeleccion).subscribe((data) => {
        //console.log(data);
        if (data.length == 0) {
          this.boletos = data;
          data = '';
          this.mensajeError = 'Boleto(s) no disponible(s)';
          this.Error = true;
          this.box.hidden = false;
        } else {
          this.boletos = data;
          this.box.hidden = false;
        }
      });
      ////console.log("Seleccione opcion 1")
    } else if (opcion2 != '') {

      this.miSeleccion = { numero: opcion2, mecanismo: 'option_contains', pkcolab: this.pkcolab };
      this.subsasdas = this._HomeService.postBoletoConditioned(this.miSeleccion).subscribe(
        // this._HomeService.postBoletoConditioned(this.miSeleccion).subscribe(
        (data) => {
          //console.log(data);

          if (data.length == 0) {
            this.boletos = data;
            data = '';
            this.mensajeError = 'Boleto(s) no disponible(s)';
            this.Error = true;
            this.box.hidden = false;
          } else {
            this.boletos = data;
            ////console.log(JSON.stringify(data))
            this.box.hidden = false;
          }
        }
      );
      ////console.log("Seleccione opcion 2")
    } else if (opcion3 != '') {

      this.miSeleccion = { numero: opcion3, mecanismo: 'option_starts', pkcolab: this.pkcolab };
      this._HomeService.postBoletoConditioned(this.miSeleccion).subscribe((data) => {
        // console.log(data);

        if (data.length == 0) {
          this.boletos = data;
          data = '';
          this.mensajeError = 'Boleto(s) no disponible(s)';
          this.Error = true;
          this.box.hidden = false;
        } else {
          this.boletos = data;
          this.Error = false;
          this.box.hidden = false;
        }
        // ////console.log(JSON.stringify(data))
      });
      ////console.log("Seleccione opcion 3")
    } else if (opcion4 != '') {
      this.miSeleccion = { numero: opcion4, mecanismo: 'option_full_number', pkcolab: this.pkcolab };
      this._HomeService.postBoletoConditioned(this.miSeleccion).subscribe((data) => {
        ////console.log(data.length)
        if (data.length == 0) {
          // console.log(data);

          this.boletos = data;
          data = '';
          this.mensajeError = 'Boleto(s) no disponible(s)';
          this.Error = false;
          this.ErrorSuerte = true;
          this.box.hidden = false;
        } else {
          this.boletos = data;
          ////console.log(this.boletos);
          this.Error = false;
          this.ErrorSuerte = false;
          this.box.hidden = false;
        }
      });
      ////console.log("Seleccione opcion 4")
    }

  }
  /* NUEVO */
  getBoletoRandom() {
    //guardar tipo boleto
    sessionStorage.setItem('tipoBoleto', 'Maquina de la suerte');

    this.botonDisabled = false;
    //Se giro una vez la maquina
    this.metodo = true;
    this.box = document.getElementById('ListBol');
    this.box.hidden = true;
    // Resetea caja busq por tipo
    this.boletos = [];
    this.boletosLuck = [];
    this.mensajeError = '';
    this.Error = false;
    // false = Habilitado | true = Desabilitado
    this.inputDisabled_1 = true;
    this.inputDisabled_2 = true;
    this.inputDisabled_3 = true;
    this.inputDisabled_4 = true;
    this.option_1 = '';
    this.option_2 = '';
    this.option_3 = '';
    this.option_4 = '';

    this.boleto_disponible = 1;

    /*nuevo */
    this.flag_buy = true;
    this.flag_buy_loader = true;
    this.flag_buy_btn = false;
    ////console.log("Entra getBoletoRandom");
    var pkc = { pkcolab: this.pkcolab };
    console.log("pk_colaboradro"+this.pkcolab);
    this._HomeService.getBoletoTablero(pkc).subscribe(
      (data) => {
        if (data.length == 0) {
          this.Msgvisible();
          return 0;
        } else {
          // this.MsgInvisible();
        }
        ////console.log("ONE --", this.boleto);
        if (this.flagGirar) this.deleteNumbers(this.boleto.folio.toString().split(""));

        this.boleto = data[0];
        ////console.log("TWO", this.boleto);
        this.precio_boleto = data[0].costo;

        this.info_boleto = [];
        ////console.log("Aquiiiiiii", this.info_boleto);


        ////console.log("THREE", data[0].folio);


        ////console.log(this.boleto.folio);
        this.girar();

        ////console.log(this.info_boleto);
        if (data[0].folio == null || data[0].folio == '') {
          this.Msgvisible();
        }
      },
      (err) => {
        console.error(err);
        this.Msgvisible();
      },
      () =>
      ////console.log('done loading boletos')
      { }
    );
  }

  deleteNumbers(numerosBoletos) {
    for (let i = 0; i < 6; i++) {
      let number = numerosBoletos[i];
      for (let index = 0; index <= parseInt(number); index++) {
        $("#list-n" + i + " li:last").remove();
      }
      $("#list-n" + i).removeClass("animation-list" + i);
    }
  }

  girar() {
    let boleto = this.boleto.folio.toString();
    let numerosBoletos = boleto.split("");

    setTimeout(() => {

      for (let i = 0; i < 6; i++) {
        let number = numerosBoletos[i];
        let lis = "";
        let sizePx = 40;
        for (let index = 0; index <= parseInt(number); index++) {
          lis += "<li>" + index + "</li>";
        }

        $("#list-n" + i).append(lis);

        sizePx *= parseInt(number);
        sizePx = -760 - sizePx - 40;
        ////console.log(sizePx);
        document.documentElement.style
          .setProperty('--my-start-px' + i, sizePx + "px");
        $("#list-n" + i).addClass("animation-list" + i);
      }
      setTimeout(() => {
        this.flag_buy_loader = false;
        this.flag_buy_btn = true;
        this.flag_buy = false;
      }, 5500);
    }, 100);


    this.flagGirar = true;


  }
  efectuarPago2() {

    //this.boleto_ = null;
    this.popstate = this._session.getDBEstadoPopUp();
    // if (this.popC != 0 && this.popC != 2 && this.popstate == 'true') {
    //   this._session.setPopstate(0);
    // }

    var ls = this._local.getBoletosGenerados();
    //console.log(ls);
    this.boletos_generados = ls == null ? [] : JSON.parse(ls);
    //this.box.hidden = true;
    this._session.setBoletosCol(0);

    // this._local.getBoletosGenerados() = ;

    var date = new Date();
    this._local.setFechaInicio(date);
    this._local.setFechaFin(date);
    //console.log(this.boletos.filter((boleto) => boleto['marcado'] === true));
    var info_bol = this.boletos.filter((boleto) => boleto['marcado'] === true);
    ////console.log("Infor de boletos", info_bol)
    info_bol.forEach((element) => {
      // delete element['pk_boleto'];
      // delete element['pk_colaborador'];
      // delete element['clave_colaborador'];
      // delete element['marcado'];
      // console.log("ciclando boleto: ", element);
      //console.log("Conteo existe boleto: ", this.boletos_generados.filter(bol => bol["folio"] === element["folio"]).length);
      if (this.boletos_generados.filter((bol) => bol['folio'] === element['folio']).length == 0) {
        // console.log("Agregando boleto:", element);
        this.boletos_generados.push(element);
      }
    });

    this._local.setBoletosGenerados(this.boletos_generados);

    this.st.startTimer();
    this._session.setTiposPago(this.tipos_pago);
    sessionStorage.setItem('pagina', 'Home');
    sessionStorage.setItem('texto', 'Gracias por participar en nuestro Sorteo');
    sessionStorage.setItem('tipoBoleto', 'Modal folios zodiaco ');
    this.router.navigate(['carrito-compra']);


  }
  /*FIN NUEVO */

  efectuarPago() {

    this.boleto_ = null;
    this.popstate = this._session.getDBEstadoPopUp();
    if (this.popC != 0 && this.popC != 2 && this.popstate == 'true') {
      this._session.setPopstate(0);
    }

    var ls = this._local.getBoletosGenerados();
    this.boletos_generados = ls == null ? [] : JSON.parse(ls);
    this._session.setBoletosCol(0);
    // Si metodo=true y btnElegir es igual al parametro que mando el boton Agregar a Carrito, inserta add-cart!
    if (this.metodo) {
      var date = new Date();
      this._local.setFechaInicio(date);
      this._local.setFechaFin(date);

      this.boletos_generados.push(this.boleto);

      this._local.setBoletosGenerados(this.boletos_generados);

      this.st.startTimer();
      this._session.setTiposPago(this.tipos_pago);
      sessionStorage.setItem('pagina', 'Home');
      sessionStorage.setItem('tipoBoleto', 'Maquinita Suerte');
      sessionStorage.setItem('texto', 'Gracias por participar en nuestro Sorteo');
      this.router.navigate(['carrito-compra']);
    } else if (!this.metodo) {
      // this._local.getBoletosGenerados() = ;

      var date = new Date();
      this._local.setFechaInicio(date);
      this._local.setFechaFin(date);
      var info_bol = this.boletos.filter((boleto) => boleto['marcado'] === true);
      ////console.log("Infor de boletos", info_bol)
      info_bol.forEach((element) => {
        ////console.log("ciclando boleto: ", element);
        ////console.log("Conteo existe boleto: ", this.boletos_generados.filter(bol => bol["folio"] === element["folio"]).length);
        if (this.boletos_generados.filter((bol) => bol['folio'] === element['folio']).length == 0) {
          ////console.log("Agregando boleto:", element);
          this.boletos_generados.push(element);
        }
      });
      this._local.setBoletosGenerados(this.boletos_generados);

      this.st.startTimer();
      this._session.setTiposPago(this.tipos_pago);
      sessionStorage.setItem('pagina', 'Home');
      sessionStorage.setItem('tipoBoleto', 'Modal folios ');
      sessionStorage.setItem('texto', 'Gracias por participar en nuestro Sorteo');
      this.router.navigate(['carrito-compra']);
    }
    this.metodo = false;
  }
  //Metodo para habilitar btn en caso de buscar boleto
  btnable(check) {
    if (check) {
      this.checkC++;
    } else {
      this.checkC--;
    }
    if (this.checkC > 0) {
      this.botonDisabled = false;
    } else {
      this.botonDisabled = true;
    }
  }

  // Funcion para buscar boletos para registrar como perpetuos en DB
  SetLuckyBoleto(boletol) {
    if (boletol != '') {
      this.miSeleccion = { numero: boletol, mecanismo: 'option_termination', pkcolab: this.pkcolab };
      this._HomeService.getLuckyBol(this.miSeleccion).subscribe((data) => {
        ////console.log("No boletos");
        ////console.log(data.length)
        ////console.log("Boletos")
        ////console.log(data);
        if (data.length == 0) {
          //Si no hay boletos disponibles
          this.boletosLuck = data;
          data = '';
          this.mensajeError = 'Desafortunadamente el boleto(s) ya está reservado(s). Intenta con otro.';
          this.PopBoleto = false;
          this.Error = true;
          this.ErrorSuerte = false;
        } else if (data[0].clave == 0) {
          //Si el boleto no existe
          this.boletosLuck = data;
          data = '';
          this.mensajeError = 'Desafortunadamente el boleto no existe. Intenta con otro.';
          this.PopBoleto = false;
          this.Error = true;
          this.ErrorSuerte = false;
        } else if (data[0].clave == 1 && data[0].folio == 'Reservado') {
          //Si el boleto está reservado
          this.boletosLuck = data;
          data = '';
          this.mensajeError = 'Desafortunadamente el boleto(s) ya está reservado(s). Intenta con otro.';
          this.PopBoleto = false;
          this.Error = true;
          this.ErrorSuerte = false;
        } // Si encuentra boletos para registrar
        else {
          this.boletosLuck = data;
          this.boletosLuckfil = this.boletosLuck.filter((item, index) => index < 3);
          this.Error = false;
          this.PopBoleto = true;
          this.contlck = 3;
          ////console.log("Correcto");
        }
      });
    } else {
      this.PopBoleto = false;
    }
  }
  // Metodo para rotacion de boletos de la suerte
  GetLuckyPermBoleto() {
    if (this.contlck <= this.boletosLuck.length) {
      this.contlck = this.contlck + 3;
      var ind = this.contlck - 3;
      this.boletosLuckfil = [];
      var i = 0;
      for (ind; ind < this.contlck; ind++) {
        if (this.boletosLuck.length <= ind) {
          //Si solo queda un boleto, lo centra
          // document.getElementById("Mas").hidden = true;
          var lck = this.boletosLuckfil[0];
          this.boleto = undefined;
          this.boleto.estado_boleto = 'Null';
          this.boletosLuckfil = [];
          this.boletosLuckfil.push(this.boleto);
          this.boletosLuckfil.push(lck);
          this.boletosLuckfil.push(this.boleto);
          return 0;
        }
        this.boletosLuckfil[i] = this.boletosLuck[ind];
        i++;
      }

      //this.boletosLuckfil = this.boletosLuck.filter((item, index) => index>=ind  && index < this.contlck);
      //  if(this.contlck>=this.boletosLuck.length){
      // 	document.getElementById("Mas").hidden = true;
      //  }
      // }else{
      // 	document.getElementById("Mas").hidden = true;
      // }
    }
  }
  //Metodo para cerrar pop Boletos de la Suerte
  CloseLuckyBoleto() {
    this.contlck = 0;
    this.boletosLuckfil = [];
    this.PopBoleto = false;
  }

  //Metodo para buscar boleto de la suerte en DB
  Registrobol(boleto) {
    this.SetLuckyBoleto(boleto);
    //this.offbtnMas();
  }

  //Funcion para registrar boletos como perpetuos
  RegistroDatos() {
    if (this.formDatosComprador.valid) {
      var info_bol = this.boletosLuckfil.filter((boleto) => boleto['marcado'] === true);
      info_bol.forEach((element) => {
        ////console.log("ciclando boleto: ", element);
        ////console.log("Conteo existe boleto: ", this.boletosLuckfil.filter(bol => bol["folio"] === element["folio"]).length);
        if (this.boletosLuckfil.filter((bol) => bol['folio'] === element['folio']).length == 0) {
          ////console.log("Agregando boleto:", element);
          this.boletosLuckfil.push(element);
        }
      });
      for (var i = 0; i < info_bol.length; i++) {
        info_bol[i].comprador = this.Info;
      }
      ////console.log(info_bol);
      if (info_bol.length > 0) {
        this._HomeService.setLuckyBol(info_bol).subscribe((data) => {
          //var patt1 = /\d/g;
          var result = data.match(/(\d+)/);
          if (data == 'Exitoso') {
            //this.boletos = data;
            //data = "";
            alert('Registro completo');
          } else if (result != null) {
            alert(
              'Ocurrio un error al registrar el boleto: ' +
              result[0] +
              '\nSe ha alcanzado el limite de boletos reservados.'
            );
          } else if (data == 'ErrorComprador') {
            alert('Ocurrio un error al registrar sus datos');
          }
          this.CloseLuckyBoleto();
          document.getElementById('ListBol').hidden = true;
        });
      } else {
        alert('Selecciona al menos un boleto');
      }
    } else {
      alert('Favor de llenar el formulario correctamente');
    }
  }

  //Funcion para controlar PopUp desde DB
  PopActive() {
    this._HomeService.getEstadoPopUp().subscribe((data) => {
      //EstadoPopUp
      this._session.setDBEstadoPopUp(data);
    });
  }

  CerrarPopColaborador() {
    this.popColaborador = false;
  }

  private ValidaLigaUnica() {
    this.route.params.subscribe((params) => {
      ////console.log(params); //log the entire params object
      //Si es liga de colaborador
      if (params['liga_colaborador'] != null) {
        var datosliga = params['liga_colaborador'];
        // RegExp para separar las palabras delimitadas por _
        var ligaConmedio = datosliga.match(
          /([a-zA-Z0-9]+)_([a-zA-Z0-9]+)_([a-zA-Z0-9]+)_([a-zA-Z0-9]+)_([a-zA-Z0-9]+)_([a-zA-Z0-9]+)_([a-zA-Z0-9]+)/
        );
        ////console.log(ligaConmedio);
        if (ligaConmedio == null) {
          var liga = datosliga.match(
            /([a-zA-Z0-9]+)_([a-zA-Z0-9]+)_([a-zA-Z0-9]+)_([a-zA-Z0-9]+)_([a-zA-Z0-9]+)_([a-zA-Z0-9]+)/
          );
          ////console.log(liga);
          this.pkcolab = liga[5];
        } else {
          this.pkcolab = ligaConmedio[5];
        }
        this._local.setPKColab(this.pkcolab);
        this._local.setMecanismoColabExt('PasaLaVoz');
        this.popColaborador = true;
      } else {
        this.popColaborador = false;
      }
    });
    this.route.queryParams.subscribe((params) => {
      if (params.pb) {
        this._local.setPaybackref(1);
      }
    });
  }

  /** CAMBIOS LUIS AGUILAR WECODE */
  //Funcion para registrar datos de redirecciones por campaña
  private ChecaCampania() {
    this.route.queryParams.subscribe((params) => {
      if (params['utm_campaign']) {
        let registeredCampaingnData = this._local.getCampaingnData();
        if (registeredCampaingnData && registeredCampaingnData['correo']) {

        } else {
          this._local.setCampaingnData(params);
        }
      }
    });
  }
  /** END CAMBIOS LUIS AGUILAR WECODE */
}
