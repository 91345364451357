import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Boleto } from '../models/Boleto';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalstorageService } from '../services/localstorage.service';
import { timer } from 'rxjs';
import { MyHttpGetService } from '../services/http.services.token';

@Injectable()
export class TimerService {

	minutesDisplay: number = 0;
	secondsDisplay: number = 0;
	ms: number = 0;

	sub: Subscription;
	public boletos_bloqueados: { boletos: Array<Object>, fecha: string, orden : string, carrito } = { boletos: [], fecha: "", orden : "", carrito : "" };
  public boletos_bloqueados_local: Array<Boleto> = [];
  private folio : string;
	constructor(private _local: LocalstorageService, private router: Router, private myGetService: MyHttpGetService) {

	}

	public startTimer() {



		const baseTimer = timer(1,1000);

		this.sub = baseTimer.subscribe(
			t => {

				var currentDate = new Date();
				var fechaFin = new Date(this._local.getFechaFin());

				this.ms = fechaFin.getTime() - currentDate.getTime();

				var minutes = Math.floor(this.ms / 60000);
				var seconds = ((this.ms % 60000) / 1000).toFixed(0);
				this.minutesDisplay = minutes;
				this.secondsDisplay = parseInt(seconds);
				if (this.minutesDisplay <= 0 && this.secondsDisplay <= 0) {
          this.folio = this._local.getFolio();
          if(!this.folio){
            this.LiberaBoletos();
            this._local.deleteBoletosGenerados();
            this._local.deleteBoletosRecomendados();
            this._local.deleteBoletosApartados();
            this._local.deleteFechaFin();
            this._local.deleteFechaInicio();
            this._local.deleteBoletosBloqueados();
            this._local.deleteCarrito();
            this.minutesDisplay = 0;
            this.secondsDisplay = 0;
            if (this.sub) this.sub.unsubscribe();
            this.router.navigate(["compra-en-linea-boletos-sorteo-anahuac"]);
          }else{
            var date = new Date();
            this._local.setFechaFin(date);
            this.sub.unsubscribe();
            this.sub.closed;
          }
				}
			}
		);
	}

  private LiberaBoletos() : void{
    var lsApartados = this._local.getBoletosApartados();
    var lsCarrito = this._local.getCarrito();
    var lsBloqueados = this._local.getBoletosBloqueados();
    this.boletos_bloqueados.boletos = lsApartados == null ? [] : JSON.parse(lsApartados);
    this.boletos_bloqueados_local = lsBloqueados == null ? [] : JSON.parse(lsBloqueados);
    this.boletos_bloqueados.orden = this._local.getFolio();
    this.boletos_bloqueados.carrito = lsCarrito;
    this.boletos_bloqueados.boletos = [...this.boletos_bloqueados.boletos, ...this.boletos_bloqueados_local];
    if(this.boletos_bloqueados.carrito && this.boletos_bloqueados.boletos.length != 0){
      this.boletos_bloqueados.fecha = new Date().toISOString();
      this.myGetService.postData(this.boletos_bloqueados,"api/BoletosWeb/LiberaBoletos").subscribe((res) => {
      });
    }
	}
}
