import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpService } from '../../services/http.service';
 

@Injectable()
export class BoletosColaboradoresService extends HttpService {
    
    constructor(public _http:HttpClient) {
        super(_http)
    }
 
    // Uses http.get() to load data from a single API endpoint
    getBoletosColaborador(clave_colaborador: number) {
        const url = `${this.apiURL}api/BoletosColaborador/${clave_colaborador}`;
        return this.get(url);
    }

    getNombreColaborador(clave_colaborador: number) {
        const url = `${this.apiURL}api/NombreColaborador/${clave_colaborador}`;
        return this.get(url);
    }
}