interface Scripts {
  name: string;
  src: string;
  text: string;
  noscriptsrc: string;
}
export const ScriptStore: Scripts[] = [
  {
    name: 'tradetracker',
    src: '../../assets/Js/tradetrackerscript.js',
    text: "var ttConversionOptions = ttConversionOptions || []; ttConversionOptions.push({type: 'sales',campaignID: '34569',productID: '54299',transactionID: 'ORDER_ID',transactionAmount: 'ORDER_AMOUNT',quantity: '1',descrMerchant: '',descrAffiliate: '',vc: '',currency: ''});(function(ttConversionOptions) {var campaignID = 'campaignID' in ttConversionOptions ? ttConversionOptions.campaignID : ('length' in ttConversionOptions && ttConversionOptions.length ? ttConversionOptions[0].campaignID : null);var tt = document.createElement('script'); tt.type = 'text/javascript'; tt.async = true; tt.src = '//tm.tradetracker.net/conversion?s=' + encodeURIComponent(campaignID) + '&t=m';var s = document.getElementsByTagName('script'); s = s[s.length - 1]; s.parentNode.insertBefore(tt, s);})(ttConversionOptions);",
    noscriptsrc:
      '//ts.tradetracker.net/?cid=34569&amp;pid=54299&amp;tid=ORDER_ID&amp;tam=ORDER_AMOUNT&amp;data=&amp;qty=1&amp;descrMerchant=&amp;descrAffiliate=&amp;event=sales&amp;currency=MXN&amp;vc=',
  },
  {
    name: 'gtag',
    src: '../../assets/Js/tradetrackerscript.js',
    text: "dataLayer.push({ ecommerce: null }); dataLayer.push({ event: 'purchase', 'ecommerce': { 'purchase': { 'actionField': {'id': 'ORDER_ID','affiliation': 'Sorteo Anáhuac','revenue': ORDER_AMOUNT,'tax':'0','shipping': '0','coupon': ''},'products': [BOLETOS]}}});",
    noscriptsrc: '',
  },
];
