import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Headers, Http } from '@angular/http';
import * as base64 from 'base-64';
import { Observable, throwError } from 'rxjs';
import 'rxjs/add/operator/do';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as utf8 from 'utf8';
import { Leads } from '../models/Leads';

@Injectable()
export class MyHttpGetService {
  private serverUrl = environment.UrlBase;

  constructor(private http: Http) {}

  getLogin(data: any, path) {
    let options = this.createRequestOptionsLogin(data);
    return this.http.get(encodeURI(this.serverUrl + path), { headers: options }).pipe(map((res) => res));
  }

  getPagoComprador(data: any, path, params) {
    return this.http.get(encodeURI(this.serverUrl + path + '?correo=' + params)).pipe(map((res) => res));
  }

  getPagoCompradorBoletoInd(data: any, path, params) {
    return this.http.get(encodeURI(this.serverUrl + path + '?boleto=' + params)).pipe(map((res) => res));
  }

  getPagoCompradorVariosBoletos(data: any, path, params) {
    return this.http.get(encodeURI(this.serverUrl + path + '?boletos=' + params)).pipe(map((res) => res));
  }

  getPagoColaborador(data: any, path, params) {
    return this.http.get(encodeURI(this.serverUrl + path + '?pk1=' + params)).pipe(map((res) => res));
  }

  getLigaWebPay(path, params) {
    return this.http.get(encodeURI(this.serverUrl + path + '/' + params)).pipe(map((res) => res));
  }

  getInfoCompra(path, params) {
    return this.http.get(encodeURI(this.serverUrl + path + '/' + params)).pipe(map((res) => res));
  }

  getInfoOrden(path) {
    return this.serverUrl + path;
  }

  private createRequestOptionsLogin(data) {
    var model = data,
      email = model.email.toLowerCase(),
      password = model.password;
    ////console.log(model);
    var str = email + ':' + password;
    var bytes = utf8.encode(str);
    let headers = new Headers();
    headers.set('Authorization', 'Basic ' + base64.encode(bytes));
    headers.set('Content-Type', 'application/json');
    return headers;
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      //console.error('An error occurred:', error.error.message);
    } else {
      //console.error(
      //`Backend returned code ${error.status}, ` +
      //`body was: ${error.error}`);
    }
    return throwError('Something bad happened; please try again later.');
  }

  postData(data: Object, path) {
    let options = this.createRequestOptions();
    ////console.log(data);
    return this.http.post(encodeURI(this.serverUrl + path), data, { headers: options }).pipe(
      map((res) => res),
      //catchError(this.handleError)
    );
  }

  post(path) {
    let options = this.createRequestOptions();
    return this.http.post(encodeURI(this.serverUrl + path), { headers: options }).pipe(map((res) => res));
  }

  private createRequestOptions() {
    let headers = new Headers();
    headers.set('Content-Type', 'application/json');
    return headers;
  }

  getFoliosBoletos( pk_egresado: number) {
    return this.http.get(`${this.serverUrl}api/Egresado/EgregarCarritoBoletos?pk_egresado=`+pk_egresado);
  }

  checkTickets(folios: string): Observable<any> {
    return this.http.get(`http://localhost:2393/api/Boletos/checkTickets?folios=${folios}`);
  }
}
