import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as base64 from 'base-64';
import * as utf8 from 'utf8';
import { Boleto } from '../models/Boleto';
import { LocalstorageService } from '../services/localstorage.service';
import { SessionstorageService } from '../services/sessionstorage.service';
import { TimerService } from '../timer/timer.service';
import { BoletosColaboradoresService } from './services/boletos-colaboradores.service';

@Component({
  selector: 'app-boletos-colaboradores',
  templateUrl: './boletos-colaboradores.component.html',
  styleUrls: ['./boletos-colaboradores.component.scss'],
})
export class BoletosColaboradoresComponent implements OnInit {
  public clave_colaborador: number = 0;
  public clave_colaborador_encoded: string;
  public nombre_colaborador: string;
  public boletos_recomendados: Array<Boleto> = [];
  public boletos_colaborador: Array<Boleto> = [];
  public total_compra: number = 0;
  public tipos_pago: number = 1;

  constructor(
    private _session: SessionstorageService,
    private _local: LocalstorageService,
    private _BColaboradoresService: BoletosColaboradoresService,
    private route: ActivatedRoute,
    private router: Router,
    public st: TimerService
  ) {
    this.route.params.subscribe((params) => (this.clave_colaborador_encoded = params.id));
    var lsRecomendados = this._local.getBoletosRecomendados();
    this.boletos_recomendados = lsRecomendados == null ? [] : JSON.parse(lsRecomendados);
  }

  ngOnInit() {
    let clave = this.clave_colaborador_encoded;
    ////console.log(atob(clave));
    if(atob(clave) == "65960") {
      this.router.navigate(['/boletos-colaborador-anahuac-online/NjU5NjA']);
    }
    var decodedString = base64.decode(decodeURIComponent(this.clave_colaborador_encoded));
    var bytesString = utf8.decode(decodedString);
    this.clave_colaborador = Number(bytesString);
    ////console.log(this.clave_colaborador);
    this.getBoletosColaborador(this.clave_colaborador);
  }

  getBoletosColaborador(clave_colaborador: number) {
    ////console.log("Entra getBoletos");
    this._BColaboradoresService.getBoletosColaborador(clave_colaborador).subscribe(
      (data) => {
        this.boletos_colaborador = data;
        // //console.log(data);
      },
      (err) => console.error(err),
      () =>
        ////console.log('done loading boletos')
        {}
    );

    this._BColaboradoresService.getNombreColaborador(clave_colaborador).subscribe(
      (data) => {
        this.nombre_colaborador = data;
        ////console.log(data);
      },
      (
        err //console.error(err)
      ) => {},
      () =>
        ////console.log('done loading boletos')
        {}
    );
  }

  add(boletos_colaborador: Array<Boleto>) {
    for (var i = 0; i <= boletos_colaborador.length - 1; i++) {
      if (boletos_colaborador[i].seleccionado) {
        var control = 0;
        if (this.boletos_recomendados.length == 0) {
          this.boletos_recomendados.push(boletos_colaborador[i]);
          this.total_compra = this.total_compra + boletos_colaborador[i].costo;
        } else {
          for (var j = 0; j <= this.boletos_recomendados.length - 1; j++) {
            if (this.boletos_recomendados[j].folio !== boletos_colaborador[i].folio) {
              control = control + 1;
              if (control == this.boletos_recomendados.length) {
                this.boletos_recomendados.push(boletos_colaborador[i]);
                this.total_compra = this.total_compra + boletos_colaborador[i].costo;
              }
            }
          }
        }
      }
    }
    var date = new Date();
    this._local.setFechaInicio(date);
    this._local.setFechaFin(date);
    this._local.setBoletosRecomendados(this.boletos_recomendados);
    this.st.startTimer();
    this._session.setBoletosCol(1);
    this._session.setTiposPago(this.tipos_pago);
    sessionStorage.setItem('pagina', 'Liga compartida');
    sessionStorage.setItem('tipoBoleto', 'Boleto desde micrositio liga compartida');
    sessionStorage.setItem('texto', 'Gracias por sumarte a Sorteo Anáhuac, con tu boleto ayudaras a muchos estudiantes');
    this.router.navigate(['carrito-compra']);
  }
}
