import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { Observable } from 'rxjs';

@Injectable()
export class HomeService extends HttpService {
  constructor(public _http: HttpClient) {
    super(_http);
  }

  // Uses http.get() to load data from a single API endpoint
  getBoletoTablero(pkcolab) {
    const url = `${this.apiURL}/api/TombolaBoleto`;
    // console.log(pkcolab);
    return this.post(url, pkcolab);
  }
  getValidaBoleto(folio) {
    const url = `${this.apiURL}/api/BoletosWeb/ValidaBoleto/${folio}`;
    return this.get(url);
  }
  postBoletoConditioned(obj) {
    const url = `${this.apiURL}/api/BoletosWeb/Seleccion`;
    return this.post(url, obj);
  }
  getLuckyBol(obj) {
    //Lista de boletos para registrar como perpetuos
    const url = `${this.apiURL}/api/BoletosWeb/GetLuckyBol_Perm`;
    return this.post(url, obj);
  }
  setLuckyBol(obj) {
    // Boletos enviados a Back para registrar como perpetuos
    //(obj[0].comprador);
    const url = `${this.apiURL}/api/BoletosWeb/Registro_BoletoPerpetuo`;
    return this.post(url, obj);
  }
  getEstadoPopUp() {
    // Estado de Pop según DB
    const url = `${this.apiURL}/api/EstadoPopUp`;
    return this.get(url);
  }


  getToken() {
    /*local
    const body = {
      "correo": "ejemplo@gmail.com",
      "tipo_usuario": "0",
      "tipo_seguridad": "GCSaTPT6rUlfbDAriV5i4kIoS0w0d3oCXAh6v"

    };*/
    //test      
  /**/
    const body = {
      "correo": "ejemplo@gmail.com",
      "tipo_usuario": "0",
      "tipo_seguridad": "TDBHNjNuczNDdVIxVDNUQCNLM05CVFIqMjI0JTUzYzgzVA=="

    };
  /*prod
      const body = {
        "correo": "ejemplo@gmail.com",
        "tipo_usuario": "0",
    
        "tipo_seguridad": "dDBrM25EM1MzZ1U3SWRARDlAclFTM3ZpQzAyJCUjQTlJcyRlIzNQNXIwZFU1NTFPbmMyMXNUM200YXM="
    
      };*/
    return this._http.post(`${this.apiURL}/api/Seguridad/Token`, body);
  }

  getActiveZod(tokenS: string) {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + tokenS
    });
    return this._http.get(`${this.apiURL}/api/BoletosWeb/SeleccionInformacionZodiaco`, { headers });
  }

  getBoletosZod(num1: string, num2: string, tokenS: string,  pkCampania: string): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + tokenS
    });
    const obj = {
      terminacionUno: num1,
      terminacionDos: num2
    };
 
    return this._http.post<any>(`${this.apiURL}/api/BoletosWeb/SeleccionBoletoZodiaco?claveCampania=${pkCampania}`, obj, { headers });

  }
}
