<section class="sct-forma-pago">
  <div class="container">
    <div class="row">
      <div class="col-md-6 my-auto text-md-right text-center order-md-1 order-2">
        <img src="/assets/img/sorteo2024/card.png" alt="Forma de pago" class="img-fluid">
      </div>
      <div class="col-md-6 d-flex justify-content-center flex-column order-md-2 order-1" data-aos="fade-left"
        data-aos-easing="ease" data-aos-duration="3000">
        <h1>
          <span class="d-block">
            formas
          </span>
          de pago
        </h1>
      </div>

    </div>
  </div>
</section>



<section class="section-payment">
  <div class="container" data-aos="fade-right">
    <div class="row container-pago">
      <div class="col-md-12 text-center">


        <div id="timer" class="content-minute">

          <div [hidden]="
                (st.minutesDisplay == 0 && st.secondsDisplay == 0) ||
                boletos.length == 0 ||
                tipos_pago == 2 ||
                tipos_pago == 3
              ">
          </div>
          <div [hidden]="
                (st.minutesDisplay == 0 && st.secondsDisplay == 0) ||
                boletos.length == 0 ||
                tipos_pago == 2 ||
                tipos_pago == 3
              ">
            <i class="fa fa-clock-o" aria-hidden="true"></i>
            <span class="timeres">
              {{ st.minutesDisplay && st.minutesDisplay <= 59 ? st.minutesDisplay : '00' }} :
              {{
                  st.secondsDisplay && st.secondsDisplay <= 59
                    ? st.secondsDisplay < 10
                      ? '0' + st.secondsDisplay
                      : st.secondsDisplay
                    : '00'
                }}
            </span>
          </div>
          <!-- <img style="width: 35px; display: inline-block; margin-top: -12px;" src="../../assets/img/Editable/recortes/reloj_negro.PNG" /> -->
        </div>

      </div>
      <!-- ******************************************************************************************* -->

      <div class="col-md-12">
        <div class="metodo-pago-cont collapsible" id="content-mit">
          <label class="container">
            <input type="radio" id="FormaMIT" [(ngModel)]="metodo_pago" [value]="1" name="radio" />
            Tarjeta de débito o crédito (Visa/Mastercard)
          </label>

          <div class="container">
            <p class="text-justify">
              Paga con nuestro servicio de pago seguro con tarjetas. <br />
              Si tu tarjeta de crédito/débito tiene seguridad 3DS te pedirá un código de compra para la verificación.
              Por
              favor revisar el SMS en tu celular, donde te llegará este código que es generado por tu banco.
            </p>

            <img src="/assets/img/sorteo2024/tarjertas.png" alt="Tarjetas de credito" class="img-fluid">

          </div>
        </div>

        <div *ngIf="habilitaPaypal" class="metodo-pago-cont collapsible" id="content-pay">
          <label class="container">
            <input id="FormaPaypal" type="radio" [(ngModel)]="metodo_pago" [value]="3" name="radio" />
            Cuenta de PayPal
          </label>
          <div class="container">
            <img src="/assets/img/sorteo2024/paypal.png" alt="Paypal">
          </div>
        </div>

        <div *ngIf="habilitarReferencia" class="metodo-pago-cont collapsible" id="content-ref">
          <label class="container">
            <input id="FormaDeposito" type="radio" name="radio" [value]="2" [(ngModel)]="metodo_pago" />
            Depósito bancario y referencia
          </label>
          <div class="container">
            <p class="text-justify pclass">
              Puedes pagar acudiendo al banco Santander y realiza el depósito proporcionando el convenio y la
              referencia.
            </p>
          </div>
        </div>

        <a  id="ctaMorePay" (click)="showMethod()"> Ver métodos de pago +</a>

      </div>

      <div class="col-12 col-md-12 col-lg-4 margen-t center" *ngIf="tipos_pago == 1 && metodo_pago == 2"
        [hidden]="!habilitarReferencia">
        <h2>Referencia bancaria</h2>
        <div class="col-12 margen-t BoletosPay margen-b">
          <button (click)="efectuarPagoPublicoGeneral(2)" *ngIf="!mostrarReferencia" class="btn-stack border-0">
            Generar Referencia
          </button>

          

          <div *ngIf="mostrarReferencia" class="margen-t">
            <div class="margen-t margen-b">
              <p class="center">Banco:</p>
              <img style="width: 150px; margin-bottom: 10px" src="/assets/img/sorteo2023/logo-santander.png" />
              <br />
              <p class="center">Cuenta: {{ datos.cuenta }}</p>
              <p class="center">Convenio: {{ datos.convenio }}</p>
              <p class="center">CLABE: {{ datos.clabe }}</p>
              <h2 style="color:#333;">Para transferencia bancaria poner en campo "Concepto" la referencia</h2>
              <p class="center">Referencia: {{ datos.referencia }}</p>
              <p class="center" style="font-style: italic">
                La referencia generada ha sido enviada a su email <span style="font-weight: bold"></span>.
              </p>
            </div>
            <label>Enviar a otro correo:</label>
            <div class="input-group col-xs-10">
              <input [(ngModel)]="correo_adicional" type="text" class="form-control email-add" />
              <span class="input-group-btn w-100">
                <button (click)="reenviarCorreo()" class="btn-stack border-0" type="button"
                  [disabled]="correo_adicional == '' || enviando">
                  Enviar
                </button>
              </span>
            </div>
            <p style="color: black">{{ envioTexto }}</p>
            <br />
            <button (click)="regresar()" class="btn-stack border-0">Terminar</button>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 margen-t center"
        *ngIf="metodo_pago == 2 && (tipos_pago == 3 || tipos_pago == 2)" [hidden]="!habilitarReferencia">
        <h2>Referencia bancaria</h2>
        <div class="col-12 margen-t BoletosPay margen-b">
          <button (click)="efectuarPagoAlterno(2)" *ngIf="!mostrarReferencia" class="btn-stack border-none">
            Generar Referencia
          </button>

          <div *ngIf="mostrarReferencia" class="margen-t">
            <div class="margen-t margen-b">
              <p class="center">Banco:</p>
              <img style="width: 150px; margin-bottom: 10px" src="/assets/img/sorteo2023/logo-santander.png" />
              <br />
              <p class="center">Cuenta: {{ datos.cuenta }}</p>
              <p class="center">Convenio: {{ datos.convenio }}</p>
              <p class="center">CLABE: {{ datos.clabe }}</p>
              <h2 style="color:#333;">Para transferencia bancaria poner en campo "Concepto" la referencia</h2>
              <p class="center">Referencia: {{ datos.referencia }}</p>
              <p class="center" style="font-style: italic">
                La referencia generada ha sido enviada a su email <span style="font-weight: bold"></span>.
              </p>
            </div>
            <label>Enviar a otro correo:</label>
            <div class="input-group col-xs-10">
              <input [(ngModel)]="correo_adicional" type="text" class="form-control" />
              <span class="input-group-btn w-100 text-center">
                <button (click)="reenviarCorreo()" class="btn-stack border-0 mt-3 " type="button"
                  [disabled]="correo_adicional == '' || enviando">
                  Enviar
                </button>
              </span>
            </div>
            <p style="color: black">{{ envioTexto }}</p>
            <br />
            <button (click)="regresar()" class="btn-stack border-0">Terminar</button>
          </div>
        </div>
      </div>

      <div *ngIf="tipos_pago == 1" class="col-lg-6 offset-lg-3 center" style="margin: auto">
        <!-- <div id="timer" [hidden]="st.minutesDisplay == 0 && st.secondsDisplay == 0 || boletos.length == 0 || tipos_pago == 2 || tipos_pago == 3">
          <div>
            <img style="width: 35px; display: inline-block; margin-top: -12px;" src="../../assets/img/timer_blanco.png" />
            <h1 style="display: inline-block; width: 100px;">{{(st.minutesDisplay) && (st.minutesDisplay <= 59) ? st.minutesDisplay : '00'}} : {{(st.secondsDisplay) && (st.secondsDisplay <= 59) ? (st.secondsDisplay < 10) ? '0' + st.secondsDisplay : st.secondsDisplay : '00'}} </h1>
          </div>
        </div> -->
        <h2 class="center title-tickets-select">
          <span id="cantboletos" [ngClass]="100 > this.boletos.length ? 'badge' : 'badgeG'">{{ boletos.length }}</span>
          Boleto(s) seleccionado(s):
        </h2>
        <div class="col-12 margen-t BoletosPay text-center">
          <!-- <hr class="hr-white"> -->
          <div class="row" *ngFor="let boleto of boletos">
            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 left">
              <label> No. {{ boleto.folio }} </label>
            </div>

            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 right">
              <label> $ {{ boleto.costo }} MXN </label>
            </div>
          </div>

          <div class="row texto-primario" *ngIf="boletos.length == 0">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <label>No se ha seleccionado ningun boleto.</label>
            </div>
          </div>

          <hr class="hr-white" />
          <div class="row center">
            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 left">
              <label class="text-uppercase">
                <b>Total</b>
              </label>
            </div>
            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 right">
              <label>$ {{ total_compra }} MXN</label>
            </div>
          </div>
        </div>


        <div class="col-12 text-center">
        

          <p>
            NOTA IMPORTANTE: Si vas a hacer tu pago por Safari, verifica que tengas deshabilitadas las ventanas
            emergentes (dar click en el enlace) en caso que este sea por cualquier otro navegador, continúa con tu pago
            sin problema.
            <a href="https://www.youtube.com/shorts/XdR8SFZvABQ" target="_blank"
              style="color: #ff4700; word-wrap: break-word;">https://www.youtube.com/shorts/XdR8SFZvABQ</a>
          </p>
        </div>

        <!-- Metodo paypay checkout agrega a hidden [hidden]="metodo_pago === 2 && tipos_pago == 1 " || (boletos.length == 0 || procesando == 1)-->
        <div id="paypal-button-container" class="paypalForm" name="payplbtn" [hidden]="metodo_pago != 3"></div>

        <div id="banamex-button-container" class="paypalForm" name="banamexbtn" [hidden]="metodo_pago != 4"></div>
      </div>

      <!-- Esto es algo no sé que aún -->
      <div *ngIf="tipos_pago == 2 || tipos_pago == 3"
        class="col-xs-12 col-sm-12 col-md-7 col-lg-6 col-xl-4 margen-t bloque3 center" id="boletos_seleccionados">
        <!-- <div id="timer" [hidden]="st.minutesDisplay == 0 && st.secondsDisplay == 0 || boletos.length == 0 || tipos_pago == 2 || tipos_pago == 3">
          <div>
             <img style="width: 35px; display: inline-block; margin-top: -12px;" src="../../assets/img/timer_blanco.png" /> <----comenta esta
            <img style="width: 35px; display: inline-block; margin-top: -12px;" src="../../assets/img/Editable/recortes/reloj_negro.PNG" />
            <h1 style="display: inline-block; width: 100px;">{{(st.minutesDisplay) && (st.minutesDisplay <= 59) ? st.minutesDisplay : '00'}} : {{(st.secondsDisplay) && (st.secondsDisplay <= 59) ? (st.secondsDisplay < 10) ? '0' + st.secondsDisplay : st.secondsDisplay : '00'}} </h1>
          </div>
        </div> -->
        <h2 class="text-uppercase">
          <span [ngClass]="100 > this.boletos_preAsignados.boletos.length ? 'badge' : 'badgeG'">{{
            boletos_preAsignados.boletos.length
          }}</span>
          Boleto(s) seleccionado(s)
        </h2>
        <div class="col-12 margen-t BoletosPay margen-b">
          <div class="row center" *ngFor="let boleto of boletos_preAsignados.boletos">
            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 left">
              <label> No. {{ boleto.folio }} </label>
            </div>

            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 right">
              <label> $ {{ boleto.costo }} MXN </label>
            </div>
          </div>

          <div class="row texto-primario" *ngIf="boletos_preAsignados.boletos.length == 0">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <label>No se ha seleccionado ningun boleto.</label>
            </div>
          </div>

          <hr class="hr-white" />
          <div class="row">
            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <label class="text-uppercase"> Total </label>
            </div>
            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <label>$ {{ total_compra }} MXN</label>
            </div>
          </div>
        </div>


        <!-- Metodo paypay checkout agrega a hidden [hidden]="metodo_pago === 2 && tipos_pago == 1 " || (boletos.length == 0 || procesando == 1)-->
        <div id="paypal-button-container" class="paypalForm" name="payplbtn" [hidden]="metodo_pago != 3"></div>
        <div id="banamex-button-container" class="paypalForm" name="banamexbtn" [hidden]="metodo_pago != 4"></div>
      </div>
      <!-- Botón pagar -->
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="text-align: center"
        [hidden]="metodo_pago === 2">
        <div class="col-12 margen-t mrg">
          <button *ngIf="metodo_pago === 1 && (tipos_pago == 2 || tipos_pago == 3)" (click)="efectuarPagoAlterno(1)"
            class="btn-stack" [disabled]="boletos_preAsignados.boletos.length == 0">
            Realizar pago
          </button>
          <button *ngIf="metodo_pago === 1 && tipos_pago == 1" (click)="efectuarPagoPublicoGeneral(1)"
            class="btn-stack border-0 btn-pay" [disabled]="boletos.length == 0">
            Pagar
          </button>
          <!-- <button style="width:auto;" *ngIf="metodo_pago === 3 && tipos_pago == 1" (click)="efectuarPagoPublicoGeneral(2)" class="btn btn-secondary center" id="btn-pay-act">Pagar en PayPal</button> -->
        </div>

        <div class="col-12 margen-t">
          <label *ngIf="urlWebPay.nb_response !== '' && metodo_pago == 1">El pago no pudo ser procesado, intente de
            nuevo.</label>
        </div>


        <!-- pop -->
        <div class="spinnback" id="spin">
          <div class="spinn">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <br />
            <label style="color: whitesmoke; font-family: sofiaproregular">Espere por favor. <br />
              Procesando pago</label>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>



<!-- pop 
 <div class="alert popamigable" role="alert" id="popupamigable" [hidden]="true">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <label class="poptext">¡No te vayas!</label><br />
        <label class="poptext">Anímate a participar en el </label><br />
        <label>Sorteo Anáhuac 2022</label> <br />
        <label class="poptext">Compra los boletos que has elegido y </label><br />
        <label class="text-danger">¡Gana excelentes premios!</label>
        <div class="modal-footer center">
          <button
            id="cerrarpop"
            type="button"
            style="place-content: center"
            class="btn-stack border-0"
            (click)="PopupAmigable(1)"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
-->
