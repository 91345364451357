import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as $ from 'jquery';
// import { HomeService } from '../../../../services/home.service';
// import { RetrieveEmail } from '../../../../models/retrieveEmail';
import { ActivatedRoute, Router } from '@angular/router';
// import { Login } from '../../../../models/login';
// import {Md5} from 'ts-md5/dist/md5';
import { environment } from 'src/environments/environment';
import * as AOS from 'aos';
import { timer } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['../hamburgers/hamburgers.scss','./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public Urlsitio : string;

   //contadores

  //time magno
	_second = 1000;
	_minute = this._second * 60;
	_hour = this._minute * 60;
	_day = this._hour * 24;
	end: any;
	now: any;
	day: any;
	hours: any;
	minutes: any;
	seconds: any;
	source = timer(0, 1000);


	//time oportuno
	_segundos = 1000;
	_minutos = this._segundos * 60;
	_horas = this._minutos * 60;
	_dias = this._horas * 24;
	fin: any;
	ahora: any;
	dias: any;
	horas: any;
	minutos: any;
	segundos: any;
	source1 = timer(0, 1000);

	//time egresado
	_segundos_egr = 1000;
	_minutos_egr = this._segundos_egr * 60;
	_horas_egr = this._minutos_egr * 60;
	_dias_egr = this._horas_egr * 24;
	fin_egr: any;
	ahora_egr: any;
	dias_egr: any;
	horas_egr: any;
	minutos_egr: any;
	segundos_egr: any;
	source2 = timer(0, 1000);



  constructor(private route: ActivatedRoute, ) {
    this.Urlsitio = environment.Urlsitio;
  }


  ngOnInit(): void {    

    AOS.init({
      duration: 1000,
    });
    $(document).ready(function(){
      //main
      $(".mobile-nav-trigger").click(function(){
        $(this).children(".hamburger").toggleClass('is-active');
        $('.mobile-menu').toggleClass('active-nav');
        $('.overlay').toggleClass('active-overlay');
      });
      $('.main-nav-link').click(function(){
        $('.hamburger').toggleClass('is-active');
        $('.mobile-menu').toggleClass('active-nav');
        $('.overlay').toggleClass('active-overlay');
      });
    });

    //contador
    //time oportuno
		this.source1.subscribe(t => {
      this.ahora = new Date();
      this.fin = new Date('08/11/' + (this.ahora.getFullYear() ) +' 11:00');
      this.showDate();
    });

    //time magno
    this.source.subscribe(t => {
      this.now = new Date();
      this.end = new Date('11/23/' + (this.now.getFullYear() ) +' 17:00');
      this.showDateSorteoMagno();
    });

    //time egresado
    this.source2.subscribe(t => {
      this.ahora_egr = new Date();
      this.fin_egr = new Date('06/30/' + (this.ahora_egr.getFullYear() ) +' 12:00');
      this.showDateEgr();
    });

  }

  showDate(){
		let distance = this.fin - this.ahora;
		if(this.fin - this.ahora <= 0) {
			distance = 0;
	   	}
		this.dias = Math.floor(distance / this._dias);
		if(this.dias < 10) this.dias ="0" + this.dias;

		this.horas = Math.floor((distance % this._dias) / this._horas);
		if(this.horas < 10) this.horas ="0" + this.horas;

		this.minutos = Math.floor((distance % this._horas) / this._minutos);
		if(this.minutos < 10) this.minutos ="0" + this.minutos;

		this.segundos = Math.floor((distance % this._minutos) / this._segundos);
		if(this.segundos < 10) this.segundos ="0" + this.segundos;

	}

	showDateSorteoMagno(){
		let distance = this.end - this.now;
		
		if(this.end - this.now <= 0) {
			 distance = 0;
		}

		this.day = Math.floor(distance / this._day);
		if(this.day < 10) this.day ="0" + this.day;
		
		
		this.hours = Math.floor((distance % this._day) / this._hour);
		if(this.hours < 10) this.hours ="0" + this.hours;

		this.minutes = Math.floor((distance % this._hour) / this._minute);
		if(this.minutes < 10) this.minutes ="0" + this.minutes;

		this.seconds = Math.floor((distance % this._minute) / this._second);
		if(this.seconds < 10) this.seconds ="0" + this.seconds;

	}

	showDateEgr(){
		let distance = this.fin_egr - this.ahora_egr;
		if(this.fin_egr - this.ahora_egr <= 0) {
			distance = 0;
	   	}
		this.dias_egr = Math.floor(distance / this._dias_egr);
		if(this.dias_egr < 10) this.dias_egr ="0" + this.dias_egr;

		this.horas_egr = Math.floor((distance % this._dias_egr) / this._horas_egr);
		if(this.horas_egr < 10) this.horas_egr ="0" + this.horas_egr;

		this.minutos_egr = Math.floor((distance % this._horas_egr) / this._minutos_egr);
		if(this.minutos_egr < 10) this.minutos_egr ="0" + this.minutos_egr;

		this.segundos_egr = Math.floor((distance % this._minutos_egr) / this._segundos_egr);
		if(this.segundos_egr < 10) this.segundos_egr ="0" + this.segundos_egr;

	}

  close() {
    $('.overlay').removeClass('active-overlay');
    $('.hamburger').removeClass('is-active');
    $('.mobile-menu').removeClass('active-nav');

  }

  

}
