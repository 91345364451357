import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MyHttpGetService } from '../services/http.services.token';
import { LocalstorageService } from '../services/localstorage.service';
import { SessionstorageService } from '../services/sessionstorage.service';

@Component({
  selector: 'Login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [MyHttpGetService, LocalstorageService, SessionstorageService],
})
export class LoginComponent implements OnInit {
  public inputEmail: string = '';
  public inputPassword: string = '';
  public correoComprador: string = '';
  public tipoLogin: number = 1;
  public infoColaborador: Array<Object>;
  public mensaje: string = '';
  public mostrarMsj: boolean = false;
  public body: { correo: string } = { correo: '' };

  constructor(
    private myGetService: MyHttpGetService,
    private _local: LocalstorageService,
    private router: Router,
    private _session: SessionstorageService
  ) {}

  ngOnInit(): void {}

  public entrar(correo: string) {
    ////console.log("entro a funcion");
    const patronCorreo = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    var correoReplace = correo.split('.').join('!');
    ////console.log(correoReplace);
    this.body.correo = correo;


    if (patronCorreo.test(correo)) {
      this.myGetService.postData(this.body, 'api/BoletosWeb/ClaveColaborador').subscribe((res) => {
        ////console.log(res.json());
        if (res.json().length > 0) {
          ////console.log('Tengo resultados');
          this._local.deleteBoletosGenerados();
          this._local.deleteBoletosRecomendados();
          sessionStorage.setItem('pagina', 'pagar-boletos-en-linea');
          sessionStorage.setItem('tipoBoleto', 'Colaborador pago boleto micrositio');
          sessionStorage.setItem('texto', 'Gracias por sumarte a Sorteo Anáhuac, con tu boleto ayudaras a muchos estudiantes');
          this.router.navigate(['pagar/1/' + res.json()]);
        }
      });
    }
    
  }

  private entrarPago() {
    this.router.navigate(['carrito-compra']);
  }


  //soy comprador
  public entrarPagoCorreo(correo: String) {
    const patronCorreo = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    var correoReplace = correo.split('.').join('!');
    ////console.log(correoReplace);
    this._local.deleteBoletosGenerados();
    this._local.deleteBoletosRecomendados();
    this._session.setTipoAsignado(2);
    if (patronCorreo.test(this.inputEmail)) {
      sessionStorage.setItem('pagina', 'pagar-boletos-en-linea');
      sessionStorage.setItem('tipoBoleto', 'Comprador pago boleto micrositio');
      sessionStorage.setItem('texto', 'Gracias por sumarte a Sorteo Anáhuac, con tu boleto ayudaras a muchos estudiantes');
      this.router.navigate(['pagar/2/' + correoReplace]);
    }
    
  }

  public compartirBoletos(correo: String) {
    const control = new FormControl(correo, Validators.compose([Validators.email, Validators.required]));
    ////console.log(control.errors);
    if(control.errors == null){
      var colaborador = correo;
      this._local.deleteBoletosGenerados();
      this._local.deleteBoletosRecomendados();
      this.router.navigate(['compartir-boleto'],{ queryParams: {correo: colaborador}, skipLocationChange: true});
    }
    else{
      if(control.errors.required){
        this.mensaje = "El campo no debe estar vacío.";
      }
      if(control.errors.email){
        this.mensaje = "Formato de correo inválido.";
      }
      this.mostrarMsj = true;
    }
  }

  private makeUuid() {
    var text = '';
    var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (var i = 0; i < 30; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }
}
