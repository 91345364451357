import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import * as base64 from 'base-64';
import * as utf8 from 'utf8';
import { MyHttpGetService } from '../services/http.services.token';
import { LocalstorageService } from '../services/localstorage.service';
import { SessionstorageService } from '../services/sessionstorage.service';
import { TimerService } from '../timer/timer.service';
import { environment } from '../../environments/environment';
import { utf8Encode } from '@angular/compiler/src/util';

@Component({
  selector: 'app-compartir',
  templateUrl: './compartir.component.html',
  styleUrls: ['./compartir.component.scss']
})
export class CompartirComponent implements OnInit {
  activatedRoute: any;
  link: string;
  has_userkey: boolean;
  message: string;

  public body: { correo: string } = { correo: '' };
  public user: { clave: string, nombre: string } = { clave: '', nombre: ''};

  constructor(
    private _session: SessionstorageService,
    private _local: LocalstorageService,
    private router: Router,
    private route: ActivatedRoute,
    private myGetService: MyHttpGetService,
    private st: TimerService,
    private meta: Meta
  ) {
    this.meta.updateTag({ name: 'title', content: 'Comparte tus boletos del Sorteo Anáhuac en línea' });
    this.meta.updateTag({
      name: 'description',
      content:
        'Invita a tus amigos a comprar un boleto de sorteo Anáhuac.',
    });
    this.has_userkey = false;
    this.message = "Lo sentimos, no se encontró un colaborador registrado bajo ese correo.";
  }

  ngOnInit() {

    this.route.queryParams
      .subscribe(params => {
        this.body.correo = params.correo;
      }
    );
   
    if(this.body.correo != ""){
      //consume endpoint y obtiene clave
      this.myGetService.postData(this.body, 'api/Usuarios/GetNameKey').subscribe(
        (result) => {
          var result_json = result.json();
          ////console.log(result_json);
          if(result_json != undefined && result_json != null && result_json.length > 0){
            var user_json = JSON.parse(result_json);
            var bytes:any;
            var user_key:any;
            if (Object.keys(user_json).length > 0) { 
              if(user_json.clave != ""){
                if(user_json.hasTickets == true){
                  if(user_json.hasDigitalTickets == true){
                    this.has_userkey = true;
                    var bytes = utf8.encode(user_json.clave);
                    user_key = encodeURIComponent(base64.encode(bytes));
                    this.user.clave = user_json.clave;
                    this.user.nombre = user_json.nombre;
                    this.link = environment.urlCompartirBoletos + user_key;
                  }
                  else{
                    this.message = "Lo sentimos, este colaborador no cuenta con boletos digitales.";
                  }
                }
                else{
                  this.message = "Lo sentimos, este colaborador aún no cuenta con boletos.";
                }
              }
            }
          }

        },
        (error) => {
          this.has_userkey = false;
          this.message = "Ocurrió un error al intentar localizar al colaborador. Disculpa las molestias."
          ////console.log("error al solicitar clave de colaborador");
          ////console.log(error);
        }
      );
    }
  }

  public copylink(inputElement){
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0,0);
    var alert = document.getElementById('message');
    alert.classList.remove("hidden");
    setTimeout(() => {
      alert.classList.add("hidden");
    }, 2000);
  }
}
