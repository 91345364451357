<section class="stc-data-cmp">
  <div class="container">
    <div class="row">
      <div class="col-md-12 d-flex justify-content-center flex-column text-center" data-aos="fade-left"
        data-aos-easing="ease" data-aos-duration="3000">
        <img src="/assets/img/sorteo2024/computer.png" class="img-fluid">
        <h1>
          Datos de
          <span class="d-md-block">
            comprador
          </span>
        </h1>
      </div>
    </div>
  </div>
</section>


<section class="data-buyer-cpm" data-aos="fade-up">
  <div class="container">
    <div class="row">

      <div class="col-md-12 text-center">
        <div id="timer" [hidden]=" (st.minutesDisplay == 0 && st.secondsDisplay == 0) || boletos.length == 0"
          class="content-minute">

          <i class="fa fa-clock-o" aria-hidden="true"></i>
          <span class="">
            {{
                st.minutesDisplay && st.minutesDisplay <= 59
                  ? st.minutesDisplay
                  : "00"
              }}
            :
            {{
                st.secondsDisplay && st.secondsDisplay <= 59
                  ? st.secondsDisplay < 10
                    ? "0" + st.secondsDisplay
                    : st.secondsDisplay
                  : "00"
              }}
          </span>
        </div>
      </div>

      <div class="col-md-12">
        <h2>Recuerda </h2>
        <p>
          que el nombre que proporciones debe coincidir exactamente con
          una identificación oficial (INE,pasaporte,etc.)
        </p>

        <div class="nav-tickets">
          <div class="flecha-izq">
            <img id="Izq" src="/assets/img/sorteo2023/arrow-left.png"
              style="width: 1rem" (click)="scroll(1)" />
          </div>
          <div class="interno-boletos hidden-scroll" id="tabla" style="overflow-x: scroll !important;">
            <table *ngIf="boletos" id="tab" class="tabC mx-auto ">
              <thead>
                <tr>
                  <th class="texto-boletos" style="width: 7rem !important" *ngFor="let boleto of boletos; let i = index"
                    [attr.data-index]="i">
                    <label class="label label-info container" *ngIf="boleto.folio" style="
                          padding-left: 25px;
                          width: max-content;
                        
                        ">
                      <!-- /////(boleto_check = false) diferente de FALSE /// -->

                      <input name="boletos-seleccionados" type="radio" [(ngModel)]="boletocheck.folio"
                        value="{{ boleto.folio }}" (click)="CambiaBoletoCheck(i)"
                        [disabled]="rotular_todos || !this.formDatosComprador.valid" class="tickets opc-0" />

                      {{ boleto.folio }}
                      <span class="checkma chec"></span>
                    </label>
                  </th>
                </tr>
              </thead>
            </table>
          </div>
          <div class="flecha-der">
            <img src="/assets/img/sorteo2023/arrow-right.png" id="Der"
              style="width: 1rem" (click)="scroll(2)" />
          </div>
        </div>
      </div>


      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
        <form class="form-vertical border-form" [formGroup]="formDatosComprador">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 mx-auto">
              <div>
                <label class="control-label col-12 " [ngClass]="{
                    validatexto:
                      formDatosComprador.controls.nombre.invalid && valida
                  }" for="nombre">Nombre *</label>
                <div class="form-group" [ngClass]="{
                    'has-error':
                      formDatosComprador.controls.nombre.invalid && valida
                      
                  }">
                  <div class="col-12">
                    <input type="text" name="nombre" [(ngModel)]="Info[0].nombre" class="form-control"
                      [ngClass]="{'is-invalid': formDatosComprador.controls.nombre.errors && (formDatosComprador.controls.nombre.touched || formDatosComprador.controls.nombre.dirty), 'is-valid': formDatosComprador.controls.nombre.valid}"
                      formControlName="nombre" id="nombre" placeholder="Nombre"  maxLength="60"/>
                    <div class="text-left invalid-feedback mb-2">
                      <p>Formato incorrecto</p>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div>
              <label class="control-label col-12"  [ngClass]="{'validatexto': formDatosComprador.controls.nombre.invalid && valida}" for="nombre">Nombre *</label>
              <div class="form-group" [ngClass]="{'has-error': formDatosComprador.controls.nombre.invalid && valida}">
                <div class="col-12">
                  <input type="text" *ngFor="let boleto of boletos ; let i= index; trackBy:trackByIdx;" name="nombre" [(ngModel)]="Info[i].nombre" class="form-control shadow"  formControlName="nombre">
                </div>
              </div>
            </div>	 -->

              <div hidden>
                <label class="control-label col-12 " for="apellidos">Apellidos*</label>
                <div class="form-group">
                  <div class="col-12">
                    <input type="text" name="apellidos" [(ngModel)]="Info[0].apellidos" class="form-control"
                      id="apellidos" formControlName="apellidos" placeholder="Apellidos"  maxLength="200"/>
                  </div>
                </div>
              </div>

              <div>
                <label class="control-label col-12 " [ngClass]="{
                    validatexto:
                      formDatosComprador.controls.apellido_paterno.invalid && valida
                  }" for="apellido_paterno">Apellido paterno*</label>
                <div class="form-group" [ngClass]="{
                    'has-error':
                      formDatosComprador.controls.apellido_paterno.invalid && valida
                  }">
                  <div class="col-12">
                    <input type="text" name="apellido_paterno" [(ngModel)]="Info[0].apellido_paterno"
                      class="form-control"
                      [ngClass]="{'is-invalid': formDatosComprador.controls.apellido_paterno.errors && (formDatosComprador.controls.apellido_paterno.touched || formDatosComprador.controls.apellido_paterno.dirty), 'is-valid': formDatosComprador.controls.apellido_paterno.valid}"
                      formControlName="apellido_paterno" id="apellido_paterno" placeholder="Apellido paterno"
                      (ngModelChange)="ValidaApellidos()" maxLength="60"/>
                    <div class="text-left invalid-feedback mb-2">
                      <p>Formato incorrecto</p>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <label class="control-label col-12" [ngClass]="{
                    validatexto:
                      formDatosComprador.controls.apellido_materno.invalid && valida
                  }" for="apellido_materno">Apellido materno*</label>
                <div class="form-group" [ngClass]="{
                    'has-error':
                      formDatosComprador.controls.apellido_materno.invalid && valida
                  }">
                  <div class="col-12">
                    <input type="text" name="apellido_materno" class="form-control"
                      [ngClass]="{'is-invalid': formDatosComprador.controls.apellido_materno.errors && (formDatosComprador.controls.apellido_materno.touched || formDatosComprador.controls.apellido_materno.dirty), 'is-valid': formDatosComprador.controls.apellido_materno.valid}"
                      [(ngModel)]="Info[0].apellido_materno" formControlName="apellido_materno" id="apellido_materno"
                      placeholder="Apellido materno" (ngModelChange)="ValidaApellidos()" maxLength="60"/>
                    <div class="text-left invalid-feedback mb-2">
                      <p>Formato incorrecto</p>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div>
                <label class="control-label col-12 " [ngClass]="{
                    validatexto:
                      formDatosComprador.controls.telefono_fijo.invalid && valida
                  }" for="telefono">Teléfono fijo *</label>
                <div class="form-group" [ngClass]="{
                    'has-error':
                      formDatosComprador.controls.telefono_fijo.invalid && valida
                  }">
                  <div class="col-12">
                    <input type="tel" name="telefono_fijo" [(ngModel)]="Info[0].telefono_fijo" class="form-control"
                      [ngClass]="{'is-invalid': formDatosComprador.controls.telefono_fijo.errors && (formDatosComprador.controls.telefono_fijo.touched || formDatosComprador.controls.telefono_fijo.dirty), 'is-valid': formDatosComprador.controls.telefono_fijo.valid}"
                      maxlength="10" formControlName="telefono_fijo" placeholder="Favor de ingresar 10 digitos" />
                    <div class="text-left invalid-feedback mb-2">
                      <p>Formato incorrecto</p>
                    </div>
                  </div>
                </div>
              </div> -->

              <div>
                <label class="control-label col-12 " [ngClass]="{
                    validatexto:
                      formDatosComprador.controls.telefono_movil.invalid && valida
                  }" for="movil">Teléfono móvil *</label>
                <div class="form-group" [ngClass]="{
                    'has-error':
                      formDatosComprador.controls.telefono_movil.invalid && valida
                  }">
                  <div class="col-12">
                    <input type="tel" name="telefono_movil" [(ngModel)]="Info[0].telefono_movil" class="form-control"
                      [ngClass]="{'is-invalid': formDatosComprador.controls.telefono_movil.errors && (formDatosComprador.controls.telefono_movil.touched || formDatosComprador.controls.telefono_movil.dirty), 'is-valid': formDatosComprador.controls.telefono_movil.valid}"
                      maxlength="10" formControlName="telefono_movil" placeholder="Favor de ingresar 10 digitos" />
                    <div class="text-left invalid-feedback mb-2">
                      <p>Formato incorrecto</p>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <label class="control-label col-12" [ngClass]="{
                    validatexto:
                      formDatosComprador.controls.correo_electronico.invalid &&
                      valida
                  }" for="correo_electronico">Correo del electrónico *</label>
                <div class="form-group" [ngClass]="{
                    'has-error':
                      formDatosComprador.controls.correo_electronico.invalid &&
                      valida
                  }">
                  <div class="col-12">
                    <input type="email" name="correo_electronico" [(ngModel)]="Info[0].correo_electronico"
                      class="form-control"
                      [ngClass]="{'is-invalid': formDatosComprador.controls.correo_electronico.errors && (formDatosComprador.controls.correo_electronico.touched || formDatosComprador.controls.correo_electronico.dirty), 'is-valid': formDatosComprador.controls.correo_electronico.valid}"
                      maxlength="60" formControlName="correo_electronico" placeholder="ejemplo@correo.com" />
                    <div class="text-left invalid-feedback mb-2">
                      <p>Formato incorrecto</p>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <label class="control-label col-12" [ngClass]="{
                    validatexto:
                      formDatosComprador.controls.correo_tarjetahabiente.invalid &&
                      valida
                  }" for="correo_tarjetahabiente">Correo del tarjetahabiente.*</label>
                <div class="form-group" [ngClass]="{
                    'has-error':
                      formDatosComprador.controls.correo_tarjetahabiente.invalid &&
                      valida
                  }">
                  <div class="col-12">
                    <input type="email" name="correo_tarjetahabiente" [(ngModel)]="Info[0].correo_tarjetahabiente"
                      class="form-control"
                      [ngClass]="{'is-invalid': formDatosComprador.controls.correo_tarjetahabiente.errors && (formDatosComprador.controls.correo_tarjetahabiente.touched || formDatosComprador.controls.correo_tarjetahabiente.dirty), 'is-valid': formDatosComprador.controls.correo_tarjetahabiente.valid}"
                      maxlength="60" formControlName="correo_tarjetahabiente" placeholder="ejemplo@correo.com" />
                    <div class="text-left invalid-feedback mb-2">
                      <p>Formato incorrecto</p>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div>
                <label class="control-label col-12 " [ngClass]="{
                    validatexto:
                      formDatosComprador.controls.correo_alternativo.invalid &&
                      valida
                  }" for="correo_alternativo">Correo electrónico alternativo</label>
                <div class="form-group" [ngClass]="{
                    'has-error':
                      formDatosComprador.controls.correo_alternativo.invalid &&
                      valida
                  }">
                  <div class="col-12">
                    <input type="email" name="correo_alternativo" id="correo_alternativo"
                      [(ngModel)]="Info[0].correo_alternativo" class="form-control"
                      [ngClass]="{'is-invalid': formDatosComprador.controls.correo_alternativo.errors && (formDatosComprador.controls.correo_alternativo.touched || formDatosComprador.controls.correo_alternativo.dirty), 'is-valid': formDatosComprador.controls.correo_alternativo.touched &&formDatosComprador.controls.correo_alternativo.valid}"
                      maxlength="60" formControlName="correo_alternativo" placeholder="ejemplo@correo.com" />
                    <div class="text-left invalid-feedback mb-2">
                      <p>Formato incorrecto</p>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>

            <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 mx-auto">
              <!-- <div>
                <label class="control-label col-12" [ngClass]="{
                    validatexto: formDatosComprador.controls.calle.invalid && valida
                  }" for="calle">Calle *</label>
                <div class="form-group" [ngClass]="{
                    'has-error': formDatosComprador.controls.calle.invalid && valida
                  }">
                  <div class="col-12">
                    <input type="text" name="calle" [(ngModel)]="Info[0].calle" class="form-control"
                      [ngClass]="{'is-invalid': formDatosComprador.controls.calle.errors && (formDatosComprador.controls.calle.touched || formDatosComprador.controls.calle.dirty), 'is-valid': formDatosComprador.controls.calle.valid}"
                      formControlName="calle" placeholder="Avenida "   maxlength="100"  />
                    <div class="text-left invalid-feedback mb-2">
                      <p>Formato incorrecto</p>
                    </div>
                  </div>
                </div>
              </div> -->

              <!-- <div>
                <label class="control-label col-12" [ngClass]="{
                    validatexto:
                      formDatosComprador.controls.numero.invalid && valida
                  }" for="numero">Número *</label>
                <div class="form-group" [ngClass]="{
                    'has-error':
                      formDatosComprador.controls.numero.invalid && valida
                  }">
                  <div class="col-12">
                    <input type="tel" name="numero" [(ngModel)]="Info[0].numero" class="form-control"
                      [ngClass]="{'is-invalid': formDatosComprador.controls.numero.errors && (formDatosComprador.controls.numero.touched || formDatosComprador.controls.numero.dirty), 'is-valid': formDatosComprador.controls.numero.valid}"
                      formControlName="numero" placeholder="0000" maxlength="25" />
                      <div class="text-left invalid-feedback mb-2">
                        <p>Solo se acpetan números y letras</p>
                      </div>
                  </div>
                </div>
              </div> -->

              <div>
                <label class="control-label col-12" [ngClass]="{
                    validatexto: formDatosComprador.controls.pais.invalid && valida
                  }" for="pais">País *</label>
                <div class="form-group" [ngClass]="{
                    'has-error': formDatosComprador.controls.pais.invalid && valida
                  }">
                  <div class="col-12" name="pais" id="clna">
                    <select *ngIf="paises | async as Paises" class="form-control selectpicker" data-live-search='true'
                      [(ngModel)]="Info[0].pais" id ="pais" name="pais" formControlName="pais" (ngModelChange)="ValidaPais()" (click)="validaDir()">
                      <option [ngValue]="" selected disabled hidden>
                        Selecciona tu país
                      </option>
                      <option [ngValue]="pais.isonum" *ngFor="let pais of Paises">
                        {{ pais.nombre }}
                      </option>
                    </select>
                    <input *ngIf="!(paises | async)" [(ngModel)]="Info[0].pais" type="text" class="form-control"
                      formControlName="pais" disabled hidden />
                  </div>
                  <!-- *ngFor="let boleto of boletos ; let i= index; trackBy:trackByIdx;" name="colonia" [(ngModel)]="Info[i].colonia" -->
                </div>
              </div>

              <div [hidden]="ocultar_cp == false">
                <label class="control-label col-12" [ngClass]="{
                    validatexto:
                      formDatosComprador.controls.codigo_postal.invalid && valida
                  }" for="codigo_postal">Código postal *</label>
                <div class="form-group" [ngClass]="{
                    'has-error':
                      formDatosComprador.controls.codigo_postal.invalid && valida
                  }">
                  <div class="input-group col-12">
                    <!-- <div class="col-9"> -->
                    <input type="tel" id="codigo_postal" name="codigo_postal" [(ngModel)]="Info[0].codigo_postal"
                      (ngModelChange)="infoCPChanged()" minlength="5" maxlength="5" type="text" class="form-control"
                      [ngClass]="{'is-invalid': formDatosComprador.controls.codigo_postal.errors && (formDatosComprador.controls.codigo_postal.touched || formDatosComprador.controls.codigo_postal.dirty || flagCP), 'is-valid': formDatosComprador.controls.codigo_postal.valid}"
                      formControlName="codigo_postal" placeholder="07200" />
                    <div class="text-left invalid-feedback mb-2">
                      <p>Formato incorrecto</p>
                    </div>
                    <span class="input-group-btn">
                      <button (click)="getInfoCP(Info[0].codigo_postal)" class="btn-stack search" type="button">
                        Buscar
                      </button>
                    </span>
                    <!-- </div> -->
                  </div>
                  <p style="color: black; text-indent: 1em">{{ messageAlert }}</p>
                </div>
              </div>

              <div>
                <label class="control-label col-12" [ngClass]="{
                    validatexto:
                      formDatosComprador.controls.colonia.invalid && valida
                  }" for="colonia">Colonia *</label>
                <div class="form-group" [ngClass]="{
                    'has-error':
                      formDatosComprador.controls.colonia.invalid && valida
                  }">
                  <div class="col-12" name="colonia" id="clna">
                    <select *ngIf="Colonias.length !== 0" class="form-control" [(ngModel)]="Info[0].colonia"
                      [ngClass]="{'is-invalid': formDatosComprador.controls.colonia.errors && (formDatosComprador.controls.colonia.touched || formDatosComprador.controls.colonia.dirty || !flagSelect) , 'is-valid': formDatosComprador.controls.colonia.valid}"
                      name="colonia" formControlName="colonia" (change)="onChange($event.target.value)">

                      <option [ngValue]="colonia.colonia" *ngFor="let colonia of Colonias">
                        {{ colonia.colonia }}
                      </option>
                    </select>
                    <input *ngIf="Colonias.length == 0 " [(ngModel)]="Info[0].colonia" type="text" class="form-control"
                      id="colonia"
                      [ngClass]="{'is-invalid': formDatosComprador.controls.colonia.errors && (formDatosComprador.controls.colonia.touched || formDatosComprador.controls.colonia.dirty), 'is-valid': formDatosComprador.controls.colonia.valid}"
                      formControlName="colonia"  />
                    <div class="text-left invalid-feedback mb-2">
                      <p>Seleccionar colonia</p>
                    </div>
                  </div>
                  <!-- *ngFor="let boleto of boletos ; let i= index; trackBy:trackByIdx;" name="colonia" [(ngModel)]="Info[i].colonia" -->
                </div>
              </div>

              <div>
                <label class="control-label col-12" [ngClass]="{
                    validatexto:
                      formDatosComprador.controls.municipio.invalid && valida
                  }">Municipio/Delegación *</label>
                <div class="form-group" [ngClass]="{
                    'has-error':
                      formDatosComprador.controls.municipio.invalid && valida
                  }">
                  <div class="col-12">
                    <input type="text" name="municipio" [ngModel]="Info[0].municipio" class="form-control"
                      formControlName="municipio"
                      [ngClass]="{'is-invalid': formDatosComprador.controls.municipio.errors && (formDatosComprador.controls.municipio.touched || formDatosComprador.controls.municipio.dirty || flagCP), 'is-valid': formDatosComprador.controls.municipio.valid}"
                      id="municipio" disabled />
                    <div class="text-left invalid-feedback mb-2">
                      <p>Dato faltante</p>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <label class="control-label col-12" [ngClass]="{
                    validatexto:
                      formDatosComprador.controls.estado.invalid && valida
                  }" for="estado">Estado *</label>
                <div class="form-group" [ngClass]="{
                    'has-error':
                      formDatosComprador.controls.estado.invalid && valida
                  }">
                  <div class="col-12">
                    <input type="text" name="estado" [ngModel]="Info[0].estado" class="form-control"
                      [ngClass]="{'is-invalid': formDatosComprador.controls.estado.errors && (formDatosComprador.controls.estado.touched || formDatosComprador.controls.estado.dirty), 'is-valid': formDatosComprador.controls.estado.valid}"
                      formControlName="estado" id="estado" />
                    <div class="text-left invalid-feedback mb-2">
                      <p>Dato faltante</p>
                    </div>
                  </div>
                </div>
              </div>


              <div>
                <label class="control-label col-12" [ngClass]="{
                    validatexto: formDatosComprador.controls.egresado.invalid && valida
                  }" for="egresado">¿Eres egresado de la Red Anáhuac o Red Semper Altius? *</label>
                <div class="form-group" [ngClass]="{
                    'has-error': formDatosComprador.controls.egresado.invalid && valida
                  }">
                  <div class="col-12" name="egresado" id="clna">
                    <select id="egresadoslct" class="form-control" [(ngModel)]="Info[0].egresado"
                      [ngClass]="{'is-invalid': formDatosComprador.controls.egresado.errors && (formDatosComprador.controls.egresado.touched || formDatosComprador.controls.egresado.dirty), 'is-valid': formDatosComprador.controls.egresado.valid}"
                      name="egresado" formControlName="egresado" (change)="ValidaEgresado()">
                      <option [ngValue]="" selected disabled hidden>
                        Selecciona
                      </option>
                      <option [ngValue]="'0'">
                        No
                      </option>
                      <option [ngValue]="'1'">
                        Si
                      </option>
                    </select>
                    <div class="text-left invalid-feedback mb-2">
                      <p>Selecciona una opción</p>
                    </div>

                    <input id="egresadoinput" [(ngModel)]="Info[0].egresado" type="text" class="form-control"
                      formControlName="egresado" disabled hidden />
                  </div>
                </div>
              </div>


              <div id="campusdiv" [hidden]="Info[0].egresado == '0' || Info[0].egresado == ''">

                <div>
                  <label class="control-label" for="campusslct">Campus *</label>
                  <div class="form-group" [formGroup]="formDatosComprador">
                    <div class="col-12" name="campus">

                      <select id="campusslct" name="campus" formControlName="campus"
                        *ngIf="campusall | async as Campusall" class="form-control  is-invalid inv"
                        [(ngModel)]="Info[0].campus" (change)="ValidaCampus()">
                        <option [ngValue]="" selected disabled hidden>
                          Selecciona tu campus
                        </option>
                        <option [ngValue]="campus.codigo" *ngFor="let campus of Campusall">
                          {{ campus.campus }}
                        </option>
                      </select>

                      <input id="campus" [(ngModel)]="Info[0].campus" type="text" class="form-control"
                        formControlName="campus" disabled hidden />
                      <div *ngIf="!this.flagPlaceCampus" class="text-left invalid-feedback mb-2">
                        <p>Selecciona Campus</p>
                      </div>

                    </div>
                  </div>
                </div>

              </div>

              <div>
                <label class="control-label col-12" [ngClass]="{
                        validatexto: formDatosComprador.controls.pkcanal1.invalid && valida
                      }" for="pkcanal">Canal por donde nos conociste *</label>
                <div class="form-group" [ngClass]="{
                        'has-error': formDatosComprador.controls.pkcanal1.invalid && valida
                      }">
                  <div class="col-12">
                    <select class="form-control selectpicker" data-live-search='true' [(ngModel)]="Info[0].pkcanal"
                      name="pkcanal" formControlName="pkcanal1">
                      <option [ngValue]="" selected disabled hidden>
                        Selecciona el canal
                      </option>
                      <option [ngValue]="canal.pkcanal" *ngFor="let canal of canales">
                        {{ canal.canal }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div *ngIf="Info[0].pkcanal == 14 || Info[0].pkcanal == 15;">
                <label class="control-label col-12" for="pkcanal">Especifique canal*</label>
                <div class="form-group">
                  <div class="col-12">
                    <input type="text" name="especifica_canal" class="form-control" id="especifica_canal" placeholder=""
                      maxlength="100" pattern="^[a-zA-ZáéíóúÁÉÍÓÚ0-9\s\-\/\.]+$" (input)="validaCanal($event)" />
                    <div *ngIf="especificaCanalInvalid" class="invalid-feedback">
                      Este campo solo debe contener letras, números, espacios, acentos, guiones, barras y puntos.
                    </div>
                  </div>
                </div>
              </div>
              



              <div>
                <div>
                  <div class="col-12">
                    <!-- <p> <input type="checkbox" [checked]="aceptar_terminos" (change)="aceptar_terminos = !aceptar_terminos" name="checkbox" ><a href="https://www.sorteoanahuac.mx/app/aviso.html"> Acepto las políticas de protección y manejo de datos</a></p> -->
                    <label class="container checkC">
                      <input type="checkbox" [checked]="aceptar_terminos"
                        (change)="aceptar_terminos = !aceptar_terminos" name="checkbox" />

                      <a id="UrlAnahuac" href="{{ UrlAnahuac }}" target="_blank">
                        Acepto las políticas de protección y manejo de datos</a>
                      <!-- "https://www.sorteoanahuac.mx/app/aviso.html"   -->

                    </label>
                  </div>
                  <div class="col-12" *ngIf="boletos.length > 1">
                    <label class="container checkC">
                      <input type="checkbox" [checked]="rotular_todos" (change)="rotular_todos = !rotular_todos"
                        name="checkbox" />
                      <span>Rotular todos los boletos con los mismos datos.</span>

                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Botones -->
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center"
            *ngIf="boletos.length > 1 && rotular_todos == false">
            <button name="btn-rotular" class="btn-stack" style="margin-right: 3%" [disabled]="rotular_todos || cont == 0"
              (click)="Anterior()">
              Anterior
            </button>
            <button name="btn-rotular" class="btn-stack" style=""
              [disabled]="rotular_todos || cont + 1 >= boletos.length" (click)="Siguiente()">
              Siguiente
            </button>
          </div>
          <!-- Selecciona colaborador	 -->
          <!-- Liga unica -->


          <div *ngIf="IDColab != null">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-5 text-center">
              <!-- <p> <input type="checkbox" [checked]="aceptar_terminos" (change)="aceptar_terminos = !aceptar_terminos" name="checkbox" ><a href="https://www.sorteoanahuac.mx/app/aviso.html"> Acepto las políticas de protección y manejo de datos</a></p> -->
              <label class="container checkC formwidth">
                <input type="checkbox" [checked]="colaborador_voluntarioC"
                  (change)="colaborador_voluntarioC = !colaborador_voluntarioC" name="checkbox" />
                <label class="align-text-bottom">La persona que me invitó a comprar mi boleto es &nbsp;
                </label>
                <textarea class="" name="" id="" cols="20" rows="1" readonly>{{
                    nombreColab
                  }}</textarea>
                <label class="d-inline align-top">&nbsp; y deseo que participe como colaborador voluntario de
                  Sorteo Anáhuac con el boleto que yo estoy comprando.
                </label>
                <span class="checkmark"></span>
              </label>
            </div>


            <div *ngIf="flagBolVal; " class="validate-ticket">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-4" [hidden]="colaborador_voluntarioC">
                <label class="container checkC formwidth">
                  <input type="checkbox" [disabled]="intentosColab == 0" [checked]="selecciona_colaboradorC"
                    (change)="selecciona_colaboradorC = !selecciona_colaboradorC" name="checkbox" />
                  <label class="align-text-bottom">Deseo asignar este boleto a otro colaborador voluntario (ingrese
                    su correo)</label>
                  <span class="checkmark"></span>
                </label>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="row" [hidden]="selecciona_colaboradorC == false">
                    <form class="form col-12 col-xs-6 col-sm-6 col-md-6 col-lg-6 d-inline"
                      [formGroup]="formDatosColaborador">
                      <div class="input-group col-xs-12 col-sm-12 col-md-12 col-lg-12 fwidth" style="margin-left: auto">
                        <input name="colaborador" [(ngModel)]="correocolaborador" type="email"
                          class="form-control col-xs-12 col-sm-12 col-md-12 col-lg-12" maxlength="50"
                          formControlName="correo_electronico" placeholder="ejemplo@correo.com" id="inp_colab" />
                        <span class="input-group-btn">
                          <button (click)="GetNombreColab()" class="btn-d" [disabled]="intentosColab == 0"
                            type="button">
                            Buscar
                          </button>
                        </span>
                      </div>
                    </form>
                    <div class="col-xs-8 col-sm-8 col-md-6 col-lg-6 center_sub">
                      <label *ngIf="nombreColab2">{{ nombreColab2 }}</label><br *ngIf="nombreColab2" />
                      <label class="validatexto">{{ messageAlert1 }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>

          <!-- Liga unica -->

          <!-- Publico general -->
          <!-- <div *ngIf="IDColab == null">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-4" [hidden]="colaborador_voluntarioC">
              <div *ngIf="flagBolVal; " class="validate-ticket">
                <label class="container checkC formwidth">
                  <input type="checkbox" [checked]="selecciona_colaboradorC"
                    (change)="selecciona_colaboradorC = !selecciona_colaboradorC" name="checkbox"
                    id="checkbox_seleccionaC" />
                  <span class="align-text-bottom">Si deseas asignar este(os) boleto(s) a un Colaborador voluntario,
                    por favor, escribe su e-mail:</span>
                </label>
              </div>

              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="row formwidth ml-auto" [hidden]="selecciona_colaboradorC == false">
                  <form class="form col-md-12 d-inline col_xl" [formGroup]="formDatosColaborador">
                    <div class="input-group col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <label>E-mail del Colaborador voluntario &nbsp;</label>
                    </div>

                    <div class="input-group col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <input name="colaborador" [(ngModel)]="correocolaborador" type="email"
                        class="form-control inpwidth" maxlength="50" formControlName="correo_electronico"
                        placeholder="ejemplo@correo.com" id="inp_colab" [disabled]="intentosColab == 0" />
                    </div>

                    <div class="input-group col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">

                      <span class="input-group-btn">
                        <button (click)="GetNombreColab()" class="btn-stack" [disabled]="intentosColab == 0"
                          type="button">
                          Buscar
                        </button>
                      </span>
                    </div>
                  </form>
                  <div class="col-xs-6 col-sm-6 col-md-12 col-lg-6 mt-3">
                    <label *ngIf="nombreColab2">
                      Nombre del colaborador: 
                      <span style="color:#333;">{{ nombreColab2 }}</span>
                    </label> 
                    <br *ngIf="nombreColab2" />
                    <label class="validatexto">{{ messageAlert1 }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <!-- Publico general -->
          <!-- Selecciona colaborador	 -->

          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
            <button (click)="validaInfo()" class="btn-stack purple" [disabled]="boletos.length == 0">
              Forma de pago
            </button>
            <p style="color: #fc5705">{{ mensajeError }}</p>
          </div>
          <div class="col-12 text-center">
            <p class="cnt-text">
              El boleto que compres se te proporcionará vía correo electrónico.
            </p>
          </div>
          <div class="col-12 text-center">
            <p>*Datos obligatorios</p>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>




<!-- pop 
<div class="alert popamigable" role="alert" id="popupamigable">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <label class="poptext">¡No te vayas!</label><br />
        <label class="poptext">Anímate a participar en el </label><br />
        <label class="poplabel">Sorteo Anáhuac 2022</label> <br />
        <label class="poptext">Compra los boletos que has elegido y </label><br />
        <label class="text-danger poplabel">¡Gana excelentes premios!</label>
        <div class="modal-footer center">
          <button id="cerrarpop" type="button" style="place-content: center" class="btn-stack border-0" (click)="PopupAmigable(1)">
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
 pop -->
