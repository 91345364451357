<section class="stc-carrito">
	<div class="container">
		<div class="row">
			<div class="col-lg-7 col-md-12 my-auto text-lg-right order-lg-1 order-2">
				<div class="content-img">
					<img src="/assets/img/sorteo2024/carrito.png" class="img-fluid" alt="Carrito Sorteo Anáhuac">
				</div>
			</div>
			<div class="col-lg-5 col-md-12 d-flex justify-content-center flex-column order-lg-2 order-1 my-auto" data-aos="fade-left"  data-aos-easing="ease"  data-aos-duration="3000">
				
				<h1>
					carrito
					<span class="d-block">
						de compras
					</span>
				</h1>
			</div>
		</div>
	</div>
</section>



<section class="stc-boleto">
	<div class="container">
			<div class="row container-info"  data-aos="fade-right">

				<div class="col-md-8 offset-md-2 text-center">
					<p>
						<span>
							{{boletos.length}}
						</span>
						Boleto(s) seleccionado(s):
					</p>
				</div>

				<div class="col-12 text-center content-minute " [hidden]="st.minutesDisplay == 0 && st.secondsDisplay == 0 || boletos.length == 0 || tipos_pago == 2 || tipos_pago == 3" >
					<i class="fa fa-clock-o" aria-hidden="true"></i>

					<span>
						{{(st.minutesDisplay) && (st.minutesDisplay <= 59) ? st.minutesDisplay : '00'}} : {{(st.secondsDisplay) && (st.secondsDisplay <= 59) ? (st.secondsDisplay < 10) ? '0' + st.secondsDisplay : st.secondsDisplay : '00'}}
					</span>
				</div>

				<ng-container  *ngFor="let boleto of boletos">
					<div class="col-6 text-center">
						<a (click)="deleteBoleto(boleto)">
							<i role="button" class="fa fa-times-circle-o mr-2" aria-hidden="true"></i>
						</a>
						<label>
							 No. {{boleto.folio}}
						</label>
					</div>
					<div class="col-6 text-center">
						<label>
							$ {{boleto.costo}} MXN
						</label>
					</div>
					<div class="col-md-12 text-center" *ngIf="boleto.nombre_comprador">
						<label>
							{{boleto.nombre_comprador}}
						</label>

					</div>
				</ng-container>

				<hr class="line w-100 border-gradient-all">

				<div class="col-6 text-center">
					<label class="txt ">
						Total
					</label>
				</div>

				<div class="col-6 text-center">
					<label class="txt">$ {{total_compra}} MXN</label>
				</div>

				<div class="col-12 text-center" *ngIf="boletos.length == 0">
					<label>No se ha seleccionado ningún boleto.</label>
				</div>

				<div *ngIf="tipos_pago !== 2 && tipos_pago !== 3 && bolCola !== 1" class="col-12 text-right">
					<button (click)="regresar();" class="btn-stack purple">
						{{ tipos_pago == 3 ? 'Regresar' : 'Agregar otro boleto'}}
					</button>
				</div>
				<div class="col-12 text-right">
					<button [disabled]="boletos.length == 0"
							(click)="efectuarPago();"
							class="btn-stack">
							Comprar
					</button>
				</div>

			</div>
	</div>
</section>




<section class="spinner">
	<div class="container">
		<div class="row">
			<div class="col-md-12">

				<div class="spinnback" id="spin" [hidden]="spinner">
					<div class="spinn">

						<label >Espere por favor. <br> Procesando </label><br>
						<div  class="lds-hourglass"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>


<!-- pop 
<div class="alert popamigable" role="alert"  id="popupamigable"  [hidden]="true">
	<div class="container">
		<div class="row">
			<div class="col-12">
				<label class="poptext">¡No te vayas!</label><br>
				<label class="poptext">Anímate a participar en el </label><br>
				<label class="poplabel">Sorteo Anáhuac 2023</label>	<br>
				<label class="poptext">Compra los boletos que has elegido y </label><br>
				<label class="text-danger poplabel	">¡Gana excelentes premios!</label>
				<div class="modal-footer center">
					<button id="cerrarpop" type="button" style="place-content: center;" class="btn btn-secondary btn-pop" (click)="PopupAmigable(1)">Cerrar</button>
				</div>
			</div>
		</div>
	</div>
</div>

-->

<!-- Cambios Luis Aguilar WECODE -->
<!-- Datos Campaña  pop -->
<!-- <div *ngIf="ShowPopCampania" class="modal" tabindex="-1" role="dialog"> -->
<!-- <div class="modal fade" id="modalCompania" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg"  *ngIf="ShowPopCampania" >
    <div class="modal-content">
      <div class="modal-header text-center">
        <h4 id="dialog-auto-name" class="modal-title pull-left">
			Ya casi tienes tu boleto, para continuar sólo déjanos tu correo electrónico 
        </h4>
      </div>
      <div class="modal-body" >
        <form class="form row" [formGroup]="formDatosCompradorCampania">
          <div class="form-group col-md-12" >
            <div class="col-12 block">
              <label class="control-label" for="correo">Correo electrónico</label>
              <input
                type="email"
                [(ngModel)]="InfoCompradorCampania.correo"
                class="form-control shadow"
                maxlength="50"
                formControlName="correo"
              />
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer center">
        <button
          id="registrarDatosCampanias"
          type="button"
          style="place-content: center"
          class="btn btn-pop btn-stack"
          (click)="RegistroDatosCampanias()"
        >
          Continuar
        </button>
 <button id="cerrarpop" type="button" style="place-content: center" class="btn btn-pop btn-stack" (click)="ClosePopCampanias()">
          Cerrar
        </button> 
      </div>
    </div>
  </div>
 </div> -->
<!-- Datos Campaña pop -->
<!-- END Cambios Luis Aguilar WECODE -->

