import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { v4 as uuid } from 'uuid';
import { Boleto } from '../models/Boleto';
import { MyHttpGetService } from '../services/http.services.token';
import { LocalstorageService } from '../services/localstorage.service';
import { SessionstorageService } from '../services/sessionstorage.service';
import { TimerService } from '../timer/timer.service';
import { Leads } from '../models/Leads';
import { DatosCompradorService } from '../datos-comprador/services/datos-comprador.service';
import { PaymentSource } from '../models/PaymentSource';
import { SeguimientoService } from '../services/seguimiento.service';

// para uso de paypal y popup
declare var paypal, Checkout, window: any;
var datosbol, Urlp, Urlc, popC, spin, Tcomprador, UrlBanamex, ordenbanamex, btn_banamex: any;

@Component({
  selector: 'app-forma-pago',
  templateUrl: './forma-pago.component.html',
  styleUrls: ['./forma-pago.component.scss'],
  providers: [MyHttpGetService, LocalstorageService],
})
export class FormaPagoComponent implements OnInit, AfterViewChecked {
  //tipos_pago -> Metodos de pago a mostrar según el tipo de sesión (1 -> PublicoGeneral, 2 -> Colaborador desde APP, 3 -> Colaborador desde PC)

  /////////////////////////

  formFormaPago: FormGroup;
  public boletos: Array<Boleto> = [];
  public boletos_asignados: Array<Object> = [];
  public boletos_log: {
    pk1: string;
    boletos: Array<Object>;
    folio: string;
    email: string;
    amount: string;
    nombre: string;
    apellidos: string;
    telefono: string;
    boletos_string: string;
    tipo_comprador: number;
    referencia: string;
  } = {
      pk1: '',
      boletos: [],
      folio: '',
      email: '',
      amount: '',
      nombre: '',
      apellidos: '',
      telefono: '',
      boletos_string: '',
      tipo_comprador: 3,
      referencia: '',
    };
  public boletos_preAsignados: { boletos: Array<Boleto>; confirmacion: string; correo: string; folio: string } = {
    boletos: [],
    confirmacion: '',
    correo: '',
    folio: '',
  };
  public total_compra: number = 0;
  public metodo_pago: number = 1;
  public tipos_pago: number = 1;
  public valida: boolean = false;
  public folio: string = '0';
  public referenciaBanco: string = '0';
  public convenio: string = '0';
  public clabe: string = '0';
  public cuenta: string = '0';
  public procesando: number = 0;
  public tipo_comprador: number = 3;
  public mostrarReferencia: boolean = false;
  public correo: string = ''; // correo con el que se inicio sesion.
  public correo_adicional: string = ''; // correo con el que se inicio sesion.
  public datos: {
    boletos: Array<Boleto>;
    referencia: string;
    folio: string;
    convenio: string;
    clabe: string;
    cuenta: string;
    correo: string;
    fecha: string;
    colaborador: boolean;
  } = {
      boletos: [],
      referencia: '',
      folio: '',
      convenio: '',
      clabe: '',
      correo: '',
      fecha: '',
      colaborador: false,
      cuenta: '',
    };
  public urlWebPay: { cd_response: string; nb_response: string; nb_url: SafeResourceUrl } = {
    cd_response: '',
    nb_response: '',
    nb_url: '',
  };
  public enviando: boolean = false;
  public envioTexto: string = '';
  public Paybackref: string;
  public pkcolab: string;
  public BoletosCol: string;
  public habilitarReferencia: boolean = true;
  public habilitaPaypal: boolean = true;
  public spinhandler: number = 0;
  cargando = false;


  constructor(
    private domSanitizer: DomSanitizer,
    private myGetService: MyHttpGetService,
    private route: ActivatedRoute,
    private router: Router,
    public st: TimerService,
    private _local: LocalstorageService,
    private _session: SessionstorageService,
    private fb: FormBuilder,
    private _DCompradorService: DatosCompradorService,
    private readonly _seguimiento: SeguimientoService
  ) {
    this.correo = this._session.getCorreoComp();
    var lsApartados = this._local.getBoletosApartados();
    var lsAsignados = this._local.getBoletosAsignados();
    this.boletos = lsApartados == null ? [] : JSON.parse(lsApartados);
    //console.log(lsApartados);
    //console.log(lsAsignados);
    //console.log(this.boletos);
    this.boletos_preAsignados.boletos = lsAsignados == null ? [] : JSON.parse(lsAsignados);
    this.tipo_comprador = this._session.getTipoAsignado() ? parseInt(this._session.getTipoAsignado()) : 3;

    this.route.queryParams.subscribe((params) => {
      this.folio = params.ordencompra == undefined ? '0' : params.ordencompra;
    });
    ////console.log(this.folio);

    this.tipos_pago = parseInt(this._session.getTiposPago());

    this.formFormaPago = this.fb.group({
      nombre_titular: ['', Validators.pattern(/[a-zA-Z������������\s]/)],
      no_tarjeta: ['', Validators.pattern(/^\d*$/)],
      vence_mes: ['', Validators.pattern(/^\d*$/)],
      vence_anio: ['', Validators.pattern(/^\d*$/)],
      codigo_seg: ['', Validators.pattern(/^\d*$/)],
    });
    //#region Validacion Referencia Banc Activa
    this.BoletosCol = this._session.getBoletosCol() ? this._session.getBoletosCol() : '0';
    this.Paybackref = this._local.getPaybackref() ? this._local.getPaybackref() : '0';
    this.pkcolab = this._local.getPKColab() ? this._local.getPKColab() : '0';
    //#endregion
  }

  async ngOnInit() {
    /*INSERTAR LOG CUANDO SE PASE DIRECTO A FORMA DE PAGO */
    //console.log(this.boletos_preAsignados.boletos);
    //console.log(this.tipo_comprador);
    ///console.log();

    /* */
   // let noshow = localStorage.getItem('noShow');
    //if(noshow != 'true') {  this.showConfirm(); }
   
    spin = document.getElementById('spin');
    spin.hidden = false;

    /// Pop up	& Paypal
    popC = this._session.getPopstate();
    ////console.log(popC);
    //this.PopupAmigable(popC);
    Urlp = this.myGetService.getInfoOrden('api/BoletosWeb/PagoPayPalSDK');
    Urlc = this.myGetService.getInfoOrden('api/BoletosWeb/PagoPayPalConfirmado');
    UrlBanamex = this.myGetService.getInfoOrden('api/BoletosWeb/PagoBanamex');
    Tcomprador = this.tipo_comprador;
    /// Pop up & Paypal

    if (this.tipos_pago == 1) {
      this.st.startTimer();
    }

    this.procesando = 0;
    if (this.tipos_pago == 1) {
      this.getTotalCompra(this.boletos);
    } else {
      this.getTotalCompra(this.boletos_preAsignados.boletos);
    }

    if (this.folio != '0') {
      this.efectuarPagoPublicoGeneral();
    }

    // history.pushState(null,null, 'forma-pago');
    // window.addEventListener('popstate', function(event) {
    // 	history.pushState(null,null, 'forma-pago');
    // 	//console.log(this.history.length);
    // })
    ////console.log("tipo comprador");
    ////console.log(this.tipo_comprador);
    if (this.tipo_comprador == 3) {
      if (this.tipos_pago >= 2) {
      } else {
        history.pushState(null, null, 'forma-pago');
        window.addEventListener('popstate', function (event) {
          history.replaceState(null, null, 'compra-en-linea-boletos-sorteo-anahuac');
          history.pushState(null, null, 'compra-en-linea-boletos-sorteo-anahuac');
          history.go(0);
        });
      }
    }
    if (this._local.getFolio()) {
      ////console.log("Si tiene folio");
      if (this.tipo_comprador == 3) {
        if (this.tipos_pago >= 2) {
        } else {
          this._local.deleteBoletosGenerados();
          this._local.deleteBoletosRecomendados();
          this._local.deleteBoletosAsignados();
          window.sessionStorage.clear();
          this._local.deleteFolio();
          this.router.navigate(['compra-en-linea-boletos-sorteo-anahuac']);
        }
      }
      // if (this.tipo_comprador == 1 || this.tipo_comprador == 2) {
      // this.router.navigate(["login"]);
      //	this.router.navigate(["compra-en-linea-boletos-sorteo-anahuac"]);
      // } else {
      // 	this.router.navigate(["compra-en-linea-boletos-sorteo-anahuac"]);
      // }
    }
    //ordenbanamex = false;
    const ref1 = await this.GetMetodoParametrizado();
    if (this.BoletosCol != '2') {
      const ref = await this.RefBancStatus();
      //const ref1 = await this.GetMetodoParametrizado();
    } else {
      this.spinHandlerMetodoParametrizado();
    }


    /**/
    let ctnMit = document.getElementById("content-mit");
    let ctnPay = document.getElementById("content-pay");
    let ctnRef = document.getElementById("content-ref");
    let ctaMorePay = document.getElementById("ctaMorePay");
    ctaMorePay.style.display = "none";

    let btnMit = document.getElementById("FormaMIT");
    let btnPay = document.getElementById("FormaPaypal");
    let btnRef = document.getElementById("FormaDeposito");

    btnMit.addEventListener("click", function () {
      ctnPay.style.display = "none";
      ctnRef.style.display = "none";
      ctaMorePay.style.display = "block";
    });

    btnPay.addEventListener("click", function () {
      ctnMit.style.display = "none";
      ctnRef.style.display = "none";
     // ctaMorePay.style.display = "block";
    });

    btnRef.addEventListener("click", function () {
      ctnMit.style.display = "none";
      ctnPay.style.display = "none";
      ctaMorePay.style.display = "block";
    });


    /**/

  }

  showConfirm() {
    (window as any).alertify
      .confirm('IMPORTANTE',
        `<div class="custom-confirm">
          <p>Por favor, considera los siguientes puntos:</p>
            <ul>
            
              <li>En este momento, tu boleto se encuentra en el carrito. En caso de que salgas de la pantalla, actualices la página, cierres el navegador o experimentes un error durante el pago, tendrás que esperar 15 minutos antes de volver a intentar la compra por el mismo número.</li>
             
              <li>Si generas una referencia bancaria, tu boleto quedará reservado durante 5 días hábiles. No podrás pagar con otro método de pago hasta que el boleto se libere.</li>

              <li> Si vas a pagar con tarjeta de débito, se recomienda hacerlo con tu tarjeta digital.</li>

            </ul>

            
            <p>
                <strong>Siguiendo estas recomendaciones podrás evitar problemas y asegurarte de que tu compra se procese sin inconvenientes. Si sigues los pasos correctamente, el proceso de pago será más fluido y sin complicaciones.</strong>
            </p>
        </div>`,
        () => {
          (window as any).alertify.success('¡LO TENGO!');
          
        },
        () => {
          // Esta función puede estar vacía si no necesitas manejar el clic en Cancelar
          localStorage.setItem('noShow', 'true');
        }
      )
      .set('labels', { ok: 'He leído', cancel: 'No mostar más' })  // Etiqueta vacía para el botón Cancelar
      .set('closable', false)  // Evitar cerrar la ventana al hacer clic fuera de ella
      .set('defaultFocus', 'ok')  // Definir el enfoque por defecto
      .set('className', 'custom-confirm');  // Aplicar la clase CSS personalizada
  }

  showMethod() {
    let ctaMorePay = document.getElementById("ctaMorePay");
    ctaMorePay.style.display = "none";
    /**/
    let ctnMit = document.getElementById("content-mit");
    let ctnPay = document.getElementById("content-pay");
    let ctnRef = document.getElementById("content-ref");
    ctnMit.style.display = "block";
    ctnPay.style.display = "block";
    ctnRef.style.display = "block";
  }

  getTotalCompra(boletos: Array<Boleto>) {
    if (boletos !== []) {
      for (var i = 0; i <= boletos.length - 1; i++) {
        this.total_compra = this.total_compra + boletos[i].costo;
      }
    }
  }

  async efectuarPagoPublicoGeneral() {

    var btnpay = document.getElementById('paypal-button-container');
    btnpay.hidden = true;
    spin = document.getElementById('spin');
    spin.hidden = true;

    if (this.metodo_pago == 1 || this.metodo_pago == 2) {
      spin.hidden = false;
    }

    this.boletos_asignados = [];

    this.boletos.forEach(
      function (Item) {
        this.boletos_asignados.push({
          comprador: {
            direccion: {
              telefono: Item.comprador.telefono_fijo,
              municipio: Item.comprador.municipio,
              estado: Item.comprador.estado,
              codigo_postal: Item.comprador.codigo_postal,
              colonia: Item.comprador.colonia,
              numero: Item.comprador.numero,
              calle: Item.comprador.calle,

            },
            nombre_completo: Item.comprador.nombre + ' ' + Item.comprador.apellidos,
            celular: Item.comprador.telefono_movil,
            correo: Item.comprador.correo_electronico,
            correo_alternativo: Item.comprador.correo_alternativo,
            correo_tarjetahabiente: Item.comprador.correo_tarjetahabiente,
            apellidos: Item.comprador.apellidos,
            apellido_paterno: Item.comprador.apellido_paterno,
            apellido_materno: Item.comprador.apellido_materno,
            egresado: Item.comprador.egresado,
            campus: Item.comprador.campus,
            nombre: Item.comprador.nombre,
            pkcanal: Item.comprador.pkcanal,
            especifica_canal: Item.comprador.especifica_canal,
          },
          folio_talonario: Item.folio_talonario,
          folio: Item.folio,
          clave: Item.clave.toString(),
        });
        this.boletos_log.boletos.push({
          folio_talonario: Item.folio_talonario,
          folio: Item.folio,
          clave: Item.clave.toString(),
        });
      }.bind(this)
    );
    ////console.log( this.boletos_asignados);
    this.boletos_log.nombre = this.boletos[0].comprador.nombre;
    this.boletos_log.apellidos = this.boletos[0].comprador.apellidos;
    this.boletos_log.email = this.boletos[0].comprador.correo_electronico;
    this.boletos_log.telefono = this.boletos[0].comprador.telefono_movil;
    this.boletos_log.pk1 = uuid();
    this.boletos_log.tipo_comprador = this._session.getBoletosCol() == '1' ? 4 : 3;
    /* aqui se crea el comprador y se tra referencia y todos los datos pero aun no se hace la compra */
    //console.log(this.boletos_asignados);
    //console.log("respuesta");
    /* */
    console.log(this.folio);
    if (this.folio != '0') {
      window.location.href = 'error-pago?success=false&nbResponse=ErrorDatosCarrito';
    } else {
      return new Promise((resolve, reject) => {
        console.log(this.boletos_asignados);
        this.myGetService
          .postData(this.boletos_asignados, 'api/BoletosWeb/PreventaPublicoGeneralBanco/' + this.metodo_pago)
          .subscribe((res) => {
            console.log(res);

            let obj = res.json();

            this.folio = obj.folio;
            this.referenciaBanco = obj.referencia;
            this.convenio = obj.convenio;
            this.clabe = obj.clabe;
            this.cuenta = obj.cuenta;
            this.boletos_log.folio = this.folio;
            this.boletos_log.amount = this.total_compra.toString();
            this.boletos_log.boletos_string = JSON.stringify(this.boletos_log.boletos);
            this._local.setFolio(this.folio);
            /* crear localstorage boletos*/
            localStorage.setItem("boletosAparatadosTem", sessionStorage.getItem('BoletosApartados'));
            if (this.folio == '0' || !this.folio) {
              window.location.href = 'error-pago?success=false&nbResponse=ErrorDatosCarrito';
            } else {
              //aqui llama function para pago al banco

              this.myGetService.postData(this.boletos_log, 'api/BoletosWeb/VentaLog').subscribe((res) => {
                //console.log(res);
              });

              if (this.metodo_pago == 2) {
                this.datos.folio = this.folio;
                this.datos.referencia = this.referenciaBanco;
                this.datos.convenio = this.convenio;
                this.datos.clabe = this.clabe;
                this.datos.cuenta = this.cuenta;
                this.datos.boletos = this.boletos;
                this.datos.correo = this.boletos_log.email;
                this.mostrarReferencia = true;
                spin.hidden = true;
                this.myGetService.postData(this.datos, 'api/BoletosWeb/ReenvioReferencia').subscribe((res) => {
                  ////console.log(res);
                });

                this.myGetService.postData(this.datos, 'api/BoletosWeb/EnviarRecordatorio').subscribe((res) => {
                  ////console.log(res);
                });

                var correoalt = this.boletos[0].comprador.correo_alternativo;

                if (correoalt) {
                  this.datos.correo = this.boletos[0].comprador.correo_alternativo;

                  this.myGetService.postData(this.datos, 'api/BoletosWeb/ReenvioReferencia').subscribe((res) => {
                    ////console.log(res);
                  });
                }

                //enviar referencia
                let infoCampania: Leads;
                //console.log("lead referencia bancaria");
                let temInfo = JSON.parse(localStorage.getItem('campaingData'));
                //localStorage.setItem("first", 'false' );
                //console.log(temInfo);
                infoCampania = temInfo;
                //console.log( infoCampania);
                //traer datos de la compra
                let datosComprador = JSON.parse(sessionStorage.getItem('BoletosApartados'));
                //console.log(datosComprador);
                let numBoletos = infoCampania.boletos_seleccionados.split(",");

                //añadir nuevo correo 
                /* datos comprador */
                infoCampania.correo = datosComprador[0].comprador.correo_electronico;
                infoCampania.sorteo = 8;
                infoCampania.nombre = datosComprador[0].comprador.nombre;

                infoCampania.apellido_peterno = datosComprador[0].comprador.apellido_paterno;
                infoCampania.apellido_materno = datosComprador[0].comprador.apellido_materno;
                infoCampania.telefono = datosComprador[0].comprador.telefono_fijo;
                infoCampania.celular = datosComprador[0].comprador.telefono_movil;
                infoCampania.num_boletos_comprados = numBoletos.length;
                infoCampania.monto = 450 * numBoletos.length;
                infoCampania.estatus_pago = "REFERENCIA-" + this.datos.referencia;
                infoCampania.boletos_seleccionados = "";
                //console.log(datosComprador);
                //console.log(infoCampania);
                let dataCampania: Leads = infoCampania;
                this._DCompradorService.modificaLeads(dataCampania).subscribe(a => {
                  //console.log(a);


                },
                  err => {
                    //console.log(err);
                    if (err.status == 200) {
                      //console.log("entre2 REFERENCIA");
                      localStorage.removeItem("InfoCompania");
                      localStorage.removeItem("campaingData");
                      localStorage.setItem("leads", "false");
                      let organico = "?utm_campaign=organico&utm_content=organico&utm_placement=organico&campaign_name=organico&site_source_name=organico&placement=organico";
                      let utm2 = organico.split("?");
                      var search2 = utm2[1];
                      //console.log(JSON.stringify(organico));
                      let finalUtm = JSON.parse('{"' + search2.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) { return key === "" ? value : decodeURIComponent(value) });
                      ////console.log(finalUtm);
                      localStorage.setItem('campaingData', JSON.stringify(finalUtm));

                      //console.log(localStorage.getItem('leads'));
                      //console.log(dataCampania);
                    }
                  });

                //add seguimiento
                sessionStorage.setItem('metodoPago', 'REFERENCIA');
                sessionStorage.setItem('ordenDeCompra', "REFERENCIA - " + this.datos.referencia);
                let respuesta;
                let tipoP = sessionStorage.getItem('TiposPago');
                let tipoPInt = parseInt(tipoP, 10)
                if (tipoPInt == 2 || tipoPInt == 3) {
                  sessionStorage.setItem('paginaclick', '/forma-pago - directo');
                  this._seguimiento.addSeguimiento(JSON.parse(sessionStorage.getItem('BoletosAsignados'))).subscribe(a => {
                    console.log(a);
                    respuesta = a;
                    const pk_seguimiento = respuesta.pk1;
                    console.log(pk_seguimiento);
                    sessionStorage.setItem('pk_seguimiento', pk_seguimiento);
                  },
                    err => {

                    });
                } else {
                  //updatear cuando ya esta creado el seguimiento
                  sessionStorage.setItem('paginaclick', '/forma-pago');
                  this._seguimiento.updateSeguimiento().subscribe(a => {
                    console.log(a);
                  },
                    err => {

                    });
                }
                //this.myGetService.postData(this.datos, "api/BoletosWeb/ReenviarRecordatorio").subscribe((res) => {
                //	//console.log(res);
                //});
                this.terminar();
              } else if (this.metodo_pago == 1) {
                this.procesando = 1;
                //MIT pago
                localStorage.setItem('campaingData1', localStorage.getItem("campaingData"));
                let respuesta;
                sessionStorage.setItem('metodoPago', 'MIT');
                sessionStorage.setItem('ordenDeCompra', this.folio);
                console.log("this.tipos_pago" + this.tipos_pago);
                let tipoP = sessionStorage.getItem('TiposPago');
                let tipoPInt = parseInt(tipoP, 10)
                if (tipoPInt == 2 || tipoPInt == 3) {
                  console.log("efectuarPagoPublicoGeneral insert");
                  sessionStorage.setItem('paginaclick', '/forma-pago - directo');
                  this._seguimiento.addSeguimiento(JSON.parse(sessionStorage.getItem('BoletosAsignados'))).subscribe(a => {
                    console.log(a);
                    respuesta = a;
                    const pk_seguimiento = respuesta.pk1;
                    console.log(pk_seguimiento);
                    sessionStorage.setItem('pk_seguimiento', pk_seguimiento);
                  },
                    err => {

                    });
                } else {
                  //updatear cuando ya esta creado el seguimiento
                  sessionStorage.setItem('paginaclick', '/forma-pago');
                  console.log("efectuarPagoPublicoGeneral update");
                  this._seguimiento.updateSeguimiento().subscribe(a => {
                    console.log(a);
                  },
                    err => {

                    });
                }

                //orden de compra generada
                this.myGetService.getLigaWebPay('api/BoletosWeb/PagoWPP2', this.folio).subscribe((res) => {
                  this.urlWebPay.cd_response = res.json().cd_response;
                  this.urlWebPay.nb_response = res.json().nb_response;
                  //console.log( this.urlWebPay);
                  //console.log( res);
                  //corregir aqui
                  //window.open(res.json().nb_url, '_blank');
                  window.location.href = res.json().nb_url;
                },
                  err => {
                    console.log(err);
                  });
              } else {
                //Metodo paypal & Metodo Banamex
                if (this.metodo_pago == 3 || this.metodo_pago == 4) {
                  var datosorden = {
                    folio: this.boletos_log.folio,
                    amount: this.boletos_log.amount,
                    email: this.boletos_log.email,
                  };
                  datosbol = datosorden;
                  this._local.deleteBoletosGenerados();
                  this._local.deleteBoletosRecomendados();
                  this._local.deleteBoletosAsignados();
                  //window.sessionStorage.clear();
                  if (this.metodo_pago == 3) {
                    btnpay.hidden = false;
                  }
                  //return (datosorden);
                }
              }
            }
            if (this.metodo_pago == 3 || this.metodo_pago == 4) {
              if (datosbol != null || datosbol != undefined) {
                resolve('datosbol listo promise');
              }
            } else {
              resolve('preventa completa');
            }
          }, err => {
            console.log(err);
            const errorBody = JSON.parse(err._body);

            console.log(errorBody.error); // Accede a la propiedad `error`
            console.log(errorBody.message); // Accede a la propiedad `message`
            if (errorBody.error == 1) {
              //guarda el mensaje el pk de seguimiento
              this._seguimiento.updateErrorCaracteres(errorBody.message).subscribe(a => {
                console.log(a);
              });
              window.location.href = 'error-pago?success=false&nbResponse=ErrorDatos';
            }
          });
      }); //del promise

      ///aqui acaba el post preventa
    }

    this._local.deleteBoletosGenerados();
    this._local.deleteBoletosRecomendados();
    this._local.deleteBoletosAsignados();
    //window.sessionStorage.clear();
  }

  async efectuarPagoAlterno(metodoPago) {
    this.procesando = 1;

    var btnpay = document.getElementById('paypal-button-container');
    btnpay.hidden = true;
    spin = document.getElementById('spin');
    spin.hidden = true;
    if (metodoPago == 2 || metodoPago == 1) {
      spin.hidden = false;
    }
    this.tipo_comprador = this._session.getTipoAsignado() ? parseInt(this._session.getTipoAsignado()) : 3;
    //console.log(this.tipo_comprador);
    return new Promise((resolve, reject) => {
      //api/BoletosWeb/PagoWPP2/{ordenCompra}
      this.myGetService
        .postData(
          this.boletos_preAsignados.boletos,
          'api/BoletosWeb/Preventa/' + metodoPago + '/' + this.tipo_comprador
        )
        .subscribe((res) => {
          //console.log(res);

          this.folio = res.json().folio;
          console.log(this.folio);
          this._local.setFolio(this.folio);
          this.datos.referencia = res.json().referencia;
          this.datos.convenio = res.json().convenio;
          this.datos.clabe = res.json().clabe;
          this.datos.cuenta = res.json().cuenta;

          this.boletos_log.pk1 = uuid();
          this.boletos_log.referencia = res.json().referencia;
          this.boletos_log.tipo_comprador = parseInt(this._session.getTipoAsignado());
          this.boletos_log.folio = res.json().folio;
          this.boletos_log.boletos_string = JSON.stringify(this.boletos_preAsignados.boletos);
          this.boletos_log.amount = this.total_compra.toString();

          var tipo_asig = this._session.getTipoAsignado();

          this.boletos_log.email = tipo_asig == '1' ? this._session.getColab() : this.correo;

          var tipo_asig = this._session.getTipoAsignado();
          ////console.log(this.boletos_preAsignados);
          if (this.folio == '0') {
            window.location.href = 'error-pago?success=false&nbResponse=ErrorDatosCarrito';
          } else {
            this.myGetService.postData(this.boletos_log, 'api/BoletosWeb/VentaLog').subscribe((res) => {
              ////console.log("exitoso");
            });
            //debugger;
            if (metodoPago == 1) {
              let respuesta;
              sessionStorage.setItem('metodoPago', 'MIT');
              sessionStorage.setItem('ordenDeCompra', this.folio);
              let tipoP = sessionStorage.getItem('TiposPago');
              let tipoPInt = parseInt(tipoP, 10)
              if (tipoPInt == 2 || tipoPInt == 3) {
                sessionStorage.setItem('paginaclick', '/forma-pago - directo');
                this._seguimiento.addSeguimiento(JSON.parse(sessionStorage.getItem('BoletosAsignados'))).subscribe(a => {
                  console.log(a);
                  respuesta = a;
                  const pk_seguimiento = respuesta.pk1;
                  console.log(pk_seguimiento);
                  sessionStorage.setItem('pk_seguimiento', pk_seguimiento);
                },
                  err => {

                  });
              } else {
                //updatear cuando ya esta creado el seguimiento
                sessionStorage.setItem('paginaclick', '/forma-pago');
                this._seguimiento.updateSeguimiento().subscribe(a => {
                  console.log(a);
                },
                  err => {

                  });
              }
              this.myGetService.getLigaWebPay('api/BoletosWeb/PagoWPP2', this.folio).subscribe((res) => {
                this.urlWebPay.cd_response = res.json().cd_response;
                this.urlWebPay.nb_response = res.json().nb_response;
                ////console.log(res.json().nb_url);
                window.location.href = res.json().nb_url;
              });
            } else if (metodoPago == 2) {
              this.datos.folio = this.folio;
              this.datos.boletos = this.boletos_preAsignados.boletos;
              this.datos.correo = this.correo;
              this.datos.colaborador = true;

              //add seguimiento
              sessionStorage.setItem('metodoPago', 'REFERENCIA');
              sessionStorage.setItem('ordenDeCompra', "REFERENCIA - " + this.folio);
              let respuesta;
              let tipoP = sessionStorage.getItem('TiposPago');
              let tipoPInt = parseInt(tipoP, 10)
              if (tipoPInt == 2 || tipoPInt == 3) {
                sessionStorage.setItem('paginaclick', '/forma-pago - directo');
                this._seguimiento.addSeguimiento(JSON.parse(sessionStorage.getItem('BoletosAsignados'))).subscribe(a => {
                  console.log(a);
                  respuesta = a;
                  const pk_seguimiento = respuesta.pk1;
                  console.log(pk_seguimiento);
                  sessionStorage.setItem('pk_seguimiento', pk_seguimiento);
                },
                  err => {

                  });
              } else {
                //updatear cuando ya esta creado el seguimiento
                sessionStorage.setItem('paginaclick', '/forma-pago');
                this._seguimiento.updateSeguimiento().subscribe(a => {
                  console.log(a);
                },
                  err => {

                  });
              }
              ////console.log(this.datos);

              this.myGetService.postData(this.datos, 'api/BoletosWeb/ReenvioReferencia').subscribe((res) => {
                ////console.log(res);
                this.mostrarReferencia = true;
                spin.hidden = true;
              });

              this.myGetService.postData(this.datos, 'api/BoletosWeb/EnviarRecordatorio').subscribe((res) => {
                ////console.log(res);
              });
              this.terminar();
            } else if (metodoPago == 3 || metodoPago == 4) {
              //servicio paypal
              var datosorden = {
                folio: this.boletos_log.folio,
                amount: this.boletos_log.amount,
              };
              datosbol = datosorden;
              if (metodoPago == 3) {
                btnpay.hidden = false;
              }

              //return (datosorden);
            }
          }
          if (this.metodo_pago == 3 || this.metodo_pago == 4) {
            if (datosbol != null || datosbol != undefined) {
              resolve('datosbol listo promise');
            }
          } else {
            resolve('preventa completa');
          }

        }, err => {
          console.error('Error en la llamada a la API de preventa', err);
          reject('Error en la llamada a la API de preventa');
        }); // subscribe
    }); // promise
  }

  reenviarCorreo() {
    this.enviando = true;
    this.datos.boletos =
      this.boletos_preAsignados && this.boletos_preAsignados.boletos.length > 0
        ? this.boletos_preAsignados.boletos
        : this.boletos;
    this.datos.correo = this.correo_adicional; //(this.boletos_preAsignados && this.boletos_preAsignados.boletos.length > 0) ? this.boletos_preAsignados.correo : this.boletos_log.email ;

    ////console.log("datos para reenvio");
    ////console.log(this.datos);
    this.myGetService.postData(this.datos, 'api/BoletosWeb/ReenvioReferenciaCorreo').subscribe((res) => {
      this.envioTexto = 'Referencia enviada nuevamente';
      setTimeout(() => {
        this.enviando = false;
      }, 3000);
    });
  }

  regresar(): void {
    this.router.navigate(['compra-en-linea-boletos-sorteo-anahuac']);
  }

  terminar(): void {
    this._local.deleteBoletosGenerados();
    this._local.deleteBoletosRecomendados();
    this._local.deleteBoletosAsignados();
    this._local.deleteCarrito();
    window.sessionStorage.clear();
  }

  //// ------------ POPUP------------
  /*
  PopupAmigable(popC) {
    var PopAct = document.getElementById('popupamigable');
    if (popC == 0) {
      setTimeout(() => {
        document.addEventListener('mouseout', (event) => {
          if (event.relatedTarget === null && popC == 0) {
            PopAct.hidden = false;
            popC = 2;
            this._session.setPopstate(popC);
          }
        });
      }, 5);
    } else {
      PopAct.hidden = true;
    }
  }*/
  //// ------------ POPUP------------

  // -------- paypal metodo ------------------
  // Para evitar carga de paypal antes de generar la orden
  addScript: boolean = false;
  paypalLoad: boolean = true;
  addScriptBanamex: boolean = false;


  ngAfterViewChecked(): void {
    let self = this;
    let statusPago = "";
    if (!this.addScript && this.metodo_pago == 3) {
      this.addPaypalScript()
        .then(() => {
          datosbol = {
            folio: this.boletos_log.folio,
            amount: this.boletos_log.amount,
          };
        })
        .then(() => { })
        .then(() => {
          paypal
            .Buttons({
              commit: true,
              createOrder: function () {
                this.procesando = 1;
                popC = 2;
                //console.log("fetch");
                return fetch(Urlp, {
                  method: 'post',
                  headers: { 'content-type': 'application/json' },
                  body: JSON.stringify(datosbol),
                })
                  .then(function (res) {
                    return res.json();
                  })
                  .then(function (data) {
                    // console.log("datos de front");
                    // console.log(data);
                    spin.hidden = false;
                    return data.id;
                  });
              },

              onApprove: function (data, actions) {
                return actions.order.capture().then(function (details) {
                  // Show a success message to the buyer
                  //alert('Pago realizado por ' + details.payer.name.given_name + '!');
                  // console.log("Datos de onApprove listos para procesar en back");
                  // console.log("Folio de orden registrado en back");
                  // console.log(details.purchase_units[0].custom_id);
                  // console.log("Cantidad pagada");
                  // console.log(details.purchase_units[0].amount.value);
                  // console.log(details.payer.email_address);
                  // console.log("Id de usuario paypal");
                  // console.log(details.payer.payer_id);
                  // console.log("Id orden paypal");
                  // console.log(details.purchase_units[0].payments.captures[0].id);
                  // console.log("todos los datos");
                  // console.log(details);
                  var datosorden = {
                    folio: details.purchase_units[0].custom_id,
                    nombre_cliente: details.payer.name.given_name,
                    monto: details.purchase_units[0].amount.value,
                    mensaje: details.purchase_units[0].payments.captures[0].id,
                  };
                  // console.log(datosorden);
                  self.seguimientoPaypal(datosorden);
                  fetch(Urlc, {
                    method: 'post',
                    headers: { 'content-type': 'application/json' },
                    body: JSON.stringify(datosorden),
                  })
                    .then(function (data) {
                      return data;
                    })
                    .then(function (data) {
                      localStorage.setItem('campaingData1', localStorage.getItem("campaingData"));
                      var Url =
                        'venta-exitosa?success=true&nbResponse=Aprobado&cdResponse=' +
                        data +
                        '&idLiga=' +
                        details.purchase_units[0].payments.captures[0].id +
                        '&referencia=' +
                        details.purchase_units[0].custom_id +
                        '&importe=' +
                        details.purchase_units[0].amount.value +
                        '&email=' +
                        details.payer.email_address;
                      window.location.href = Url;
                    });
                });
              },
              onCancel: function (data, actions) {
                //console.log(data);
                popC = 0;
                if (Tcomprador == 3) {
                  window.location.href = 'venta-exitosa?success=false&nbResponse=Rechazado';
                } else {
                  spin.hidden = true;
                  alert('La operacion fue cancelada');
                  this.procesando = 0;
                }
              },

              onError: function (err) {
                // alert("La operacion no se completo");
                spin.hidden = false;
                this.procesando = 0;
                //console.log(err);
                popC = 0;
                window.location.href = 'venta-exitosa?success=false&nbResponse=Bloqueo';
              },
            })
            .render('#paypal-button-container');
          this.paypalLoad = false;
        });
    }

    //Metodo de pago Banamex
    if (!this.addScriptBanamex && this.metodo_pago == 4) {
      this.addPaypalScript()
        .then(() => {
          datosbol = {
            folio: this.boletos_log.folio,
            amount: this.boletos_log.amount,
          };
        })
        .then(() => { })
        .then(async () => {
          async function createOrder() {
            popC = 2;
            //console.log("creamos orden banamex");
            return fetch(UrlBanamex, {
              method: 'post',
              headers: { 'content-type': 'application/json' },
              body: JSON.stringify(datosbol),
            })
              .then(function (res) {
                return res.json();
              })
              .then(function (data) {
                //console.log("datos de front");
                //console.log(data);
                ordenbanamex = data;
                //spin.hidden=false;
                return data;
              })
              .then(function (data) {
                return new Promise((resolve, reject) => {
                  if (data != null || data != undefined) {
                    return resolve('orden de banamex generada');
                  }
                });
              });
          }

          await createOrder();
        })
        .then((data) => {
          Checkout.configure({
            session: {
              id: ordenbanamex.session.id,
            },
            interaction: {
              merchant: {
                name: 'Sorteo Anahuac 2024',
                address: {
                  line1: 'Av. Universidad Anáhuac #46 Col. Lomas Anáhuac',
                  line2: ' Huixquilucan, Estado de México C.P.52786',
                },
              },
            },
          });

          function PagoBanamex() {
            spin.hidden = false;
            var scriptban = document.getElementById('ban');
            (scriptban as HTMLScriptElement).setAttribute('data-complete', 'completeCallback');
            window.completeCallback = (resultIndicator, sessionVersion) => {
              //console.log("Operacion completada");
              //console.log(resultIndicator);
            };
            Checkout.showLightbox();
            //Checkout.showPaymentPage()

            sessionS.setDatosOrdenBanamex(JSON.stringify(datosorden));
            sessionS.setOrdenBanamex(ordenbanamex.successIndicator);
          }

          function btncreate() {
            const container = document.getElementById('banamex-button-container');

            const button = document.createElement('button');
            button.textContent = 'Paga con Bananamex';
            button.className = 'btn btn-secondary';
            button.style.backgroundColor = '#dd741d';
            button.style.fontSize = '1.4rem';
            button.onclick = PagoBanamex;
            container.appendChild(button);
          }

          var datosorden = {
            folio: this.boletos_log.folio,
            nombre_cliente: 'Banamex',
            monto: this.boletos_log.amount,
            mensaje: 'Pago con Banamex',
          };
          var sessionS = this._session;
          btncreate();
        });
    }
  }


  async addPaypalScript() {
    if (this.metodo_pago == 3) {
      this.addScript = true;
    } else {
      this.addScriptBanamex = true;
    }

    if (this.tipos_pago == 2 || this.tipos_pago == 3) {
      await this.efectuarPagoAlterno(this.metodo_pago);
    } else {
      const genera_Orden = await this.efectuarPagoPublicoGeneral();
    }
    let promise = new Promise((resolve, reject) => {
      let scripttagElement = document.createElement('script');
      if (this.metodo_pago == 3) {
        scripttagElement.src = environment.PaypalAmbiente;
      }
      if (this.metodo_pago == 4) {
        scripttagElement.src = 'https://evopaymentsmexico.gateway.mastercard.com/checkout/version/57/checkout.js';
        //scripttagElement.setAttribute("data-complete","completeCallback");
        scripttagElement.setAttribute('data-cancel', 'cancel');
        scripttagElement.setAttribute('data-error', 'error');
        scripttagElement.setAttribute('data-beforeRedirect', 'beforeRed');
        scripttagElement.setAttribute('data-afterRedirect', 'afterRed');

        window.cancel = (payload) => {
          if (this.tipos_pago == 1) {
            window.location.href = 'error-pago?success=false&nbResponse=Rechazado';
            ////console.log("Operacion cancelada");
          } else {
            spin.hidden = true;
            ////console.log("Operacion cancelada");
            ////console.log(payload);
          }
        };
        window.error = (error) => {
          ////console.log(JSON.stringify(error));
          window.location.href = 'error-pago?success=false&nbResponse=Bloqueo';
        };
        window.beforeRed = (payload) => {
          ////console.log("Guarda datos de orden");
          Checkout.saveFormFields();
        };
        window.afterRed = (resultIndicator) => {
          ////console.log("Carga datos de orden");
          Checkout.restoreFormFields();
          ////console.log("Resultado de orden");
          ////console.log(resultIndicator);
        };
        // window.completeCallback = (resultIndicator, sessionVersion) => {
        // 	//console.log("Operacion completada");
        // 	//console.log(resultIndicator);
        // };
      }
      //		scripttagElement.src = 'https://www.paypal.com/sdk/js?client-id=sb&currency=MXN&disable-funding=credit,card';
      scripttagElement.onload = resolve;
      scripttagElement.oncancel = reject;
      scripttagElement.id = 'ban';
      document.body.appendChild(scripttagElement);
    });

    return promise;
  }

  async RefBancStatus(): Promise<void> {
    let tipocompra: number;
    if (this.Paybackref == '1') {
      tipocompra = 1; //payback
    } else if (this.pkcolab != '0') {
      tipocompra = 2; // pkcolab == pasa la voz
    } else if (this.BoletosCol == '1') {
      tipocompra = 3; //Liga de colaborador
    } else if (this.BoletosCol == '0') {
      tipocompra = 4; //publico general
    }

    let boletos: any =
      this.boletos_preAsignados && this.boletos_preAsignados.boletos.length > 0
        ? this.boletos_preAsignados.boletos
        : this.boletos;

    let datos: any = {
      opc: tipocompra,
      boletos: boletos,
    };

    ////console.log('ref enviando');
    return new Promise((resolve, reject) => {
      this.myGetService.postData(datos, 'api/BoletosWeb/ReferenciaBancaria/Estatus').subscribe(
        (res) => {
          this.habilitarReferencia = res.json();
          ////console.log('response ref recibida');
          this.spinHandlerMetodoParametrizado();
          resolve();
        },
        (error) => {
          this.spinHandlerMetodoParametrizado();
        }
      );
    });
  }

  async GetMetodoParametrizado(): Promise<void> {
    let tipocompra: number;
    let nicho: string;
    if (this.Paybackref == '1') {
      tipocompra = 1; //payback
      nicho = 'payback';
    } else if (this.pkcolab != '0') {
      tipocompra = 2; // pkcolab == pasa la voz
      nicho = 'pasalavoz';
    } else if (this.BoletosCol == '1') {
      tipocompra = 3; //Liga de colaborador
      nicho = 'ligacolaborador';
    } else if (this.BoletosCol == '0') {
      tipocompra = 4; //publico general
    }

    let boletos: any =
      this.boletos_preAsignados && this.boletos_preAsignados.boletos.length > 0
        ? this.boletos_preAsignados.boletos
        : this.boletos;

    let datos: any = {
      pkmetodo: '3',
      pknicho: nicho,
      boletos: boletos,
    };

    ////console.log('paypal parametrizado enviando');
    return new Promise((resolve, reject) => {
      this.myGetService.postData(datos, 'api/BoletosWeb/Config/GetMetodoParametrizado').subscribe(
        (res) => {
          this.habilitaPaypal = res.json();
          ////console.log('response ref recibida');
          this.spinHandlerMetodoParametrizado();
          resolve();
        },
        (error) => {
          console.log(error);
          window.location.href = 'error-pago?success=false&nbResponse=Invalido';
          this.spinHandlerMetodoParametrizado();
        }
      );
    });
  }

  public spinHandlerMetodoParametrizado(): void {
    if (this.spinhandler == 1) {
      spin.hidden = true;
    } else {
      this.spinhandler++;
    }
  }

  seguimientoPaypal(datosorden: { folio: string; nombre_cliente: any; monto: string; mensaje: any; }) {
    let respuesta;
    sessionStorage.setItem('metodoPago', 'PAYPAL');
    sessionStorage.setItem('ordenDeCompra', datosorden.folio);
    let tipoP = sessionStorage.getItem('TiposPago');
    let tipoPF = parseInt(tipoP, 10);

    console.log(tipoPF);
    if (tipoPF == 2 || tipoPF == 3) {
      sessionStorage.setItem('paginaclick', '/forma-pago - directo');
      this._seguimiento.addSeguimiento(JSON.parse(sessionStorage.getItem('BoletosAsignados'))).subscribe(a => {
        console.log(a);
        respuesta = a;
        const pk_seguimiento = respuesta.pk1;
        console.log(pk_seguimiento);
        sessionStorage.setItem('pk_seguimiento', pk_seguimiento);
      },
        err => {

        });
    } else {
      //updatear cuando ya esta creado el seguimiento
      sessionStorage.setItem('paginaclick', '/forma-pago');
      this._seguimiento.updateSeguimiento().subscribe(a => {
        console.log(a);
      },
        err => {

        });
    }
  }




}

